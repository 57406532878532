import React from 'react'
import styles from './WeAreSection.module.scss'
import { Container, Grid, Typography } from '@mui/material'
import CommonButtton from '../CommonButtton/CommonButtton';

const WeAreSection = () => {
    return (
        <Grid className={styles.weAreSectionContainer}>
            <Grid className={styles.headingContainer}>
                <Container className={styles.heading}>
                    <Typography variant='h2' fontWeight={600}>We love working <br></br> with industries </Typography>
                    <CommonButtton
                        name={"GET INSPIRED"}
                    />
                </Container>
                <Grid className={styles.leftTriangle}></Grid>
                <Grid className={styles.leftCurcle}></Grid>
            </Grid>
            <Grid className={styles.descriptionContainer}>
                <Container className={styles.content}>
                    <Typography variant='h2' fontWeight={600}>More than just consultants</Typography>
                    <Typography>We believe in upgrading your HSE culture and guiding you to excellence</Typography>
                    <CommonButtton
                        name={"Your hand holder to achieve excellance"}
                    />
                </Container>
                <Grid className={styles.rightTriangle}></Grid>
                <Grid className={styles.bluevioletTriangle}></Grid>
                <Grid className={styles.bluevioletTriangle2}></Grid>
            </Grid>
        </Grid>
    )
}

export default WeAreSection