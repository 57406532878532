import React, { FunctionComponent } from 'react';
import AuditingHeader from '../../component/AuditingAndConsulting/AuditingAndConsultingHeader';
import MainLayout from '../../component/Layout/MainLayout';
import Footer from '../../component/Footer/Footer';
import EnergyAudit from '../../component/AuditingAndConsulting/EnergyAudit';
interface EnergyAuditingAndConsultingProps {

}

const EnergyAuditingAndConsulting: FunctionComponent<EnergyAuditingAndConsultingProps> = () => {

    const handleDownload = () => {
        console.log("download");
    };

    return (
        <div className='auditing-and-consulting'>
        <MainLayout>
            <AuditingHeader heading='Energy Audit'/>
            <EnergyAudit/>
            <Footer/>
        </MainLayout>
    </div>
    );
};

export default EnergyAuditingAndConsulting;
