import React from 'react'
import leaderimg from '../../constrain/Images/team_leader.jpg'
import './Leader.css';
import Peoplework from './Peoplework';
import Coreteam from './Coreteam';
import Headteam from './Headteam';
import Banner from '../AboutBanner/Banner/Banner';


function Leader() {
  return (
    <>
      <Banner
        img={leaderimg}
        heading={'Leadership Team'}
        description={''}
        isAction={false}
      />

      <Peoplework />
      <Headteam />
      <Coreteam />
    </>
  )
}

export default Leader