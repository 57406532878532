import React from 'react'
import styles from './CourseModal.module.scss'
import { Box, Grid, Modal, Typography } from '@mui/material'
import { RxCross2 } from 'react-icons/rx';



export interface ICourseModal {
    open: boolean;
    handleClose: any;
}
const CourseModal = ({ open, handleClose }: ICourseModal) => {
    return (
        <Grid className={styles.courseModalContainer}>
            <Modal
                open={open}
                style={{ width: 550, height: "fit-content", borderRadius: 2, margin: "auto", backgroundColor: "#86bc25" }}
            >
                <Box>
                    <RxCross2 fontSize={25} style={{ color: "#ffffff", marginInlineStart: 510, marginBlockStart: 10, cursor: "pointer" }} onClick={handleClose} />
                    <Typography variant='h2' fontWeight={600} fontSize={25} textAlign={"center"} style={{ color: "#ffffff", margin: "auto", paddingBlockStart: 70, paddingBlockEnd: 100 }}>
                        IOSH Course- (in progess to get accreditation)
                    </Typography>
                </Box>
            </Modal>
        </Grid>
    )
}

export default CourseModal