import React from 'react'
import styles from './HeadingText.module.scss'
import { Grid, Typography } from '@mui/material'

export interface IHeadingText {
    data: any;
}
const HeadingText = ({ data }: IHeadingText) => {
    return (
        <Grid className={styles.headingTextContainer}>
            {data && data?.map((item: any) => {
                return (
                    <Grid>
                        <Typography variant='h6' paddingBottom={2}>{item.title}  </Typography>
                        {item.des?.map((item: any) => {
                            return (
                                <Typography>{item.para}</Typography>
                            )
                        })}
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default HeadingText