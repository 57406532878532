import React from 'react'

const EnquiryPage = () => {
  return (
    <div>
      Enquiry About Training
    </div>
  )
}

export default EnquiryPage
