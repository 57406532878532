import React from "react";
import MainLayout from "../component/Layout/MainLayout";
import Supplymangement from "../component/Supplymanagement/Supplymangement";
import Footer from "../component/Footer/Footer";
import Commondigital from "../component/Drone/Commondigital";
import MainDigital from "../component/Drone/MainDigital";
import img from "../constrain/Images/ehs-management-system-video.jpg"

function SupplymanagementPage() {
  return (
    <MainLayout>
      {/* <Supplymangement/> */}
       <Commondigital heading={""} />
    
      <MainDigital
        heading={"Health safety and environment management"}
        content={[
          {
            lione: "Vidura International introduces an Integrated Health, Safety, and Environment (HSE) Management system, offering a comprehensive approach to managing organizational safety practices. Our platform integrates health, safety, and environmental protocols into a unified framework, ensuring a holistic and streamlined approach to risk management.",
            litwo: "With Vidura's HSE Management system, organizations can centralize their safety efforts, facilitating real-time monitoring, reporting, and analysis of health, safety, and environmental data. The system promotes proactive hazard identification, risk assessment, and the implementation of effective control measures.",
            li3: "Key features include incident tracking, compliance management, and performance analytics to empower organizations in achieving and maintaining regulatory compliance. The platform fosters a safety culture by providing tools for employee engagement, training, and communication on health, safety, and environmental matters.",
            li4: "Embrace the future of safety management with Vidura International's Integrated HSE Management system. Elevate your organization's safety standards and contribute to a healthier, safer, and more sustainable workplace.",
            li5: "",
            li6: "",
          },
        ]}
        image={img}
        text={"Discover the transformative capabilities of Integrated HSE Management by initiating a conversation with Vidura International. "}
      />
      <Footer />
    </MainLayout>
  );
}

export default SupplymanagementPage;
