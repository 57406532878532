import React, { useState } from 'react';
import  './tabStyle.css'

interface TabProps {
    title: string;
    isActive: boolean;
    // onClick: () => void;
    onMouseEnter:() => void;
}

const NavBarTabs: React.FC<TabProps> = ({ title, isActive, onMouseEnter }) => {
    return (
        <div className={`"tabBtn" ${isActive ? "activeTab" : ''}`}>
            <span className="navMenuBtn" onMouseEnter={onMouseEnter}>
                {title}
            </span>
        </div>
    );
};

export default NavBarTabs;

