import React from 'react';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import CallIcon from '@mui/icons-material/Call';
import { Link } from 'react-router-dom';
import { BsLinkedin, BsInstagram, BsFacebook } from 'react-icons/bs';


import './footer.css'
interface FooterProps {
    //   videoSrc: string;
    //   tagline: string;
}

const Footer: React.FC<FooterProps> = () => {

    return (
        <section className='footer-section'>
            <div className='footer-header-row'>
                <div className='footer-header-col-1'>
                    <div className='footer-Qusetion'>Questions ? <span>{"}"}</span></div>
                    <div className='footer-mobile'>
                        <CallIcon /><a href='tel:+91 9898989898'> +91 </a>
                        <a href='tel:+91 73043 26277'>73043 26277</a>
                    </div>
                    <div className='footer-email'>
                        <a href='mailto:info@vidurainternational.com'> <span className='emailIcon'> <MarkEmailReadIcon /> </span>info@vidurainternational.com</a>
                    </div>
                </div>
                <div className='footer-header-col-2'>
                    <div className='footer-social-media'>
                        <h3>Stay Connected</h3>
                        <span ><a href='https://www.facebook.com/vidura.international?mibextid=PzaGJu' target="_blank"  rel="noreferrer"><BsFacebook fontSize={25} style={{ color: "#ffffff" }} /></a></span>
                        <span> <a href='https://www.linkedin.com/in/vidura-international-337b21299/' target="_blank"  rel="noreferrer"><BsLinkedin fontSize={25} style={{ color: "#ffffff" }} /></a></span>
                        <span><a href='https://www.instagram.com/vidura_international/' target="_blank"  rel="noreferrer"><BsInstagram fontSize={25} style={{ color: "#ffffff" }} /></a></span>
                    </div>
                </div>
            </div>
            <div className='footer-content-row'>
                <div className='footer-content-col-1'>
                    <h4>Company</h4>
                    <div className='company-row'>
                        <div className='comany-col-1'>
                            <ul className='footer-link-list'>
                                <li className='footer-link'>
                                    <Link to="/about">About Us</Link>
                                </li>
                                <li className='footer-link'>
                                    <Link to="/">News & Events</Link>
                                </li>

                                <li className='footer-link'>
                                    <Link to="/contact">Contact US</Link>
                                </li>
                               
                                {/* <li className='footer-link'>
                                    <Link to="/certificate">Certificate & Course</Link>
                                </li> */}
                            </ul>
                        </div>
                        <div className='comany-col-2'>
                            <ul className='footer-link-list'>
                            <li className='footer-link'>
                                    <Link to="/leader">Leadership Team</Link>
                                </li>
                                <li className='footer-link'>
                                    <Link to="/career">Carrer</Link>
                                </li>
                               
                                {/* <li className='footer-link'>
                                    <Link to="/">Career</Link>
                                </li>
                                <li className='footer-link'>
                                    <Link to="/">Contact Us</Link>
                                </li>
                                <li className='footer-link'>
                                    <Link to="/">Sitemap</Link>
                                </li> */}
                                 <li className='footer-link'>
                                    <Link to="/certificate">Certificate & Course</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='footer-content-col-2'>
                    <h4 className='service-heading'>Services</h4>
                    <div className='service-row'>
                        <div className='service-col-1'>
                            <div className='service-footer'>
                                <ul className='service-link-list'>
                                    <li className='service-link'>
                                        <Link to="/esg">ESG</Link>
                                    </li>
                                    <li className='footer-link'>
                                        <Link to="/hse">HSE</Link>
                                    </li>
                                    <li className='footer-link'>
                                        <Link to="/skill-development">Skill Development</Link>
                                    </li>
                                    <li className='footer-link'>
                                        <Link to="/trainings">Trainings</Link>
                                    </li>
                                    <li className='footer-link'>
                                        <Link to="/food-safety">Food Safety</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='service-col-2'>
                            <div className='service-footer'>
                                <ul className='service-link-list'>
                                    <li className='service-link'>
                                        <Link to="/Competent-human-management">Competent human management</Link>
                                    </li>
                                    <li className='footer-link'>
                                        <Link to="/mep-epc-contracts">Mep Epc Contracts</Link>
                                    </li>
                                    <li className='footer-link'>
                                        <Link to="/fire-safety-management">Fire Safety Management</Link>
                                    </li>
                                    {/* <li className='footer-link'>
                                        <Link to="/">Success Stories</Link>
                                    </li>
                                    <li className='footer-link'>
                                        <Link to="/">Enquiry</Link>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='footer-content-col-3'>
                    <div className='keep-in-touch-footer'>
                        <h4 className='keep-in-touch-heading'>Keep In Touch</h4>
                        <h5>Automatically receive updates, webinar invitations and more.</h5>
                        <h5>Join our Exclusive Newsletter</h5>
                        <div className='input-info'>
                            <input type='text' placeholder='Name / Company Name'></input>
                            <input type='email' placeholder='Email'></input>
                            <div className='info-Btn'>
                                <input type='text' placeholder='Services(s) of Interest'></input>
                                <button>send</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Footer;
