import React from 'react'
import './CommonButtton.css'
import { Button, Grid } from '@mui/material'

export interface ICommonButtton {
    name: string;
    icon?: string;
    handleClick?:any;
}
const CommonButtton = ({ name, icon,handleClick }: ICommonButtton) => {
    return (
        <Grid className='buttonContainer'>
            <Button variant='outlined' onClick={handleClick}>{name} <img src={icon} /></Button>
        </Grid>
    )
}

export default CommonButtton