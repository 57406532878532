import React, { FunctionComponent, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./auditingAndConsulting.css";
import AuditCard from "./AuditCard";
import image from "../../constrain/Images/VIDURA_images/VIDURA_images/pexels-pixabay-39553.jpg"
import hseAudit5 from "../../constrain/Images//cq5dam.web.436.228.webp";
import AuditRightSectionCard from "./AuditRightSectionCard";
import Auditcard1 from "./Auditcard1";
import card2img from "../../constrain/Images/VIDURA_images/VIDURA_images/pexels-loïc-manegarium-3855962.jpg";

interface CarbonAuditProps {}

const CarbonAudit: FunctionComponent<CarbonAuditProps> = () => {
  const [isVisible, setIsVisible] = useState(false);
  const handleVisibilityChange = () => {
    const element = document.querySelector(".audit-show-when-visible");
    if (element) {
      const rect = element.getBoundingClientRect();
      const elementIsVisible =
        rect.top < window.innerHeight && rect.bottom >= 0;
      setIsVisible(elementIsVisible);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleVisibilityChange);
    return () => {
      window.removeEventListener("scroll", handleVisibilityChange);
    };
  }, []);

  return (
    <section className="audit-page">
      <div className="auditing-and-consulting-main">
        <div className="auditing-main-left-section">
         
          <AuditCard
            img={image}
            title="Carbon Audit"
            auditName="Navigating Carbon Neutrality with Vidura International"
            para={[
                { text: "At Vidura International, we understand the pivotal role carbon management plays in mitigating climate change and fostering sustainable business practices. Our Carbon Audit Services are meticulously designed to assess, quantify, and strategically manage your organization's carbon footprint." },
                
              ]}
          />
          <Auditcard1
           mainheading= "Our Carbon Audit Process"
           img ={card2img}
           para={[{
            text:"Understanding your organization's carbon emissions and identifying key sources.",heading:"Carbon Footprint Assessment"
           },
           {text:"Thorough examination of energy consumption, transportation, and other relevant data.",heading:"Data Collection and Analysis"},
           {text:"Receive a comprehensive report with actionable recommendations for carbon reduction.",heading:"Strategic Recommendations "},{
            text:"We assist you in implementing recommended changes and monitor ongoing carbon reduction efforts",heading:"Implementation Support"
           }
        ]}
          />
         
        </div>
        <div className='auditing-main-right-section'>
                <AuditRightSectionCard
                mainheading="Why Vidura International for Carbon Audits?"
                content = {[
                    {text: "Our experienced professionals utilize advanced methodologies to accurately measure and quantify your organization's carbon emissions.", heading:"Accurate Measurement"},
                    {text:"Gain valuable insights into carbon hotspots, allowing for strategic decision-making to reduce emissions and enhance sustainability.",heading:"Strategic Insights"},
                    {
                        text:"Our carbon audits are tailored to your industry, ensuring that recommendations align with your specific goals and compliance requirements.",heading:"Customized Solutions"
                    },
                    {text:"Stay ahead of carbon-related regulations by ensuring your organization follows local and international standards.", heading:"Regulatory Compliance"}
                ]}
                />
            </div>
      </div>
      
    </section>
  );
};

export default CarbonAudit;
