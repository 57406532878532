import React from "react";
import Virtualreality from "../component/Digitalvirtual/Virtualreality";
import MainLayout from "../component/Layout/MainLayout";
import Footer from "../component/Footer/Footer";
import Commondigital from "../component/Drone/Commondigital";
import MainDigital from "../component/Drone/MainDigital";
import imgg from "../constrain/Images/virtual-reality.jpeg"

function Virtualdigital() {
  return (
    <MainLayout>
      <Commondigital heading={""} />
      <MainDigital
        heading={"Virtual reality training setup"}
        content={[
          {
            lione: "Vidura International pioneers the integration of Virtual Reality (VR) into safety training, providing an immersive and engaging learning experience. Our VR training setup offers a groundbreaking approach to enhance safety awareness, simulate real-life scenarios, and reinforce critical skills.",
            litwo: "Through our VR training, employees can virtually navigate hazardous environments, practice emergency response procedures, and engage in hands-on simulations without any real-world risks. This innovative technology enables a deeper understanding of safety protocols, making training sessions more impactful and memorable.",
            li3: "Vidura International is committed to staying at the forefront of technological advancements, and our VR training setup reflects our dedication to providing cutting-edge solutions for a safer and more prepared workforce. Elevate your safety training programs with the transformative experience of Virtual Reality – the future of safety education.",
            li4: "",
            li5: "",
            li6: "",
          },
        ]}
        image={imgg}
        text={"Explore the possibilities of VR training with Vidura International. Connect with us to discuss how this immersive technology can revolutionize safety training in your organization."}
      />
      <Footer />
    </MainLayout>
  );
}

export default Virtualdigital;
