import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

interface EsgAdvisoryCardProps {
  id: number;
  img: string;
  title: string;
  desc: string;
  link: string;
  btn: string;
}

const EsgAdvisoryCard: FunctionComponent<EsgAdvisoryCardProps> = ({ id, img, title, desc, link, }) => {

  const handleDownload = () => {
    console.log("download");
  };

  return (
    <div className="esgAdvisory-card" key={id}>
      <div className='esg-card-img'>
        <img src={img} loading="lazy" alt="" />
      </div>
      <p className='new'>New</p>
      <h4>{title}</h4>
      <p>{desc}</p>
      <p>
        <ul>
          <li>Introduction</li>
          <li>Key Elements of Health & Safety Management Systems</li>
          <li>The purpose and importance of setting up Policies for Health & Safety</li>
          <li>Introduction</li>
          <li>Introduction</li>

        </ul>
      </p>
      <Link to={`/${title.toLowerCase()}`} className="btn-a-solid">
        {link}
      </Link>
    </div>
  );
};

export default EsgAdvisoryCard;
