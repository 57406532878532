import React from 'react'
import MainLayout from '../component/Layout/MainLayout'
import Supplytrades from '../component/Supplytrades/Supplytrades'
import Footer from '../component/Footer/Footer'
import AuditingHeader from '../component/AuditingAndConsulting/AuditingAndConsultingHeader'

function Supply() {
  return (
  

    // <MainLayout>
    //     <Supplytrades/>
    //     <Footer/>
    // </MainLayout>

    <div className='auditing-and-consulting'>
    <MainLayout>
        <AuditingHeader heading="Supply of Different Trade Professionals"/>
        <Supplytrades/>
        <Footer/>
    </MainLayout>
</div>
    
      
    
  )
}

export default Supply
