import React from 'react'
import styles from './AboutCard.module.scss'
import { Card, CardContent, CardMedia, Grid, ListItemText, Typography } from '@mui/material'

export interface IAboutCard {
    bgImage: string;
    bgOpecity: string;
    label: string;
    icon: string;

}
const AboutCard = ({ bgImage, bgOpecity, label, icon }: IAboutCard) => {
    return (
        <Grid className={styles.aboutCardContainer}>
            <Grid className={styles.imageContainer}>
                <img src={bgImage} alt='img' />
            </Grid>
            <Grid style={{ backgroundColor: bgOpecity }} className={styles.opecityContainer}>
            </Grid>
            <Grid className={styles.contentContainer}>
                <Typography variant='h2' fontWeight={600} fontSize={2}>{label}</Typography>
            </Grid>
            <Grid className={styles.iconContainer}>
                <img src={icon} alt='img' />
            </Grid>
        </Grid>
    )
}

export default AboutCard;