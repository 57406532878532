import React from 'react';
import foodSafetyImg from '../../constrain/Images/cook1.jpg'
import './FoodSafetyStyle.css'





const FoodSafetyMainContentSection: React.FC = () => {
    return (
        <>
            <section className='foodSafety-main-section'>
                <div className='main-left-section'>
                    <div className='main-image-section'>
                        <img src={foodSafetyImg} alt='' />
                    </div>
                </div>
                <div className='main-right-desc-section'>
                    <div>
                        <h2>
                            Food Safety Mandatory
                        </h2>
                        <pre>
                            <p>
                                We Are Excited To Introduce You to our
                                Extablishment Business
                            </p>
                        </pre>
                    </div>
                </div>

            </section>
        </>
    );
};

export default FoodSafetyMainContentSection;
