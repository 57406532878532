import React from "react";
import './Form.css';
import CommonButtton from "../CommonButtton/CommonButtton";

function Form() {
  return (
    <>
      <div className="Form">
        <h1>Submit Your Resume</h1>

        <form>
          <div className="mb-3">
            <label className="form-label " htmlFor="name">
              First Name
            </label>
            <input className="form-control" type="text" id="name" required />
          </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="email">
              Last Name
            </label>
            <input className="form-control" type="email" id="email" required />
          </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="email">
              Email
            </label>
            <input className="form-control" type="email" id="email" required />
          </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="email">
              Applying For
            </label>
            <input className="form-control" type="email" id="email" required />
          </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="phone">
              Phone
            </label>
            <input className="form-control" type="phone" id="phone" required />
          </div>
          <div className="mb-3 upload-resume">
            <label className="form-label">Upload Resume</label>
            <input className="form-control" type="file" id="formFile" />
          </div>
          <CommonButtton 
          name={"Submit"}/>
        </form>
      </div>
    </>
  );
}

export default Form;
