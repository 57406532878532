import React, { FunctionComponent } from 'react';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import CallIcon from '@mui/icons-material/Call';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import './stayConnected.css'

interface StayConnectedProps {
    //   id: number;
    //   img: string;
    //   title: string;
    //   desc: string;
    //   link: string;
    //   btn: string;
}

const StayConnected: FunctionComponent<StayConnectedProps> = () => {
    return (
        <div className='social-banner'>
            <h3>Stay Connected</h3>
            <span><FacebookIcon /></span>
            <span><TwitterIcon /></span>
            <span><MarkEmailReadIcon /></span>
            <span><CallIcon /></span>
        </div>
    );
};

export default StayConnected;
