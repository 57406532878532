import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import './knowMoreStyle.css'


interface KnowMoreProps {
  //   id: number;
  //   img: string;
  //   title: string;
  //   desc: string;
  //   link: string;
  //   btn: string;
  heading:string;
}
 
const KnowMore: FunctionComponent<KnowMoreProps> = ({heading}) => {
  return (
    <div className="Know-more-section">
      {/* <div className='know-more-header'>
        <div className='welcome-details'>
          <div className='intro-text'>
            <h6 className='welcome-to'>
              Welcome to
            </h6>
            <h3 className='vidura-big'>Vidura </h3>
            <br />
            <span className='consultance'>Consultancy</span>

          </div>

          <p className='know-more-paragraph'>Our success is guided by a deep collaboration with global professionals of diverse ESG/HSE background to make the world a better, healthy, sustainable safe place.</p>
        </div>
        <div className='welcome-banner'>
          <img src="" alt="" />
        </div>
      </div> */}
      {/* <div className='development-bottom-menu'>
        <button><Link to={"/development advisory"}>Development advisory {">"} </Link></button>
        <button><Link to={"/development advisory"}>HSE advisory for changing the culture {">"}</Link></button>
        <button><Link to={"/Development of procedures/process"}>Development of procedures/process {">"}</Link></button>
      </div> */}

      <div className="advisorycontentheading">
         
      </div>
    
    </div>
  );
};

export default KnowMore;
