import React from 'react';
import MainLayout from '../../component/Layout/MainLayout';
import Footer from '../../component/Footer/Footer';
import EsgStripDataCard from '../../component/ESGcomponent/EsgStripData';
import EsgAdvisoryCard from '../../component/Advisory/EsgAdvisoryCard';
import img1 from '../../constrain/Images/Morgan-Advanced-Materials-bg.jpg';
import img2 from '../../constrain/Images/foundation-in-health-safety-IGC-1.jpg';
import img3 from '../../constrain/Images/group-people-working-out-business-plan-office.jpg';
import img4 from '../../constrain/Images/health-safety-management-systems-plan-IGC-2.jpg';
import img5 from '../../constrain/Images/work-equipment-hazardous.jpg';
import img6 from '../../constrain/Images/FeaturedImage-5.webp';
import img7 from '../../constrain/Images/world-map-and-produce.jpg';
import img8 from '../../constrain/Images/as-is-process.png';
import img9 from '../../constrain/Images/close-up-people-working-with-laptop.jpg';
import img from "../../constrain/Images/VIDURA_images/VIDURA_images/Advisory On Development Of HSE Management Systems.png"
import headerImage from '../../constrain/Images/ESG-reporting.webp';
import EsgManagemntHeroSection from '../../component/ESGcomponent/ESGManagementHeroSection';
import ESGManagementHeader from '../../component/Services/Header/ESGManagementHeader';
import KnowMore from '../../component/Services/KnowMore';
import MainPage from './MainPage';

interface EsgAdvisoryDataItem {
  id: number;
  img: string;
  title: string;
  desc: string;
  link: string;
  btn: string;
  download: string;
}

interface EsgStripDataItem {
  id: number;
  img1: string;
  img2: string;
  title: string;
  desc: string;
  link: string;
  btn: string;
  download: string;
}

const EsgAdvisoryData: EsgAdvisoryDataItem[] = [
  {
    id: 1,
    img: img1,
    title: "ESG Advisory",
    desc: "Join our most awaited and self-paced course for NDA and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "ndabrochure.pdf",
  },
  {
    id: 2,
    img: img2,
    title: "Development advisory",
    desc: "Join our most awaited and self-paced course for CDS and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "cds.pdf",
  },
  {
    id: 3,
    img: img3,
    title: "HSE advisory for changing the culture",
    desc: "Join our most awaited and self-paced course for AFCAT and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "afcat.pdf",
  },
  {
    id: 4,
    img: img4,
    title: "Development of procedures/process",
    desc: "Join our most awaited and self-paced course for AFCAT and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "ota.pdf",
  },
  {
    id: 5,
    img: img5,
    title: "HSE advisory for changing the culture",
    desc: "Join our most awaited and self-paced course for AFCAT and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "afcat.pdf",
  },
  {
    id: 6,
    img: img6,
    title: "Development of procedures/process",
    desc: "Join our most awaited and self-paced course for AFCAT and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "ota.pdf",
  },
];
const EsgStripData: EsgStripDataItem[] = [
  {
    id: 1,
    img1: img7,
    img2: img8,
    title: "ESG Advisory",
    desc: "Join our most awaited and self-paced course for NDA and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "ndabrochure.pdf",
  },
  {
    id: 2,
    img1: img9,
    img2: img6,
    title: "Development advisory",
    desc: "Join our most awaited and self-paced course for CDS and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "cds.pdf",
  },
];
const AdvisoryHSEManagementPage: React.FC = () => {
  return (
    <MainLayout>
      {/* <ESGManagementHeader headerImage={headerImage} title="Advisory on development of HSE management systems" />
      <div className='esg-section'>
        <h3>Designed To Achieve Your Safety Training Goals</h3>
      </div>
      <main className='main-esg-content'>
        {EsgStripData.map((item, ind) => (
          <EsgStripDataCard
            key={item.id}
            id={item.id}
            img1={item.img1}
            img2={item.img2}
            title={item.title}
            desc={item.desc}
            link={item.link}
            btn={item.btn}
          />
        ))}

      </main>
      <EsgManagemntHeroSection/>
      <div className='esgAdvisory-section'>
        {EsgAdvisoryData.map((esdAdvisory) => (
          <EsgAdvisoryCard
            key={esdAdvisory.id}
            id={esdAdvisory.id}
            img={esdAdvisory.img}
            title={esdAdvisory.title}
            desc={esdAdvisory.desc}
            link={esdAdvisory.link}
            btn={esdAdvisory.btn}
          />
        ))}
      </div> */}

<KnowMore heading="" />
<MainPage
  heading="Advisory On Development of HSE & ESG Management Systems"
  content={[
    {
      lione:
        "Vidura International offers strategic Advisory on the Development of Health, Safety, and Environment (HSE & ESG) Management Systems. Our expert guidance transcends conventional approaches, aiming to create tailored systems that not only meet regulatory standards but also foster a culture of safety and environmental responsibility within your organization.",
      litwo:
        "Collaboratively, our seasoned experts work with your team to understand the unique challenges and goals of your organization. We then craft a customized roadmap for the development and implementation of HSE & ESG management systems that align seamlessly with your business operations.",
      li3: "Vidura International's approach goes beyond the checklist, focusing on creating systems that are practical, efficient, and embedded in the DNA of your organizational processes. Stay informed about the progress of your HSE & ESG initiatives with our monitoring and reporting mechanisms, complete with established key performance indicators (KPIs) for regular assessment and transparent reporting.",
      li4: "Choose Vidura International for Advisory on HSE & ESG Management Systems that elevates safety practices and environmental responsibility to the forefront of your organizational culture.",
      li5: "Initiate a collaborative discussion to implement strategies tailored to enhance your organization's commitment to Health, Safety, and Environmental excellence",
      li6: "",
    },
  ]}
  image={img}
/> 
      <Footer />
    </MainLayout>
  );
};

export default AdvisoryHSEManagementPage;
