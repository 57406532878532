import React from "react";
import MainLayout from "../../component/Layout/MainLayout";
import Footer from "../../component/Footer/Footer";
import EsgStripDataCard from "../../component/ESGcomponent/EsgStripData";
import EsgAdvisoryCard from "../../component/Advisory/EsgAdvisoryCard";
import img1 from "../../constrain/Images/Morgan-Advanced-Materials-bg.jpg";
import img2 from "../../constrain/Images/foundation-in-health-safety-IGC-1.jpg";
import img3 from "../../constrain/Images/group-people-working-out-business-plan-office.jpg";
import img4 from "../../constrain/Images/health-safety-management-systems-plan-IGC-2.jpg";
import img5 from "../../constrain/Images/work-equipment-hazardous.jpg";
import img6 from "../../constrain/Images/green-overlay.png";
import img7 from "../../constrain/Images/airshow2.jpg";
import img8 from "../../constrain/Images/Green Growth Blog.png";
import img9 from "../../constrain/Images/chairmans-message-thumbnail.jpg";
import img10 from "../../constrain/Images/cnsultancy3.jpg";
import headerImage from "../../constrain/Images/ESG-reporting.webp";
import EsgManagemntHeroSection from "../../component/ESGcomponent/ESGManagementHeroSection";
import ESGManagementHeader from "../../component/Services/Header/ESGManagementHeader";
import KnowMore from "../../component/Services/KnowMore";
import MainPage from "../HseMenuPage/MainPage";
import image from "../../constrain/Images/VIDURA_images/VIDURA_images/Competence Development Trainings.jpg"

interface EsgAdvisoryDataItem {
  id: number;
  img: string;
  title: string;
  desc: string;
  link: string;
  btn: string;
  download: string;
}
interface EsgStripDataItem {
  id: number;
  img1: string;
  img2: string;
  title: string;
  desc: string;
  link: string;
  btn: string;
  download: string;
}
const EsgStripData: EsgStripDataItem[] = [
  {
    id: 1,
    img1: img7,
    img2: img8,
    title: "ESG Advisory",
    desc: "Join our most awaited and self-paced course for NDA and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "ndabrochure.pdf",
  },
  {
    id: 2,
    img1: img9,
    img2: img10,
    title: "Development advisory",
    desc: "Join our most awaited and self-paced course for CDS and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "cds.pdf",
  },
];
const EsgAdvisoryData: EsgAdvisoryDataItem[] = [
  {
    id: 1,
    img: img1,
    title: "ESG Advisory",
    desc: "Join our most awaited and self-paced course for NDA and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "ndabrochure.pdf",
  },
  {
    id: 2,
    img: img2,
    title: "Development advisory",
    desc: "Join our most awaited and self-paced course for CDS and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "cds.pdf",
  },
  {
    id: 3,
    img: img3,
    title: "HSE advisory for changing the culture",
    desc: "Join our most awaited and self-paced course for AFCAT and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "afcat.pdf",
  },
  {
    id: 4,
    img: img4,
    title: "Development of procedures/process",
    desc: "Join our most awaited and self-paced course for AFCAT and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "ota.pdf",
  },
  {
    id: 5,
    img: img5,
    title: "HSE advisory for changing the culture",
    desc: "Join our most awaited and self-paced course for AFCAT and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "afcat.pdf",
  },
  {
    id: 6,
    img: img6,
    title: "Development of procedures/process",
    desc: "Join our most awaited and self-paced course for AFCAT and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "ota.pdf",
  },
];

const CompetenceTraining: React.FC = () => {
  return (
    <MainLayout>
      {/* <ESGManagementHeader headerImage={headerImage} title="Competence Development Training" />
      <div className='esg-section'>
        <h3>Designed To Achieve Your Safety Training Goals</h3>
      </div>
      <main className='main-esg-content'>
        {EsgStripData.map((item, ind) => (
          <EsgStripDataCard
            key={item.id}
            id={item.id}
            img1={item.img1}
            img2={item.img2}
            title={item.title}
            desc={item.desc}
            link={item.link}
            btn={item.btn}
          />
        ))}

      </main>
      <EsgManagemntHeroSection/>
      <div className='esgAdvisory-section'>
        {EsgAdvisoryData.map((esdAdvisory) => (
          <EsgAdvisoryCard
            key={esdAdvisory.id}
            id={esdAdvisory.id}
            img={esdAdvisory.img}
            title={esdAdvisory.title}
            desc={esdAdvisory.desc}
            link={esdAdvisory.link}
            btn={esdAdvisory.btn}
          />
        ))}
      </div> */}

      <KnowMore heading={""} />
      <MainPage
       heading={"Competence Development / Trainings"}
        content={[
          {
            lione:"At Vidura International, we redefine workforce excellence through our dynamic Competence Development and Training programs. In the ever-evolving landscape of Health, Safety, Environment, and Governance (HSE & ESG&G), continuous learning is the key to staying at the forefront.",
            litwo:"Our training initiatives go beyond standard certifications, covering a broad spectrum of competencies. From safety certifications like NEBOSH, IOSH, and CSP to specialized skill development in construction, hydrocarbon, telecom, mining, and more, we tailor programs to meet the unique needs of your workforce." ,
            li3:"Led by seasoned experts in their respective fields, our trainers bring practical insights and real-world experience to the training room. We do not just deliver courses; we cultivate a culture of ongoing learning within your organization. Whether it is mastering safety protocols, understanding environmental and social accountability, or acquiring technical skills in specific industries, our programs are designed for practical application and measurable impact.",
            li4:"Beyond compliance, Vidura International's training encompasses behavior-based safety, prevention and protection, and other critical aspects of safety management. Our goal is not just to ensure compliance but to foster competence that prepares your workforce for the challenges of the future.",
            li5:"Choose Vidura International for Competence Development and Training that transcends conventional approaches. Initiate a collaborative discussion to implement training programs tailored to elevate your organization's competence and performance.",
            li6:""
          }
        ]}
         image={image} />
      <Footer />
    </MainLayout>
  );
};

export default CompetenceTraining;
