import React from 'react'
import styles from './InputField.module.scss'
import { Grid, Input } from '@mui/material'

export interface InputField {
    placeholder: string,
    type:string,
    // value: string; 
    name:string|any;
    // onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;

}
const InputField = ({ placeholder,type ,name}: InputField) => {
    return (
        <Grid className={styles.inputContainer}>
            <Input type={type} placeholder={placeholder} name={name} 
                />
        </Grid>
    )
}

export default InputField