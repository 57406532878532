import React from 'react'
import MainLayout from '../component/Layout/MainLayout'
import { galleryPropsData } from '../constrain/renderComponentData/GalleryData'
import GalleryCard from '../component/Gallery/GalleryCard'
import './pageStyle.css'
import Footer from '../component/Footer/Footer'

const GalleryPage = () => {
  return (
    <MainLayout>
      <div className='galleryCard'>
        {galleryPropsData.map((item, index) => (
          <GalleryCard imageSrc={item.imageSrc} title={item.title} />
        ))}
      </div>

      <Footer/>
    </MainLayout>
  )
}

export default GalleryPage
