import React, { useState, useEffect } from 'react'

const ShowImage = () => {
    const [card, setCard] = useState([])
    useEffect(() => {
        async function fetchData() {
            const header = {
                "Authorization": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyaWQiOiI2NTFiZTMzZDZmZjNkOTMxNDE2ZjE1MDgiLCJpYXQiOjE2OTY1NzM1NDUsImV4cCI6MTY5OTE2NTU0NX0.9US7taOPgimV3rKyY1GeaqU09bqNOSG7hpYdqFdcz9c"
            }
            try {
                const response = await fetch("http://54.252.205.219:8000/api/auth/report/get/report?bookingId=AUSTKSNLH2", {
                    method: "GET",
                    headers: header,
                });

                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }

                const result = await response.json();
                // console.log(result)
                console.log(result.checkOutImage);
                let imageArray = result.checkOutImage
                setCard(imageArray);

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }

        // console.log(card)


        fetchData();
    }, [])
    console.log(card)
    return (
        <div className='image holder'>
            {card?.map((img, ind) => (
                <div className='image-card'>
                    <img src={img} alt='' />
                </div>
            ))}

            <img src={card[0]} alt="" />

        </div>
    )
}

export default ShowImage
