import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import './AuditingStyle.css'
import image from "../../constrain/Images/Screenshot (217).png";


interface Paragraph {
    text: string;
  }
  
interface AuditCardProps {
    title:string;
    img:string;
    para:Paragraph[];
    auditName:string;
}

const AuditCard: FunctionComponent<AuditCardProps> = ({title,para,auditName,img}) => {

    const handleDownload = () => {
        console.log("download");
    };

    return (
        <section className='auditing-card'>
           <h3 className='audit-card-title'>{auditName}</h3>
           <div className='audit-card-content'>
            <div className=''>
            {/* <h3>{auditName}</h3> */}
            {para.map((item,index)=>(
                <p>{item.text}</p>
            ))}
            </div>
               <div className='audit-card-img'>
                <img src={img} alt=''/>
               </div>
           </div>
        </section>
    );
};

export default AuditCard;
