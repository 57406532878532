import React, { useEffect, useState } from "react";
import "./Manpowermangement.css";
import AuditCard from "../AuditingAndConsulting/AuditCard";
import Auditcard1 from "../AuditingAndConsulting/Auditcard1";
import AuditRightSectionCard from "../AuditingAndConsulting/AuditRightSectionCard";

function Manpowermangement() {

  const [isVisible, setIsVisible] = useState(false);
  const handleVisibilityChange = () => {
    const element = document.querySelector(".audit-show-when-visible");
    if (element) {
      const rect = element.getBoundingClientRect();
      const elementIsVisible =
        rect.top < window.innerHeight && rect.bottom >= 0;
      setIsVisible(elementIsVisible);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleVisibilityChange);
    return () => {
      window.removeEventListener("scroll", handleVisibilityChange);
    };
  }, []);

  return (
  // <>
  
  //     {/* <div className="manpowercontainer">
  //       <div>
  //         <div className="Safety-img">
  //           <img src={img} alt="" />
  //         </div>
  //         <div className="Safetycontent">
  //           <h1>Safety Manpower Management </h1>
  //         </div>
  //       </div>
  //     </div>

  //     <Safetybuisness />
  //     <Helpyou />
  //     <Safetydiv />
  //     <Cardspower /> */}
  // </>



    <section className="audit-page">
    <div className="auditing-and-consulting-main">
      <div className="auditing-main-left-section">
        <AuditCard
          img=""
          title="Audit Innovation"
          auditName="Ensuring Safety Excellence with Skilled and Competent Professionals"
          para={[
            {
              text: "At Vidura International, we recognize that the success of any safety program hinges on the caliber and competence of the individuals responsible for its implementation. Our Safety Manpower Management services are designed to provide your organization with the right blend of skills and expertise to foster a culture of safety excellence.",
            },
          ]}
        />

        <Auditcard1 
        mainheading={"Key Components of Safety Manpower Management Services"} 
        img="" 
        para={[
          {
              text:"Identify, recruit, and place skilled safety professionals to meet the unique needs of your organization.",heading:"Recruitment of Safety Professionals"
             },
             {text:"Implement ongoing competency development programs to enhance the skills of your safety workforce.",heading:"Competency Development Programs"},
             {text:"Provide support in obtaining relevant safety certifications to ensure your team meets industry standards.",heading:"Certification Assistance"},{
              text:"Strategically plan and deploy safety manpower based on the requirements of your projects and operations.",heading:"Manpower Planning and Deployment"
             }
        ]} />
        <h5 style={{color:"green", display:"flex" , justifyContent:"center",alignItems:"center"}}>Invest in Safety Excellence. Explore our Safety Manpower Management Services at Vidura International.</h5>
       
      </div>
      <div className='auditing-main-right-section'>
              <AuditRightSectionCard
               mainheading={"Why Choose Vidura International for Safety Manpower Management"} 
               content={[
                  {text:"Leverage our experience in recruiting and placing safety ", heading:"Expertise in Safety Recruitment"},
                  {text:"Ensure that your safety team is equipped with the latest knowledge and skills through ongoing training programs.",heading:"Continuous Skill Development"},
                  {text:"Guarantee compliance with safety regulations by deploying qualified and certified safety personnel.", heading:"Adherence to Regulatory Standards"},
                 
               ]} />
               <h4 style={{color:"green", display:"flex" , paddingTop:"3rem", justifyContent:"center",alignItems:"center"}}>We have safety professionals who have done their ADIS from State and Central government approved collages based on company requirements</h4>
          </div>
    </div>
   
  </section>
  
 
  );
}

export default Manpowermangement;
