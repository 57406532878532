import React from 'react'
import CourseTableSheet from './CourseTableSheet'
import  styles from './NEBOSHCourses.module.scss'
import { Box, Grid, Typography } from '@mui/material'

const NEBOSHCourses = () => {
    return (
        <Grid className={styles.NEBOSH_Courses}>
            <Box sx={{ marginBlockStart: 5, marginBlockEnd: 2 }}>
                <Typography variant='h4' textTransform={"capitalize"} textAlign={"center"} marginY={8} fontSize={35} fontWeight={700}>We are providing <br></br> these Certificates and Courses</Typography>
            </Box>
            <CourseTableSheet
                S_num={0}
                subject={''}
                duration={0}

            />
            <h1>Hello</h1>
        </Grid>
    )
}

export default NEBOSHCourses