
//         {/* <section className='auditing-services-right-section'>
//             <h3 className='auditing-header-strip'>Why Vidura International?</h3>
//             <div className='audit-services'>
//                 <h5>Comprehensive Audits</h5>
//                 <p>
//                 Our experienced HSE & ESG professionals conduct thorough audits, examining every facet of your organization's HSE & ESG & ESG practices.
//                 </p>
//             </div>

//             <div className='audit-services'>
//                 <h5>Tailored Solutions & Client-Centric Approach</h5>
//                 <p>
//                 Recognizing the uniqueness of each business, our HSE & ESG audits are customized to align with your specific industry, regulatory requirements, and organizational goals to ensure maximum relevance and effectiveness
//                 </p>
//             </div>

//             <div className='audit-services'>
//                 <h5>Compliance Assurance: </h5>
//                 <p>
//                 Stay ahead of regulatory changes with our audits. We provide clear insights into compliance levels, helping you address any gaps and mitigate potential risks.
//                 </p>
//             </div>

//             <div className='audit-services'>
//                 <h5>Continuous Improvement </h5>
//                 <p>
//                 Our audit reports not only identify areas for improvement but also offer practical recommendations to enhance your HSE & ESG performance continually.
//                 </p>
//             </div>

//             <div className='audit-services'>
//                 <h5>Proactive Risk Management </h5>
//                 <p>
//                 Anticipate and mitigate potential risks before they escalate. Our audits are designed to identify hazards and provide proactive risk management strategies.
//                 </p>
//             </div>

//             <div className='audit-services'>
//                 <h5>Expertise </h5>
//                 <p>
//                  Our team comprises seasoned HSE & ESG professionals with a wealth of experience across diverse industries and countries.
//                 </p>
//             </div>

//             <div className='audit-services'>
//                 <h5>Commitment to Excellence: </h5>
//                 <p>
//                 Vidura International is dedicated to helping you achieve and maintain the highest standards of health, safety, and environmental practices by following legal requirements, with implementing national and international best practices.
//                 </p>
//             </div>

       
//         </section> */}
//        

import React, { FunctionComponent } from 'react';
import './AuditingStyle.css';

interface AuditRightSectionCardProps {
    mainheading: string;
    content: { heading: string; text: string }[];
}

const AuditRightSectionCard: FunctionComponent<AuditRightSectionCardProps> = ({ mainheading, content }) => {

    const handleDownload = () => {
        console.log("download");
    };

    return (
        <>
            <section className='auditing-services-right-section'>
                <h3 className='auditing-header-strip'>{mainheading}</h3>
                {content.map((item, index) => (
                    <div key={index} className='audit-services'>
                        <h5>{item.heading}</h5>
                        <p>{item.text}</p>
                    </div>
                ))}
            </section>
        </>
    );
};

export default AuditRightSectionCard;

