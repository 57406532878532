import React from 'react'
import CertificateAndCourse from '../component/CertificateAndCourse/CertificateAndCourse'
import MainLayout from '../component/Layout/MainLayout'

const CertificatePage = () => {
  return (
    <MainLayout>
      <CertificateAndCourse />
    </MainLayout>
  )
}

export default CertificatePage