import React,{useState} from 'react'
import './News.css';
import Newssidebar from './Newssidebar';
import NewsCard from './Newscard';
// import Media from './Media';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';



function News() {
  const [isOpenNav,setIsOpenNav]=useState(true)
  const togglrNav=()=>{
      setIsOpenNav(!isOpenNav)
  }
  return (
    <>
      <div className='newsborder'>
        <div className='newstag'>
          <h1>NEWS & EVENTS</h1>
          {/* <h3 onClick={togglrNav}>button</h3> */}
         <ArrowDropDownIcon onClick={togglrNav}  className='arrow' style={{ color: 'red', fontSize:'4rem' }} />
        </div>
        </div> 
       
       <div className='differentcard'>
       <Newssidebar isOpenNav={isOpenNav} />
        <NewsCard/>
       </div>

       {/* <Media/> */}
        
        
    </>
  )
}

export default News
