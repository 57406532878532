import React from "react";
import "./Nihnews.css";
import img1 from "../../constrain/Images/download.jpeg";
import img2 from "../../constrain/Images/save-earth-day-poster-environment-day-nature-green-ai-generative-glossy-background-images-tree-and-water-free-photo.jpg";
import img3 from "../../constrain/Images/flowers-276014_1280.jpg";
import { Grid, Typography, Divider } from "@mui/material";

// interface NewsItem {
//   id: number;
//   img: string;
//   label: string;
// }

function Nihnews() {
  const data = [
    {
      id: 1,
      img: img1,
      heading1: " Vidura Research",
      heading:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet deserunt quibusdam consectetur enim veritatis, dicta voluptate? Neque consectetur veritatis et recusandae voluptatem eius, nostrum eveniet? Repellat adipisci pariatur ducimus! Blanditiis.",
    },
    {
      id: 2,
      img: img1,
      heading1: "Vidura News",
      heading:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet deserunt quibusdam consectetur enim veritatis, dicta voluptate? Neque consectetur veritatis et recusandae voluptatem eius, nostrum eveniet? Repellat adipisci pariatur ducimus! Blanditiis.",
    },
    {
      id: 3,
      img: img1,
      heading1: "Vidura Record",
      heading:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet deserunt quibusdam consectetur enim veritatis, dicta voluptate? Neque consectetur veritatis et recusandae voluptatem eius, nostrum eveniet? Repellat adipisci pariatur ducimus! Blanditiis.",
    },
  ];
  return (
    <>
      <div className="nihnews">
        <h4>NIH News Publications</h4>
        <Divider sx={{ margin: 2 }} />

        <Grid container>
          {data.map((item) => {
            return (
              <Grid container key={item.id} item md={6} className="news-publication">
                <Grid item md={4}  className="img-container">
                  <img src={item.img} alt="img" />
                </Grid>
                <Grid item md={6} className="content-container">
                  <Typography variant="h5">{item.heading1}</Typography>
                  <Typography>{item.heading}</Typography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </>
  );
}

export default Nihnews;
