import React from 'react'
import './SubMenuCard.css'
import { Box, Grid, Typography } from '@mui/material'
import CommonButtton from '../../../component/CommonButtton/CommonButtton'

const SubMenuCard = ({ data }: any) => {
    return (
        <Grid className='submenuCard'>
            <Box>
                <img src={data.image} alt='img' />
                <Typography textAlign={"center"} paddingX={5} paddingTop={4} paddingBottom={2} align='left' variant='h4' textTransform={"capitalize"} fontSize={25} fontWeight={600}>
                    {data.heading}
                </Typography>
                {/* <Typography paddingX={5}>{data.description}</Typography> */}
                {/* <CommonButtton
                    name={'Get A Quote'} /> */}
            </Box>
        </Grid>
    )
}

export default SubMenuCard;