import React from 'react'
import styles from './Banner.module.scss'
import { Grid, Typography } from '@mui/material'
import CommonButtton from '../../CommonButtton/CommonButtton'
import { useNavigate } from 'react-router-dom'
import bgImage from '../../constrain/Images/about_banner_img.jpg'

export interface IBanner {
    img: string;
    heading: string;
    description: string;
    isAction: boolean;

}
const Banner = ({ img, heading, description, isAction }: IBanner) => {
    const navigation = useNavigate()
    return (
        <Grid className={styles.bannerContainer}>
            <Grid className={styles.bannerImage}>
                <img src={img} alt='img' />
            </Grid>
            <Grid className={styles.bannerOpecity}>
            </Grid>
            <Grid className={styles.bannerContent}>
                <Typography variant='h2' fontWeight={600} fontSize={50}>{heading}</Typography>
                <Typography>{description}</Typography>
                {isAction ? <CommonButtton
                    name={'Contact Us'}
                    handleClick={(() => navigation('/contact'))}
                /> : ""}
            </Grid>
        </Grid>
    )
}

export default Banner