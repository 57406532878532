import React, { FunctionComponent, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./auditingAndConsulting.css";
import AuditCard from "./AuditCard";
import hseAudit1 from "../../constrain/Images/What-is-HSE-Audit.jpg";
import card2img from "../../constrain/Images/Screenshot (217).png"
import card1img from "../../constrain/Images/Screenshot (236).png"
import AuditRightSectionCard from "./AuditRightSectionCard";
import Auditcard1 from "./Auditcard1";

interface StandardSpecificAuditProps {}

const StandardSpecificAudit: FunctionComponent<
  StandardSpecificAuditProps
> = () => {
  const [isVisible, setIsVisible] = useState(false);
  const handleVisibilityChange = () => {
    const element = document.querySelector(".audit-show-when-visible");
    if (element) {
      const rect = element.getBoundingClientRect();
      const elementIsVisible =
        rect.top < window.innerHeight && rect.bottom >= 0;
      setIsVisible(elementIsVisible);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleVisibilityChange);
    return () => {
      window.removeEventListener("scroll", handleVisibilityChange);
    };
  }, []);

  return (
    <section className="audit-page">
      <div className="auditing-and-consulting-main">
        <div className="auditing-main-left-section">
          <AuditCard
            img={card1img}
            title="Audit Innovation"
            auditName="Precision Auditing for Targeted Excellence with Vidura International"
            para={[
                { text: "At Vidura International, we recognize the importance of adhering to specific standards within your industry. Our Standard-Specific Audit Services are tailored to meet the unique requirements of international standards, ensuring your organization's compliance and excellence." },
            ]}
          />
          <Auditcard1 
          mainheading={"Our Standard-Specific Audit Process"} 
          img="" 
          para={[
            {
                text:" Understand your organization's specific industry standards, operational processes, and compliance goals.",heading:"Needs Assessment"
               },
               {text:"Conduct a detailed audit focusing on the requirements outlined in specific industry standards.",heading:"Specialized Evaluation"},
               {text:"In-depth review of documentation, protocols, and processes to ensure adherence to the targeted standards.",heading:"Data Analysis "},{
                text:"Receive a detailed report with specific recommendations for aligning with and excelling in the chosen industry standards.",heading:"Comprehensive Reporting"
               }
          ]}/>
        </div>
        <div className='auditing-main-right-section'>
                <AuditRightSectionCard 
                mainheading={"Why Vidura International for Standard-Specific Audits?"} 
                content={[
                    {text: ": Our auditors possess in-depth knowledge of various industry-specific standards, providing targeted and effective auditing services.", heading:"Specialized Expertise"},
                    {text:"Ensure your organization meets the stringent criteria of specific standards, minimizing risks and fostering a culture of excellence.",heading:"Comprehensive Compliance"},
                    {
                        text:"Tailor-made audits aligning with international standards relevant to your sector, offering precise insights for improvement.",heading:"Industry Alignment"
                    },
                    {text:"Stay updated with evolving standards, allowing your organization to adapt and thrive in a dynamic regulatory environment.", heading:"Continuous Conformance"}
                ]} />
            </div>
      </div>
      {/* <div className="audit-show-when-visible">
        <div className={`dynamic-audit-content ${isVisible ? "visible" : ""}`}>
          <h4>Reduce Audit Burden</h4>
          <p>
            Achieving a higher quality, more insight audit shouldn't take more
            effort from you. Through focus, flexibility, and and advanced audit
            technology, Vidura delivers more relevant insights , all while
            reducing the burden on your team
          </p>
          <p className="discover-more">
            <div className="discover-more-about-audit-btn">+</div>
            Discover the benifit of a more focused audit
          </p>
        </div>
        <div className={`dynamic-audit-content ${isVisible ? "visible" : ""}`}>
          <h4>Deeper Insight</h4>
          <p>
            Insight come in many shapes and sizes. Vidura produces insights that
            are tailored for your specific business and the industry it operates
            in. This focused approach enhances audit quality and improves your
            overall experience
          </p>
          <p className="discover-more">
            <div className="discover-more-about-audit-btn">+</div>
            Discover the benifit of a more focused audit
          </p>
        </div>
        <div className={`dynamic-audit-content ${isVisible ? "visible" : ""}`}>
          <h4>A Smarter Audit</h4>
          <p>
            Driving value through a high-quality audit is not only what we do,
            it's in our DNA. Our diverse and highly skilled teams are constantly
            analyzing changes in your risk profile to focus on the risks facing
            your business
          </p>

          <p className="discover-more">
            <div className="discover-more-about-audit-btn">+</div>
            Discover the benifit of a more focused audit
          </p>
        </div>
      </div> */}
    </section>
  );
};

export default StandardSpecificAudit;
