import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import AuditingHeader from '../../component/AuditingAndConsulting/AuditingAndConsultingHeader';
import MainLayout from '../../component/Layout/MainLayout';
import AuditingAndConsulting from '../../component/AuditingAndConsulting/HSEAuditAndConsulting';
import Footer from '../../component/Footer/Footer';
import StandardSpecificAudit from '../../component/AuditingAndConsulting/StandardSpecificAudit';
interface StandarSafetyAuditingProps {

}

const StandarSafetyAuditing: FunctionComponent<StandarSafetyAuditingProps> = () => {

    const handleDownload = () => {
        console.log("download");
    };

    return (
        <div className='auditing-and-consulting'>
        <MainLayout>
            <AuditingHeader 
            heading={'Standard Specific Audit'} />
            <StandardSpecificAudit/>
            <Footer/>
        </MainLayout>
    </div>
    );
};

export default StandarSafetyAuditing;
