import React from 'react'
import MainLayout from '../component/Layout/MainLayout'
import ServiceCard from '../component/Services/ServiceCard'
import serviceImg from '../constrain/Images/featured11.webp'
import './pageStyle.css'
import AdvisoryCard from '../component/Services/AdvisoryCard'
import ESGAdvisory from '../constrain/Images/esg-advisory.jpg'
import DevelopmentAdvisory from '../constrain/Images/real-Estate-development-advisory.jpg'
import HSEAdvisory from '../constrain/Images/hse-advisory.jpg'
import DevelopProcedure from '../constrain/Images/as-is-process.png'
import ServiceSectorHeader from '../component/Services/SectorAndService'
import Footer from '../component/Footer/Footer'


const advisoryData = [
  {
    id: 1, img: ESGAdvisory, title: "Construction skill development Council", desc: "Joint our most awaited and self paced course for NDA and prepare yourself for the academy",
    link: "More Details", btn: "Download Brochure", download: "ndabrochure.pdf"
  },
  {
    id: 2, img: DevelopmentAdvisory, title: "Hydrocarbon Skill development council", desc: "Join our most awaited and self paced course for CDS and prepare yourself for the academy",
    link: "More Details", btn: "Download Brochure", download: "cds.pdf"
  },
  {
    id: 3, img: HSEAdvisory, title: "Skill council for Green jobs", desc: "Join our most awaited and self paced course for AFCAT and prepare yourself for the academy",
    link: "More Details", btn: "Download Brochure", download: "afcat.pdf"
  },
  {
    id: 4, img: DevelopProcedure, title: "Skill council for Mining sector", desc: "Join our most awaited and self paced course for AFCAT and prepare yourself for the academy",
    link: "More Details", btn: "Download Brochure", download: "ota.pdf"
  },
  {
    id: 5, img: DevelopmentAdvisory, title: "Skill council for Telecom sector", desc: "Join our most awaited and self paced course for CDS and prepare yourself for the academy",
    link: "More Details", btn: "Download Brochure", download: "cds.pdf"
  }
]

const SkillDevelopment = () => {
  return (
    <MainLayout>
      <ServiceSectorHeader serviceName="Skill Development" />
      <div className="advisory-section" style={{backgroundColor:"black"}}>
        {advisoryData.map((advisory) => (
          <AdvisoryCard
            key={advisory.id}
            id={advisory.id}
            img={advisory.img}
            title={advisory.title}
            desc={advisory.desc}
            link={advisory.link}
            btn={advisory.btn}
          />
        ))}
      </div>
      <Footer/>
    </MainLayout>
  )
}

export default SkillDevelopment
