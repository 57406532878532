import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import './AuditingStyle.css'

interface AuditingHeaderProps {
    heading:string;
}

const AuditingHeader: FunctionComponent<AuditingHeaderProps> = ({heading}) => {

    const handleDownload = () => {
        console.log("download");
    };

    return (
        <section className='auditing-and-consulting-header'>
         <div className='auditing-left-section'>
            <h1 style={{fontSize:"3rem", textAlign:"center"}}>{heading}</h1>
         </div>

        </section>
    );
};

export default AuditingHeader;
