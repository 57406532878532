import React from 'react'
import MainLayout from '../component/Layout/MainLayout'
import Staffandnurse from '../component/Medicalnurse/Staffandnurse'
import Footer from '../component/Footer/Footer'
import AuditingHeader from '../component/AuditingAndConsulting/AuditingAndConsultingHeader'

function Medicalnursing() {
  return (
    <>


    {/* <MainLayout>
       <Staffandnurse/>
        <Footer/>
    </MainLayout> */}
      
    <div className='auditing-and-consulting'>
            <MainLayout>
                <AuditingHeader heading="Medical/nursing staff deployment"/>
                <Staffandnurse/>
                <Footer/>
            </MainLayout>
        </div>
    </>
  )
}

export default Medicalnursing
