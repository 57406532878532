import React from 'react'

const UserEnrollmentPage = () => {
  return (
    <div>
      User Enrollment
    </div>
  )
}

export default UserEnrollmentPage
