import React, { FunctionComponent } from 'react';
import AuditingHeader from '../../component/AuditingAndConsulting/AuditingAndConsultingHeader';
import MainLayout from '../../component/Layout/MainLayout';
import Footer from '../../component/Footer/Footer';
import ElectricalSafetyAudit from '../../component/AuditingAndConsulting/ElectricalSafetyAudit';
interface ElectricalSafetyAuditingAndConsultingProps {

}

const ElectricalSafetyAuditingAndConsulting: FunctionComponent<ElectricalSafetyAuditingAndConsultingProps> = () => {

    const handleDownload = () => {
        console.log("download");
    };

    return (
        <div className='auditing-and-consulting'>
        <MainLayout>
            <AuditingHeader heading={'Electrical Safety Audit'} />
            <ElectricalSafetyAudit/>
            <Footer/>
        </MainLayout>
    </div>
    );
};

export default ElectricalSafetyAuditingAndConsulting;
