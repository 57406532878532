import React from "react";
import "./Information.css";


function Information() {
  return (
    <>
      <div className="">
        <div className="contactinformation">
          <h1>Connect with us </h1>
          <p>
            Follow us on social to keep up-to-date <br />
            with our events, culture and people
          </p>
          <div className="icons">
          <a href="https://www.facebook.com/vidura.international?mibextid=PzaGJu" target="blank"><i className="fab fa-facebook"></i></a>
          <a href="https://instagram.com/vidura_international" target="blank"><i className="fab fa-instagram"></i></a>
          <a href="https://www.linkedin.com/in/vidura-international-337b21299/" target="blank"><i className="fab fa-linkedin"></i></a>
          {/* <a href=""> <i className="fab fa-twitter"></i></a> */}
          </div>
          
        </div>
        <div className="information"></div>
      </div>
    </>
  );
}

export default Information;
