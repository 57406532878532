import React from 'react'
import m1 from "../../constrain/Images/1.jpg";
import "./Headteam.css";

function Headteam() {
  return (
    <>
      <section className="hero-area overflow-hidden headteamcontainer">
        <div className="container">
          <div className="row justify-content-center justify-content-md-between align-items-center head-team">
            <div className="col-lg-7 col-md-6 headheading">
              <h3>Mr.Jaideep Singh</h3>
              <p>M.Tech (IIT Kanpur), BTech</p>
              <p><span>Experience :</span>  16 Years</p>
              <p>Deloitte, Shapoorji Pallonji, SolarBees</p>
            </div>

            <div className="col-lg-4 col-md-6 headimg">
              <img src={m1} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Headteam