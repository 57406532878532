import React, { FunctionComponent } from 'react';
import AuditingHeader from '../../component/AuditingAndConsulting/AuditingAndConsultingHeader';
import MainLayout from '../../component/Layout/MainLayout';
import Footer from '../../component/Footer/Footer';
import CarbonAudit from '../../component/AuditingAndConsulting/CarbonAudit';
interface CarbonAuditingAndConsultingProps {

}

const CarbonAuditingAndConsulting: FunctionComponent<CarbonAuditingAndConsultingProps> = () => {

    return (
        <div className='auditing-and-consulting'>
        <MainLayout>
            <AuditingHeader  heading="Carbon Audit" />
            <CarbonAudit/>
            <Footer/>
        </MainLayout>
    </div>
    );
};

export default CarbonAuditingAndConsulting;
