import React from 'react'
import MainLayout from '../component/Layout/MainLayout'
import Footer from '../component/Footer/Footer'
import Commondigital from '../component/Drone/Commondigital'
import MainDigital from '../component/Drone/MainDigital'
import imga from "../constrain/Images/aichat.jpg"

function Aichat() {
  return (
    <MainLayout>
        <Commondigital heading={''} />
        <MainDigital
        heading={'AI chatbot for safety and others'}
        content={[
          {
            lione: "Vidura International introduces a cutting-edge AI Chatbot designed to revolutionize safety communication within organizations. Our AI Chatbot is a dynamic solution that goes beyond conventional safety measures, offering an interactive and engaging platform for employees to access safety information, report incidents, legal requirements and receive real-time guidance.",
            litwo: "This advanced AI Chatbot is trained to understand safety protocols, answer queries, and provide immediate assistance, enhancing the overall safety culture within your organization. Whether it's delivering safety reminders, conducting virtual safety inductions, or guiding employees through emergency procedures, our AI Chatbot ensures that safety information is readily available and easily accessible.",
            li3: "Beyond safety, the AI Chatbot can be customized to support and general FAQs, making it a versatile tool for employee engagement. With machine learning capabilities, the Chatbot continuously evolves and adapts to the changing needs of your organization.",
            li4: "Integrate Vidura International's AI Chatbot into your safety initiatives and beyond, redefining the way your workforce engages with safety and operational information. Elevate communication, accessibility, and engagement with this innovative AI solution.",
            li5: "",
            li6: "",
          },
        ]}
        image={imga} 
        text={'Experience the future of safety communication by initiating a discussion with Vidura International.'}/>
        <Footer/>
    </MainLayout>
  )
}

export default Aichat