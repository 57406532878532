import React from 'react'
import MainLayout from '../component/Layout/MainLayout'
import './pageStyle.css'
import AdvisoryCard from '../component/Services/AdvisoryCard'
import ESGAdvisory from '../constrain/Images/esg-advisory.jpg'
import DevelopmentAdvisory from '../constrain/Images/real-Estate-development-advisory.jpg'
import HSEAdvisory from '../constrain/Images/hse-advisory.jpg'
import ServiceSectorHeader from '../component/Services/SectorAndService'
import Footer from '../component/Footer/Footer'


const advisoryData = [
  {
    id: 1, img: ESGAdvisory, title: "Occupational health assessment", desc: "Joint our most awaited and self paced course for NDA and prepare yourself for the academy",
    link: "More Details", btn: "Download Brochure", download: "ndabrochure.pdf"
  },
  {
    id: 2, img: DevelopmentAdvisory, title: "Occupational health centre setup", desc: "Join our most awaited and self paced course for CDS and prepare yourself for the academy",
    link: "More Details", btn: "Download Brochure", download: "cds.pdf"
  },
  {
    id: 3, img: HSEAdvisory, title: "Health surveillance ", desc: "Join our most awaited and self paced course for AFCAT and prepare yourself for the academy",
    link: "More Details", btn: "Download Brochure", download: "afcat.pdf"
  },
]

const OccupationalHealthManagement = () => {
  return (
    <MainLayout>
      <ServiceSectorHeader serviceName="Occupational Health Management"/>
      <div className="advisory-section">
        {advisoryData.map((advisory) => (
          <AdvisoryCard
            key={advisory.id}
            id={advisory.id}
            img={advisory.img}
            title={advisory.title}
            desc={advisory.desc}
            link={advisory.link}
            btn={advisory.btn}
          />
        ))}
      </div>
      <Footer/>
    </MainLayout>
  )
}

export default OccupationalHealthManagement
