import React, { lazy, Suspense } from 'react'
import MainLayout from '../component/Layout/MainLayout'
import Banner from '../component/HomeComponent/Banner'
import TagLineVideo from '../component/HomeComponent/TagLineVideo'
import Introduction from '../component/HomeComponent/Introduction'
import Footer from '../component/Footer/Footer'
// @ts-ignore
import homeVideo from '../constrain/animation/sample2.mp4'
import WeAreSection from '../component/WeAreSection/WeAreSection'
// clients logo
import logo1 from '../constrain/logos/client_abb.png'
import logo2 from '../constrain/logos/client_bsc.png'
import logo3 from '../constrain/logos/client_deloitte.png'
import logo4 from '../constrain/logos/client_essar.png'
import logo5 from '../constrain/logos/client_lanco.png'
import logo6 from '../constrain/logos/client_sp.png'
import logo7 from '../constrain/logos/client_summit.png'
import logo8 from '../constrain/logos/client_vedanta.png'
import logo9 from '../constrain/logos/jacobs.png'
import EnquiryForm from '../component/Enquiry/EnquiryFrom'
import { ShareDetailsProvider } from '../context/ShareDetailsContext'


const HeroCardSection = lazy(() => import('../component/HomeComponent/HeroCardSection'));
const HeroBanner = lazy(() => import('../component/HeroSection/HeroBanner'));
const OurClient = lazy(() => import('../component/HomeComponent/OurClient'));


interface Logo {
  id: number;
  img: string;
}

interface video {
  video1: string;
  slide_2: string;
}
const logos: Logo[] = [
  { id: 1, img: logo1 },
  { id: 2, img: logo2 },
  { id: 3, img: logo3 },
  { id: 4, img: logo4 },
  { id: 5, img: logo5 },
  { id: 6, img: logo6 },
  { id: 7, img: logo7 },
  { id: 8, img: logo8 },
  { id: 9, img: logo9 }
]
// const slide_1 = require('../constrain/animation/safety_Construction_Video.mp4');
const slide_2 = require('../constrain/animation/VIDURAfinal-video.mp4')
// const video1 = require('../constrain/animation/safety-construction1.mp4')

const data: any = [ slide_2]

const HomePage = () => {
  const videoSrc = "https://res.cloudinary.com/dznncrjxh/video/upload/v1694496860/ViduraHomeTagLineVideo_swi1nj.mp4"
  const tagline = 'Innovating Ideas to take the clients to level next” / “One stop solution for all your ESG and HSE needs';

  return (
    <div className='home-popup' style={{position:"relative"}}>
    <MainLayout>
      {/* <TagLineVideo videoSrc={testVideo} tagline={tagline} /> */}
      <TagLineVideo data={data} />
      <EnquiryForm/>
      <Introduction />
      <Suspense fallback={<div>Loading...</div>}>
        <HeroCardSection />
      </Suspense>
      <WeAreSection />
      <Suspense fallback={<div>Loading...</div>}>
        <HeroBanner />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <OurClient logos={logos} />
      </Suspense>

      <Footer />
    </MainLayout>
    </div>
  )
}

export default HomePage
