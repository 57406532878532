import React, { lazy, Suspense } from 'react'
import MainLayout from '../component/Layout/MainLayout'
import Trainig from '../component/Trainigandlearning/Trainig'
const Footer = lazy(() => import('../component/Footer/Footer'));

function Training() {
  return (
    <MainLayout>
      <Trainig />
      <Suspense fallback={<div>Loading...</div>}>
        <Footer />
      </Suspense>

    </MainLayout>
  )
}

export default Training
