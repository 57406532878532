import React, { lazy, Suspense } from 'react'
import bgimg from "../../constrain/Images/bgimage.webp"
import "./Training.css";
import Flowtraining from './Flowtraining';
const Trainingcards = lazy(() => import('./Trainingcards'));
const Investskill = lazy(() => import('./Investskill'));
const Cardcrediential = lazy(() => import('./Cardcrediential'));
const Whatsnew = lazy(() => import('./Whatsnew'));




function Trainig() {
   return (
      <>
         <div className='training-container'>

            <div >
               <div className='trining-img'>
                  <img src={bgimg} alt="" />
               </div>
               <div className='trainingcontent'>
                  <h2>Training on Different Safety Modules</h2>
                  <h4>Elevate Safety Standards Through Specialized Training with Vidura International</h4>
                  <div className='trainingbutton'>
                     
                  </div>

               </div>
            </div>


            <div className='skilltraining'>
               <h6>At Vidura International, we recognize the pivotal role of comprehensive training in fostering a safe and secure work environment. Our Training on Different Safety Modules is meticulously designed to empower professionals across various industries with the knowledge and skills essential for ensuring occupational health, safety, and environmental sustainability.</h6>
              
            </div>
            <Suspense fallback={<div>Loading...</div>}>
               <Trainingcards />
               <Investskill />
               {/* <Cardcrediential /> */}
               <Flowtraining/>
               <Whatsnew />
            </Suspense>

         </div>
      </>
   )
}

export default Trainig
