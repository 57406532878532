import React, { useEffect, useState } from 'react';
import { Grid, Input, TextareaAutosize, Typography, Box } from '@mui/material'
import InputField from '../InputField/InputField'
import CommonButtton from '../CommonButtton/CommonButtton'
import emailjs from '@emailjs/browser';
import './enquiryform.css'
const EnquiryForm = () => {
    const [isClosed, setIsClosed] = useState(true)
    const [phoneNumber, setPhoneNumber] = useState("")
    const [fullName, setFullName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")

    const closeEnquiryForm = () => {
        setIsClosed(true)
    }

    const sendInfo = (e: any) => {
        e.preventDefault()
        // console.log(fullName, email, phoneNumber, message)
    
        emailjs.sendForm('service_tx8fm25', 'template_zhycwvm', e.target, '8si9wlnxsZLmEBB_n')
            .then((response) => {
                console.log('Email sent successfully:', response);
                alert("Email sent successfully:")
            })
            .catch((error) => {
                console.error('Email sending failed:', error);
            });

        e.target.reset();

    }

    useEffect(() => {
        setTimeout(() => {
            setIsClosed(false)
        }, 2000)
    }, [])
    return (
        <div className={`${isClosed ? "close-enquiry-form" : "open-enquiry-form"}`}>
            <Grid className="enquiry-popup-formContainer">
                <Box className='get-in-touch'> <span className='close-popup' onClick={closeEnquiryForm}>X</span>
                <Typography variant='h2' fontWeight={600} fontSize={20} align='center' color="white" >GET IN TOUCH</Typography>
                </Box>

                <form className="enquiry-popup-formContainer" onSubmit={sendInfo}>
                    <Grid className="popup-form">
                        <InputField type="text" name="fullName" placeholder={"Full Name"}  />
                        <InputField type="text" name="phone" placeholder={"Phone"}  />
                        <InputField type="email" name="email" placeholder={"Email"} />
                        <TextareaAutosize name="message" className="popup-messageContainer"  placeholder='Message' />
                        <div className='place-send-btn-center'>
                        <button type='submit' className='send-info-btn'>Send</button>
                        </div>
                    </Grid>
                </form>
            </Grid>
            <div className='backround-div'>

            </div>
        </div>
    );
}

export default EnquiryForm
