import React from "react";
import "./Supply.css";
import AuditRightSectionCard from "../AuditingAndConsulting/AuditRightSectionCard";
import AuditCard from "../AuditingAndConsulting/AuditCard";
import Auditcard1 from "../AuditingAndConsulting/Auditcard1";


function Supplytrades() {
  return (
    // <>
    //   <div className="supplycontainer">
    //     <div>
    //       <div className="supply-img">
    //         <img src={image} alt="" />
    //       </div>
    //       <div className="supplycontent">
    //         <h2>Supply of different trade professionals </h2>
    //       </div>
    //     </div>
    //     <Supplycards/>
    //     <Tradeprfessional/>
    //   </div>
    // </>

    <section className="audit-page">
    <div className="auditing-and-consulting-main">
      <div className="auditing-main-left-section">
        <AuditCard
          img=""
          title="Audit Innovation"
          auditName="Meeting Your Project Needs with Skilled and Diverse Trade Professionals"
          para={[
            {
              text: "Vidura International is your strategic partner in ensuring that your projects have access to a diverse and skilled workforce across various trades. Our Supply of Different Trade Professionals service is designed to match your specific project requirements with experienced professionals, fostering efficiency, quality, and successful project outcomes.",
            },
          ]}
        />

        <Auditcard1 
        mainheading={"Key Components of Supplying Different Trade Professionals"} 
        img="" 
        para={[
          {
              text:"Source and recruit skilled professionals in specific trades, ensuring a match with your project's technical requirements.",heading:"Trade-Specific Recruitment"
             },
             {text:"Adapt staffing levels based on the evolving needs of your projects, ensuring optimal resource utilization.",heading:"Flexible Staffing Solutions"},
             {text:"Implement quality control measures to maintain high standards in workmanship and project delivery.",heading:"Quality Assurance"}
        ]} />
          <h4  style={{color:"green"}}>Empower Your Projects with the Right Trades. Explore our Supply of Different Trade Professionals Services at Vidura International.</h4>
      </div>
      <div className='auditing-main-right-section'>
              <AuditRightSectionCard
               mainheading={"Empower Your Projects with the Right Trades. Explore our Supply of Different Trade Professionals Services at Vidura International."} 
               content={[
                  {text:" Tap into our vast network of skilled professionals across various trades to meet the unique needs of your projects (e.g., welders, operators, unskilled workmen etc.)", heading:"Extensive Talent Pool"},
                  {text:"Benefit from tailored staffing solutions that align with the specific trades required for your project.",heading:"Customized Staffing Solutions"},
                  {text:"Ensure the deployment of certified and experienced trade professionals who bring industry expertise to your projects.", heading:"Certified and Experienced Professionals"},
                  {text:"Guarantee compliance with industry regulations and standards by deploying professionals with the necessary certifications.",heading:"Adherence to Regulatory Standards"}
               ]} />
          </div>
    </div>
   
  </section>
  );
}

export default Supplytrades;
