import React from 'react';
import './App.css';
import { Route, Routes, useLocation } from "react-router-dom"
import HomePage from './Pages/HomePage';
import AboutPage from './Pages/AboutPage';
import ContactPage from './Pages/ContactPage';
import ServicePage from './Pages/ServicePage';
import GalleryPage from './Pages/GalleryPage';
import EnquiryPage from './Pages/EnquiryPage';
import UserEnrollmentPage from './Pages/UserEnrollmentPage';
import CoursesPage from './Pages/CoursesPage';
import ArticlesPage from './Pages/ArticlesPage';
import MagazinesPage from './Pages/MagazinesPage';
import NewsPage from './Pages/NewsPage';
import EsgAdvisory from './Pages/HseMenuPage/EsgAdvisory';
import DevelopmentAdvisory from './Pages/DevelopmentAdvisory';
import IndustriesPage from './Pages/IndustriesPage';
import CarrerPage from './Pages/CarrerPage';
import Newsandevent from './Pages/Newsandevent';
import HSEPage from './Pages/HSEPage';
import SkillDevelopment from './Pages/SkillDevelopmentPage';
import TrainingPage from './Pages/TrainingPage';
import FireSafetyManagement from './Pages/FireSafetyManagement';
import OccupationalHealthManagement from './Pages/OccupationalHealthManagement';
import ShowImage from './Pages/ShowImage';
import ESGManagementPlan from './Pages/ESGMenuPage/ESGManagemntPlan';
import EnvironmentAspectAndImpactStudy from './Pages/ESGMenuPage/EnvironmentAspectAndImpact';
import ESGReportingPage from './Pages/ESGMenuPage/ESGReporting';
import AuditingPage from './Pages/ESGMenuPage/AuditingPage';
import WaterAndWasteFootPrinting from './Pages/ESGMenuPage/WaterAndWasteFootPrinting';
import SocialAccountability from './Pages/ESGMenuPage/SocialAccountability';
import CompetenceTraining from './Pages/ESGMenuPage/CompetenceTraining';
import GHGAccounting from './Pages/ESGMenuPage/GHGAccounting';
import GreenBuilldingService from './Pages/ESGMenuPage/GreenBuildingService';
import GreenhouseGasAccounting from './Pages/ESGMenuPage/GreenhouseGasAccounting';
import CarbonCreditAdvisory from './Pages/ESGMenuPage/CarbonCreditAdvisory';
import CorporateSocialResponsibilty from './Pages/ESGMenuPage/CorporateSocialResponsibilty';
import ConstructionSkillCouncilPage from './Pages/SkillDevelopmentManuPage/ConstructionSkillCouncilPage';
import HydroCarbonSkillCouncilPage from './Pages/SkillDevelopmentManuPage/HydroCarbonSkillCouncilPage';
import GreenJobSkillCouncilPage from './Pages/SkillDevelopmentManuPage/GreenJobSkillCouncilPage';
import MiningSectorSkillCouncilPage from './Pages/SkillDevelopmentManuPage/MiningSectorSkillCouncilPage';
import TelecomeSectorSkillCouncilPage from './Pages/SkillDevelopmentManuPage/TelecomSectorSkillCouncilPage';
import AdvisoryHSEManagementPage from './Pages/HseMenuPage/AdvisoryHSEManagementPage';
import HSEManagementReportingPage from './Pages/HseMenuPage/HSEManagementReportingPage';
import HSEAuditingPage from './Pages/HseMenuPage/HSEAuditingPage';
import HSECompetenceDevelopmentTraining from './Pages/HseMenuPage/HSECompetenceDevelopmentTrainings';
import HSECulreBehaviourBasedTraining from './Pages/HseMenuPage/HSECultureBehaviourBasedTraining';
import HandholdingAward from './Pages/HseMenuPage/HandholdingAward';
import HSESoftware from './Pages/HseMenuPage/HSESoftware';
import HSEVideoAnimation from './Pages/HseMenuPage/HSEVideoAnimation';
import ThirdPartyInspection from './Pages/HseMenuPage/ThirdPartyInspections';
import HSEEnvironmentMonitoring from './Pages/HseMenuPage/HSEEnvironmentMonitoring';
import HSEGuidance from './Pages/HseMenuPage/HSEGuidance';
import GwoPage from './Pages/Trainings/GWOPage';
import FarmTocliPage from './Pages/Trainings/FarmTocliPage';
import FirePreventionProtection from './Pages/Trainings/FirePreventionAndProtection';
import EngineerDesignPage from './Pages/FireSafetyManagement/EngineerDesignPage';
import FireFightingEquipment from './Pages/FireSafetyManagement/FireFightingEquipment';
import FireNOCPage from './Pages/FireSafetyManagement/FireNOCPage';
import FireSystemAuditingPage from './Pages/FireSafetyManagement/FireSystemAuditingPage';
import OccupationalHealthAssessment from './Pages/OccupationalHealthManagement/OccupationalHealthAssessment';
import OccupationalHealthCentre from './Pages/OccupationalHealthManagement/OccupationalHealthCentre';
import HealthSurveillance from './Pages/OccupationalHealthManagement/HealtyhSurveillance';
import FoodSafty from './Pages/FoodSafety/FoodSafetyPage';
import Training from './Pages/Training';
import Supply from './Pages/Supply';
import Manpowermangement from './Pages/Manpowermangement';
import Medicalnursing from './Pages/Medicalnursing';
import HSEAuditingAndConsulting from './Pages/AuditingConsulting/HSEAuditing';
import ESGAuditingAndConsulting from './Pages/AuditingConsulting/ESGAuditing';
import CarbonAuditingAndConsulting from './Pages/AuditingConsulting/CarbonAuditing';
import IMSAuditingAndConsulting from './Pages/AuditingConsulting/IMSAuditing';
import EnergyAuditingAndConsulting from './Pages/AuditingConsulting/EnergyAuditing';
import ProcessSafetyAuditing from './Pages/AuditingConsulting/ProcessSafetyAuditing';
import ElectricalSafetyAuditingAndConsulting from './Pages/AuditingConsulting/ElectricalSafetyAuditing';
import EnvironmentalAuditingAndConsulting from './Pages/AuditingConsulting/EnvironmentalAuditing';
import StandarSafetyAuditing from './Pages/AuditingConsulting/StandardSafetyAuditing';
import Droneandcamera from './Pages/Droneandcamera';
import Virtualdigital from './Pages/Virtualdigital';
import EnergyManagement from './Pages/EnergyManagement';
import Wastemanagement from './Pages/Wastemanagement';
import Aichat from './Pages/Aichat';
import Leadership from './Pages/Leadership';
import AnimationsPage from './Pages/AnimationsPage';
import DigitalAppPage from './Pages/DigitalAppPage';
import LearningsystemPage from './Pages/LearningsystemPage';
import AerospaceDefence from './Pages/IndustriesMenuPage/AerospaceDefence/AerospaceDefence';
import FinanceService from './Pages/IndustriesMenuPage/FinanceService/FinanceService';

import PublicSector from './Pages/IndustriesMenuPage/PublicSector/PublicSector';
import Agricultre from './Pages/IndustriesMenuPage/Agricultre/Agricultre';
import Electronics from './Pages/IndustriesMenuPage/Electronics/Electronics';
import RealEstate from './Pages/IndustriesMenuPage/RealEstate/RealEstate';
import AutomotiveAndAssembly from './Pages/IndustriesMenuPage/AutomotiveAndAssembly/AutomotiveAndAssembly';
import Healthcare from './Pages/IndustriesMenuPage/Healthcare/Healthcare';
import Retail from './Pages/IndustriesMenuPage/Retail/Retail';
import Chemicals from './Pages/IndustriesMenuPage/Chemicals/Chemicals';
import LifeScience from './Pages/IndustriesMenuPage/LifeScience/LifeScience';
import Semiconductors from './Pages/IndustriesMenuPage/Semiconductors/Semiconductors';
import ConsumerPackagedGoods from './Pages/IndustriesMenuPage/ConsumerPackagedGoods/ConsumerPackagedGoods';
import MetalsAndMining from './Pages/IndustriesMenuPage/MetalsAndMining/MetalsAndMining';
import SocialSector from './Pages/IndustriesMenuPage/SocialSector/SocialSector';
import Education from './Pages/IndustriesMenuPage/Education/Education';
import OilAndGas from './Pages/IndustriesMenuPage/OilAndGas/OilAndGas';
import TechMediaTelecom from './Pages/IndustriesMenuPage/TechMediaTelecom/TechMediaTelecom';
import ElectricPowerNaturalGas from './Pages/IndustriesMenuPage/ElectricPowerNaturalGas/ElectricPowerNaturalGas';
import PaperForestProductPack from './Pages/IndustriesMenuPage/PaperForestProductPack/PaperForestProductPack';
import TravelLogisticsAndInfracture from './Pages/IndustriesMenuPage/TravelLogistics&Infracture/TravelLogisticsAndInfracture';
import EngineeringConstruction from './Pages/IndustriesMenuPage/EngineeringConstruction/EngineeringConstruction';
import PrivateEquityAndPrincipalInvestor from './Pages/IndustriesMenuPage/PrivateEquityAndPrincipalInvestor/PrivateEquityAndPrincipalInvestor';
import OccupationPage from './Pages/OccupationPage';
import PtwPage from './Pages/PtwPage';
import Eincidentpage from './Pages/E_incidentpage';
import SupplymanagementPage from './Pages/SupplymanagementPage';
import DigitalesgPage from './Pages/DigitalesgPage';
import CertificatePage from './Pages/CertificatePage';

import { ShareDetailsProvider } from './context/ShareDetailsContext'




function App() {
  const location = useLocation()
  const path = location.pathname;
  const nebosh = "/certificate-&-course/nebosh-courses"
  const iosh = "/certificate-&-course/iosh-courses"
  const firstaid = "/certificate-&-course/first-aid-courses"
  const firefighting = "/certificate-&-course/fire-fighting-courses"

  return (
    <div className="App">
      <ShareDetailsProvider>
        <>
          {path === nebosh || path === iosh || path === firstaid || path === firefighting ? 
           
          <CertificatePage />:<Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />

          {/* ------------------------home menu----------------------------------------------- */}
          <Route path="/esg" element={<ServicePage />} />
          <Route path="/hse" element={<HSEPage />} />
          <Route path="/skill-development" element={<SkillDevelopment />} />
          <Route path="/trainings" element={<TrainingPage />} />
          <Route path="/fire-safety-management" element={<FireSafetyManagement />} />
          <Route path="/occupational-health-management" element={<OccupationalHealthManagement />} />
          <Route path="/Competent-human-management" element={<OccupationalHealthManagement />} />
          <Route path="/management-system-consulting" element={<OccupationalHealthManagement />} />
          <Route path="/food-safety" element={<FoodSafty />} />
          <Route path="/mep-epc-contracts" element={<OccupationalHealthManagement />} />
          <Route path="/material-procurement-and-supply" element={<OccupationalHealthManagement />} />

          {/* ------------------------industries page menu----------------------------------------------- */}
          <Route path='/industries/aerospace-defence' element={<AerospaceDefence />} />
          <Route path='industries/finance-service' element={<FinanceService />} />
          <Route path='/industries/public-sector' element={<PublicSector />} />
          <Route path='industries/agricultre' element={<Agricultre />} />
          <Route path='/industries/electronics' element={<Electronics />} />
          <Route path='industries/real-estate' element={<RealEstate />} />
          <Route path='/industries/automotive-&-assembly' element={<AutomotiveAndAssembly />} />
          <Route path='industries/healthcare' element={<Healthcare />} />
          <Route path='/industries/retail' element={<Retail />} />
          <Route path='industries/chemicals' element={<Chemicals />} />
          <Route path='/industries/life-science' element={<LifeScience />} />
          <Route path='industries/semiconductors' element={<Semiconductors />} />
          <Route path='/industries/consumer-packaged-goods' element={<ConsumerPackagedGoods />} />
          <Route path='industries/metals-&-mining' element={<MetalsAndMining />} />
          <Route path='industries/social-sector' element={<SocialSector />} />
          <Route path='/industries/education' element={<Education />} />
          <Route path='industries/oil-&-gas' element={<OilAndGas />} />
          <Route path='/industries/technology-media-&-telecommunication' element={<TechMediaTelecom />} />
          <Route path='industries/electric-power-&-natural-gas' element={<ElectricPowerNaturalGas />} />
          <Route path='industries/paper-forest-product-&-packaging' element={<PaperForestProductPack />} />
          <Route path='/industries/travel-logistics-&-infracture' element={<TravelLogisticsAndInfracture />} />
          <Route path='industries/oil-&-gas' element={<OilAndGas />} />
          <Route path='/industries/engineering-construction-&-building-materials' element={<EngineeringConstruction />} />
          <Route path='industries/private-equity-&-principal-investor' element={<PrivateEquityAndPrincipalInvestor />} />

          {/* ---------------------------esg -page list------------------------------------ */}
          <Route path="/advisory on framework development" element={<EsgAdvisory />} />
          <Route path="/esg management plan" element={<ESGManagementPlan />} />
          <Route path="/environment aspect and impact study" element={<EnvironmentAspectAndImpactStudy />} />
          <Route path="/esg reporting" element={<ESGReportingPage />} />
          <Route path="/auditing" element={<AuditingPage />} />
          <Route path="/competence development / trainings" element={<CompetenceTraining />} />
          <Route path="/ghg accounting" element={<GHGAccounting />} />
          <Route path="/water and waste foot printing" element={<WaterAndWasteFootPrinting />} />
          <Route path="/green building services," element={<GreenBuilldingService />} />
          <Route path="/greenhouse gas accounting," element={<GreenhouseGasAccounting />} />
          <Route path="/carbon credit advisory" element={<CarbonCreditAdvisory />} />
          <Route path="/social accountability" element={<SocialAccountability />} />
          <Route path="/corporate social responsibility guidance" element={<CorporateSocialResponsibilty />} />
          <Route path="/environment aspect and impact study" element={<EnvironmentAspectAndImpactStudy />} />
          {/* ----------------hse page list-------------------- */}
          <Route path="/advisory on development of hse management systems" element={<AdvisoryHSEManagementPage />} />
          <Route path="/hse management system reporting" element={<HSEManagementReportingPage />} />
          <Route path="/hse Auditing" element={<HSEAuditingPage />} />
          <Route path="/competence development / trainings" element={<HSECompetenceDevelopmentTraining />} />
          <Route path="/hse culture development / behaviour based training" element={<HSECulreBehaviourBasedTraining />} />
          <Route path="/handholding for hse excellence awards like bsc- fsa, nsci awards, csdci awards, golden peacock etc." element={<HandholdingAward />} />
          <Route path="/hse softwares – ptw, incident reporting etc." element={<HSESoftware />} />
          <Route path="/hse videos / animations – training and incident learning" element={<HSEVideoAnimation />} />
          <Route path="/third party inspections by competent persons" element={<ThirdPartyInspection />} />
          <Route path="/environment monitoring" element={<HSEEnvironmentMonitoring />} />
          <Route path="/guidance on legal compliances" element={<HSEGuidance />} />

          {/* ----------------skill development page list-------------------- */}
          <Route path="/construction skill development council" element={<ConstructionSkillCouncilPage />} />
          <Route path="/hydrocarbon skill development council" element={<HydroCarbonSkillCouncilPage />} />
          <Route path="/skill council for green jobs" element={<GreenJobSkillCouncilPage />} />
          <Route path="/skill council for mining sector" element={<MiningSectorSkillCouncilPage />} />
          <Route path="/skill council for telecom sector" element={<TelecomeSectorSkillCouncilPage />} />

          {/* ----------------Training page list-------------------- */}
          <Route path="/gwo" element={<GwoPage />} />
          <Route path="/farm tocli" element={<FarmTocliPage />} />
          <Route path="/fire prevention and protection" element={<FirePreventionProtection />} />

          {/* ----------------fire safety page list-------------------- */}
          <Route path="/engineering design" element={<EngineerDesignPage />} />
          <Route path="/installation and maintenance of firefighting equipment" element={<FireFightingEquipment />} />
          <Route path="/fire noc consultation" element={<FireNOCPage />} />
          <Route path="/fire system auditing" element={<FireSystemAuditingPage />} />
          <Route path="/skill council for telecom sector" element={<TelecomeSectorSkillCouncilPage />} />
          {/* ----------------occupational page list-------------------- */}
          <Route path="/occupational health assessment" element={<OccupationalHealthAssessment />} />
          <Route path="/occupational health centre setup" element={<OccupationalHealthCentre />} />
          <Route path="/health surveillance" element={<HealthSurveillance />} />


          {/* ----------------Food safety page list-------------------- */}
          <Route path="/occupational health assessment" element={<OccupationalHealthAssessment />} />


          {/* ----------------industries page------------------------------ */}
          <Route path='/industries/aerospace defence' element={<IndustriesPage />} />
          <Route path='/industries/finance service' element={<IndustriesPage />} />
          <Route path='/industries/public sector' element={<IndustriesPage />} />
          <Route path='/industries/agricultre' element={<IndustriesPage />} />
          <Route path='/industries/electronics' element={<IndustriesPage />} />
          <Route path='/industries/real-estate' element={<IndustriesPage />} />
          <Route path='/industries/automotive & assembly' element={<IndustriesPage />} />
          <Route path='/industries/retail' element={<IndustriesPage />} />
          <Route path='/industries/healthcare' element={<IndustriesPage />} />
          <Route path='/industries/chemicals' element={<IndustriesPage />} />
          <Route path='/industries/life science' element={<IndustriesPage />} />
          <Route path='/industries/semiconductors' element={<IndustriesPage />} />
          <Route path='/industries/consumer packaged goods' element={<IndustriesPage />} />
          <Route path='/industries/metals & mining' element={<IndustriesPage />} />
          <Route path='/industries/social sector' element={<IndustriesPage />} />
          <Route path='/industries/education' element={<IndustriesPage />} />
          <Route path='/industries/oil & gas' element={<IndustriesPage />} />
          <Route path='/industries/technology , media& telecommunication' element={<IndustriesPage />} />
          <Route path='/industries/electric power & natural gas' element={<IndustriesPage />} />
          <Route path='/industries/paper, forest product & packaging' element={<IndustriesPage />} />
          <Route path='/industries/travel, logistics & infracture' element={<IndustriesPage />} />
          <Route path='/industries/engineering, construction & building materials' element={<IndustriesPage />} />
          <Route path='/industries/private equity & principal investor' element={<IndustriesPage />} />

          {/* ----------------------------------------digital services------------------------------ */}
          <Route path='/digital/service/real time monitoring of site with drone and camera' element={<Droneandcamera />} />
          <Route path='/digital/service/virtual reality training setup' element={<Virtualdigital />} />
          <Route path='/digital/service/energy management' element={<EnergyManagement />} />
          <Route path='/digital/service/waste management' element={<Wastemanagement />} />
          <Route path='/digital/service/ai chat boat for safety and others' element={<Aichat />} />
          <Route path='/digital/service/animation and others video creation' element={<AnimationsPage />} />
          <Route path='/digital/service/digital app service' element={<DigitalAppPage />} />

          {/* ////////////////////////////////////// lws /////////////////////////////////////// */}
          <Route path='esg/software/learning management' element={<LearningsystemPage />} />
          <Route path='esg/software/occupationa health management' element={<OccupationPage />} />
          <Route path='esg/software/e-ptw' element={<PtwPage />} />
          <Route path='esg/software/e-incident management' element={<Eincidentpage />} />
          <Route path='esg/software/supply chain management' element={<SupplymanagementPage />} />
          <Route path='esg/software/digital esg/hse platforms' element={<DigitalesgPage />} />



          {/* -------------------------Auditing and consulting-------------- */}
          <Route path='/auditing/consulting/hse-audit' element={<HSEAuditingAndConsulting />} />
          <Route path='/auditing/consulting/esg-audit' element={<ESGAuditingAndConsulting />} />
          <Route path='/auditing/consulting/carbon-audit' element={<CarbonAuditingAndConsulting />} />
          <Route path='/auditing/consulting/energy-audit' element={<EnergyAuditingAndConsulting />} />
          <Route path='/auditing/consulting/ims-audit' element={<IMSAuditingAndConsulting />} />
          <Route path='/auditing/consulting/process-safety-audit' element={<ProcessSafetyAuditing />} />
          <Route path='/auditing/consulting/electrical-safety-audit' element={<ElectricalSafetyAuditingAndConsulting />} />
          <Route path='/auditing/consulting/environmental-audit' element={<EnvironmentalAuditingAndConsulting />} />
          <Route path='/auditing/consulting/standard-specific-audit' element={<StandarSafetyAuditing />} />

          {/* -------------------------Certificate & Cources-------------- */}
          <Route path='/certificate-&-course/nebosh-courses' element={<CertificatePage />} />

          <Route path="/development advisory" element={<DevelopmentAdvisory />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/leader" element={<Leadership />} />
          <Route path="/enquiry/about-training" element={<EnquiryPage />} />
          <Route path="/user/enrollment" element={<UserEnrollmentPage />} />
          <Route path="/courses" element={<CoursesPage />} />
          <Route path="/gallery" element={<GalleryPage />} />
          <Route path="/articles" element={<ArticlesPage />} />
          <Route path="/magazines" element={<MagazinesPage />} />
          <Route path="/news" element={<NewsPage />} />


          <Route path='/demo' element={<IndustriesPage />} />
          <Route path='/career' element={<CarrerPage />} />
          <Route path='/news-event' element={<Newsandevent />} />

          <Route path='/training-learning' element={<Training />} />
          <Route path='/training on different safety modules' element={<Training />} />
          <Route path='/legal training' element={<Training />} />
          <Route path='/first aid training' element={<Training />} />
          <Route path='/fire fighting training' element={<Training />} />
          <Route path='/hse induction training' element={<Training />} />
          <Route path='/esg trainings' element={<Training />} />
          <Route path='/gwo and farm tikli training' element={<Training />} />
          <Route path='/behaviour based safety trainings' element={<Training />} />
          <Route path='/posh training' element={<Training />} />
          <Route path='/Professional-resourcing-suppy' element={<Supply />} />
          <Route path='/Manpower-management' element={<Manpowermangement />} />
          <Route path='/Medical-nurse-staff' element={<Medicalnursing />} />
          <Route path='/show/image' element={<ShowImage />} />
        </Routes>
          }

        </>
      </ShareDetailsProvider>
    </div>
  );
}

export default App;
