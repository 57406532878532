import React from 'react'
import MainLayout from '../component/Layout/MainLayout'
import Contact from '../component/Contact/Contact';
import Footer from '../component/Footer/Footer';

const ContactPage = () => {
  return (
    <MainLayout>
      <Contact />
      <Footer />
    </MainLayout>
  )
}

export default ContactPage
