import React from 'react';
import foodSafetyImg from '../../constrain/Images/food-safety.jpg'
import './FoodSafetyStyle.css'





const FoodSafetyHeader: React.FC = () => {
    return (
        <>
            <section className='foodSafety-header-section'>

                <div className='right-food-safety-section'>
                    <div className='right-image-section'>
                        {/* <img src={foodSafetyImg} alt='' /> */}
                    </div>
                </div>
            </section>
            <div className='bottom-strip'></div>
            <div className='main-food-safety-section'>
                <pre>
                    <h3>
                        EAT HEALTY
                        BE HAPPY
                    </h3>
                </pre>
            </div>
        </>
    );
};

export default FoodSafetyHeader;
