import { Button, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import CertificateRoutes from './CertificateRoutes'
import { useLocation, useNavigate } from 'react-router-dom'
import Footer from '../Footer/Footer'
import './CertificateAndCourse.css'
import CourseModal from './CourseModal/CourseModal'

const CertificateAndCourse = () => {
    const navigation = useNavigate()
    const [open, setOpen] = useState(false);
    const [stateValue, setStateValue] = useState()
    const [test, setTest] = useState()

    const handleClose = () => setOpen(false);

    const handleModal = (item: any) => {
        if (item === "/certificate-&-course/iosh-courses") {
            setOpen(!open)
            setStateValue(item)
        } else {
            console.log("not found")
        }
    };
    const data = [
        {
            "id": 1,
            "label": "NEBOSH",
            "path": "/certificate-&-course/nebosh-courses"
        },
        {
            "id": 2,
            "label": "IOSH",
            "path": "/certificate-&-course/iosh-courses"
        },
        {
            "id": 3,
            "label": "First Aid",
            "path": "/certificate-&-course/first-aid-courses"
        },
        {
            "id": 4,
            "label": "Fire and Safety Courses",
            "path": "/certificate-&-course/fire-fighting-courses"
        }

    ]

    console.log("test")

    return (
        <>
            <Grid container>
                <Grid item sm={2} className='course-menu'>
                    {data.map((item) => {
                        return (
                            <p key={item.id} onClick={() => {
                                navigation(item.path);
                                handleModal(item.path);
                            }}>{item.label} Course</p>
                        )
                    })}
                </Grid>
                <Grid item sm={10}>
                    <CertificateRoutes />
                </Grid>
            </Grid>
            <Footer />
            <CourseModal
                open={open}
                handleClose={handleClose}
            />
        </>
    )
}

export default CertificateAndCourse