import React, { useState } from 'react';
import './tagLineStyle.css'



interface TagLineVideoProps {
  data: any;
  tagline?: string;
} 

const TagLineVideo: React.FC<TagLineVideoProps> = ({ data }) => {
  const [slide, setSlide] = useState(0)
  // const [isShareDetailsOpen, setShareDetailsOpen] = useState(false)


  // const nextSlide = () => {
  //   setSlide(slide === data.length - 1 ? 0 : slide + 1)
  //   console.log(slide)
  // }
  // const preSlide = () => {
  //   setSlide(slide === 0 ? data.length - 1 : slide - 1)
  //   console.log(slide)
  // }

  // const openShareDetails = () => {
  //   console.log(isShareDetailsOpen)
  //   setShareDetailsOpen(true)
  // }
  return (
    <div className='homeTagLine'>
      {/* <p className='tagline'>{tagline}</p> */}
      <div className='vidoe-banner' >
        {data.map((item: any, idx: number) => {
          return (
            <div className={slide === idx ? "slide" : "slide-hidden"}>
              <video src={item} autoPlay={true} loop muted={true} ></video>
            </div>
          )
        })}
      </div>
      {/* <div className='hometag-arrow'>
        <MdArrowBackIosNew onClick={preSlide} fontSize={30} fontWeight={700} />
        <MdArrowForwardIos onClick={nextSlide} fontSize={30} fontWeight={700} />
      </div> */}
     
{/* 
      <button className='share-detail' onClick={openShareDetails}>
        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/be/Facebook_Messenger_logo_2020.svg/2048px-Facebook_Messenger_logo_2020.svg.png" alt="share details" />
      </button> */}

    </div>
  );
};

export default TagLineVideo;
