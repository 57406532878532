import React, { FunctionComponent, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./auditingAndConsulting.css";
import AuditCard from "./AuditCard";
import esgAudit1 from "../../constrain/Images/VIDURA_images/VIDURA_images/audit-3229739_1280.jpg";

import AuditRightSectionCard from "./AuditRightSectionCard";
import Esgcard1 from "./Esgcard1";

interface AuditingAndConsultingProps {}

const ESGAuditing: FunctionComponent<AuditingAndConsultingProps> = () => {
  const [isVisible, setIsVisible] = useState(false);
  const handleVisibilityChange = () => {
    const element = document.querySelector(".audit-show-when-visible");
    if (element) {
      const rect = element.getBoundingClientRect();
      const elementIsVisible =
        rect.top < window.innerHeight && rect.bottom >= 0;
      setIsVisible(elementIsVisible);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleVisibilityChange);
    return () => {
      window.removeEventListener("scroll", handleVisibilityChange);
    };
  }, []);

  return (
    <section className="audit-page">
      <div className="auditing-and-consulting-main">
        <div className="auditing-main-left-section">
          <AuditCard
            img={esgAudit1}
            title="Audit Innovation"
            auditName="Navigating Sustainability Excellence with Vidura International"
            para={[
                { text: "At Vidura International, we recognize the pivotal role Environmental, Social, and Governance (ESG) factors play in modern business. Our ESG Audit Services are meticulously crafted to assess and enhance your organization's sustainability practices." },
                
              ]}
          />
       
          <Esgcard1/>
         
        </div>
        <div className="auditing-main-right-section">
          <AuditRightSectionCard 
          mainheading="Why Vidura International for ESG Audits?"
          content ={[
            {text: "Our experienced energy auditors conduct thorough assessments, examining every aspect of your organization's energy usage, from equipment efficiency to operational processes." , heading: "Holistic Evaluation"},
            {
                text:" Recognizing the uniqueness of each organization, our ESG audits are customized to align with your specific industry, regulatory requirements, and sustainability objectives.", heading:"Tailored to Your Goals: "
            },
            {text:"Gain insights into compliance levels and receive strategic recommendations for continuous improvement in ESG performance.", heading:"Strategic Guidance"},
            {text:"Anticipate and address potential environmental and social risks, demonstrating a commitment to sustainable business practices.", heading:"Proactive Sustainability"}
          ]}
          />
        </div>
      </div>
      {/* <div className='audit-show-when-visible'>
                <div className={`dynamic-audit-content ${isVisible ? 'visible' : ''}`}>
                    <h4>Reduce Audit Burden</h4>
                    <p>Achieving a higher quality, more insight audit shouldn't take more effort from you. Through focus, flexibility, and and advanced audit technology, Vidura delivers more relevant insights , all while reducing the burden on your team</p>
                    <p className='discover-more'>
                        <div className='discover-more-about-audit-btn'>+</div>
                        Discover the benifit of a more focused audit
                    </p>
                </div>
                <div className={`dynamic-audit-content ${isVisible ? 'visible' : ''}`}>
                    <h4>Deeper Insight</h4>
                    <p>Insight come in many shapes and sizes. Vidura produces insights that are tailored for your specific business and the industry it operates in. This focused approach enhances audit quality and improves your overall experience</p>
                    <p className='discover-more'>
                        <div className='discover-more-about-audit-btn'>+</div>
                        Discover the benifit of a more focused audit
                    </p>
                </div>
                <div className={`dynamic-audit-content ${isVisible ? 'visible' : ''}`}>
                    <h4>A Smarter Audit</h4>
                    <p>Driving value through a high-quality audit is not only what we do, it's in our DNA. Our diverse and highly skilled teams are constantly analyzing changes in your risk profile to focus on the risks facing your business</p>
                    
                    <p className='discover-more'>
                        <div className='discover-more-about-audit-btn'>+</div>
                        Discover the benifit of a more focused audit
                    </p>

                </div>

            </div> */}
    </section>
  );
};

export default ESGAuditing;
