import React from "react";
import MainLayout from "../component/Layout/MainLayout";
import Digital from "../component/Digitalapp/Digital";
import Footer from "../component/Footer/Footer";
import Commondigital from "../component/Drone/Commondigital";
import MainDigital from "../component/Drone/MainDigital";
import imgg from ".././constrain/Images/pngtree-d-rendering-of-a-digital-service-or-app-for-cloud-storage-image_3853633.jpg"

function DigitalAppPage() {
  return (
    <MainLayout>
      <Commondigital heading={""} />
      <MainDigital
        heading={"Digital app services"}
        content={[
          {
            lione: "Vidura International introduces cutting-edge Digital App Services designed to revolutionize safety, environmental, and operational efficiency. Our suite of digital applications is crafted to streamline processes, foster real-time communication, and enhance overall safety management.",
            litwo: "By harnessing the power of technology, our digital apps empower your workforce to report incidents promptly, access critical safety information on the go, and participate in a collaborative approach towards a safer and more sustainable workplace. Vidura International is committed to bringing you state-of-the-art digital solutions that drive efficiency, compliance, and a culture of safety.",
            
            li3: "Embark on a digital transformation journey with Vidura International's Digital App Services. ",
            li4: "",
            li5: "",
            li6: "",
          },
        ]}
       
        image={imgg}
        text={"Explore the possibilities of a tech-driven approach to safety and environmental management by initiating a discussion with us."}
      />
      <p style={{display:"-ms-grid" ,paddingLeft:"2rem", marginLeft:"4rem", width:"50%"}}>Whether it's a safety reporting app, an environmental compliance tool, or a comprehensive HSE platform, our digital apps are tailored to meet the unique requirements of your industry. We prioritize user-friendly interfaces, ensuring seamless adoption and integration into your existing workflows. With our digital app services you would be able to have-</p>
        <ul style={{marginLeft:"4rem",paddingLeft:"2rem", display:"flex", justifyContent:"center", flexDirection:"column"}}>
          <li>Data Collection</li>
          <li>Data Visualization </li>
          <li>Real Time Monitoring</li>
          <li>QHSE and ESG System Implementation </li>
          <li>Legal Compliance Monitoring</li>
          <li>Report Generation and smart decision making</li>
        </ul>
      <Footer />
    </MainLayout>
    
  );
}

export default DigitalAppPage;
