

import { Box, Grid } from "@mui/material";
import React, { FunctionComponent } from "react";
import "./Mainpage.css";

interface MainpageContent {
  heading: string;
  content: {
    lione: string;
    litwo: string;
    li3: string;
    li4: string;
    li5: string;
    li6: string;
  }[];
  image: string;
}

const MainPage: FunctionComponent<MainpageContent> = ({ content, heading, image }) => {
  return (
    <section className="mainpagesection">
      <h5 className="mainpageheading">{heading}</h5>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8} md={8} lg={8} xl={8} className="mainpageul">
          <ul style={{ margin: "2rem" }}>
            {content.map((item, index) => (
              <div key={index}>
                <p>{item.lione}</p>
                <p>{item.litwo}</p>
                <p>{item.li3}</p>
                <p>{item.li4}</p>
                <p>{item.li5}</p>
                <p>{item.li6}</p>
              </div>
            ))}
          </ul>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} className="mainpageimg">
          <img src={image} alt="" style={{ width: "100%", height: "auto" }} />
        </Grid>
      </Grid>
    </section>
  );
};

export default MainPage;

