import React, { FunctionComponent, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./auditingAndConsulting.css";
import AuditCard from "./AuditCard";
import hseAudit1 from "../../constrain/Images/What-is-HSE-Audit.jpg";
import card2img from "../../constrain/Images/Screenshot (225).png"
import AuditRightSectionCard from "./AuditRightSectionCard";
import Auditcard1 from "./Auditcard1";
import card2 from "../../constrain/Images/VIDURA_images/VIDURA_images/pexels-kindel-media-9800092.jpg"
import cardimg from "../../constrain/Images/Screenshot (225).png"

interface EnergyAuditProps {}

const EnergyAudit: FunctionComponent<EnergyAuditProps> = () => {
  const [isVisible, setIsVisible] = useState(false);
  const handleVisibilityChange = () => {
    const element = document.querySelector(".audit-show-when-visible");
    if (element) {
      const rect = element.getBoundingClientRect();
      const elementIsVisible =
        rect.top < window.innerHeight && rect.bottom >= 0;
      setIsVisible(elementIsVisible);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleVisibilityChange);
    return () => {
      window.removeEventListener("scroll", handleVisibilityChange);
    };
  }, []);

  return (
    <section className="audit-page">
      <div className="auditing-and-consulting-main">
        <div className="auditing-main-left-section">
          <AuditCard
            img={cardimg}
            title="Audit Innovation"
            auditName="Enhancing Efficiency, Reducing Costs with Vidura International"
            para={[
              {
                text: "At Vidura International, we recognize the critical role energy efficiency plays in both environmental sustainability and operational cost-effectiveness. Our Energy Audit Services are meticulously designed to assess, analyze, and optimize your organization's energy consumption.",
              },
            ]}
          />
          <Auditcard1
           mainheading= "Our Energy Audit Process:"
           img ={card2}
           para={[{
            text:": Understanding your organization's energy goals, operational processes, and cost-saving objectives.",heading:"Needs Assessment"
           },
           {text:"Thorough examination of energy-consuming systems, equipment, and operational processes.",heading:"On-Site Evaluation"},
           {text:"In-depth analysis of energy consumption patterns and identification of areas for improvement.",heading:"Data Analysis "},{
            text:"Receive a detailed report with actionable recommendations for energy efficiency and cost reduction.",heading:"Comprehensive Reporting"
           }
        ]}
          />
        </div>

        

        <div className='auditing-main-right-section'>
                <AuditRightSectionCard 
                mainheading={"Why Vidura International for Energy Audits?"} 
                content={[
                    {text:"Our experienced energy auditors conduct thorough assessments, examining every aspect of your organization's energy usage, from equipment efficiency to operational processes.", heading:"Holistic Evaluation"},
                    {text:"Identify opportunities for cost savings through energy-efficient practices, equipment upgrades, and process optimization.",heading:"Cost Savings"},
                    {text:"Our energy audits are tailored to your industry and operational needs, ensuring recommendations align with your specific goals and regulatory requirements.", heading:"Customized Solutions"},
                    {text:"Gain insights into the environmental impact of your energy consumption and receive recommendations for reducing your carbon footprint.",heading:"Environmental Impact"}
                ]} />
            </div>
      </div>
      {/* <div className="audit-show-when-visible">
        <div className={`dynamic-audit-content ${isVisible ? "visible" : ""}`}>
          <h4>Reduce Audit Burden</h4>
          <p>
            Achieving a higher quality, more insight audit shouldn't take more
            effort from you. Through focus, flexibility, and and advanced audit
            technology, Vidura delivers more relevant insights , all while
            reducing the burden on your team
          </p>
          <p className="discover-more">
            <div className="discover-more-about-audit-btn">+</div>
            Discover the benifit of a more focused audit
          </p>
        </div>
        <div className={`dynamic-audit-content ${isVisible ? "visible" : ""}`}>
          <h4>Deeper Insight</h4>
          <p>
            Insight come in many shapes and sizes. Vidura produces insights that
            are tailored for your specific business and the industry it operates
            in. This focused approach enhances audit quality and improves your
            overall experience
          </p>
          <p className="discover-more">
            <div className="discover-more-about-audit-btn">+</div>
            Discover the benifit of a more focused audit
          </p>
        </div>
        <div className={`dynamic-audit-content ${isVisible ? "visible" : ""}`}>
          <h4>A Smarter Audit</h4>
          <p>
            Driving value through a high-quality audit is not only what we do,
            it's in our DNA. Our diverse and highly skilled teams are constantly
            analyzing changes in your risk profile to focus on the risks facing
            your business
          </p>

          <p className="discover-more">
            <div className="discover-more-about-audit-btn">+</div>
            Discover the benifit of a more focused audit
          </p>
        </div>
      </div> */}
    </section>
  );
};

export default EnergyAudit;
