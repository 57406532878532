import React from "react";
import carrerimage from "../../constrain/Images/pexels-photo-106400.webp";
import "./Carrer.css";
import Form from "./Form";
import Information from "./Information";

function Carrer() {
  return (
    <>
      <div className="jobimage">
        <div className="carrerimage">
          <img src={carrerimage} />
        </div>

        <div className="jobsearch">
          <h1>Job Search</h1>
          <p>
            Join Vidura and discover a career that will help you <br /> thrive,
            learn, and do work that matters.
          </p>
        </div>
      </div>
      <Form />
      <Information />
    </>
  );
}

export default Carrer;
