import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import menuImg from '../../constrain/Images/image1.svg'
import { Link } from 'react-router-dom';

import './menuCardStyle.css'
interface MenuCardProps {
  homeMenu: {
    id: number;
    navMenu: string;
    path: string;
  }[];
  title:string;
}
const MenuCard: React.FC<MenuCardProps> = ({ homeMenu,title }) => {

  return (
    <Card>
      <CardContent >
        <Typography gutterBottom variant="h5" component="div">
          {title}
          {homeMenu.map((item) => (
            <li className='all-menu-list' key={item.id}><Link to={item.path}> {item.navMenu}</Link></li>
          ))}
        </Typography>
        <Typography variant="body2" color="black">
        The goal of <span className='goal-of-vidura'>VIDURA </span> <span className='vidura-consultancy'>International</span> services is to help clients achieve their objectives more effectively and efficiently by leveraging the expertise of external specialists.
        </Typography>
      </CardContent>
    </Card>
  );
}


export default MenuCard