import React from "react";
import MainLayout from "../component/Layout/MainLayout";
import Eincident from "../component/E-incident_Mangement/Eincident";
import Footer from "../component/Footer/Footer";
import Commondigital from "../component/Drone/Commondigital";
import MainDigital from "../component/Drone/MainDigital";
import imgg from "../constrain/Images/bg_image.jpg"

function E_incidentpage() {
  return (
    <MainLayout>
      {/* <Eincident /> */}
      <Commondigital heading={""} />
      <MainDigital
        heading={"E-Incident management"}
        content={[
          {
            lione: "Vidura International introduces a cutting-edge Electronic Incident Management system designed to streamline and enhance the reporting and tracking of workplace incidents. Our digital solution replaces traditional manual processes, offering a more efficient and accurate approach to incident management.",
            litwo: "With Vidura's E-Incident Management system, organizations can report incidents in real-time, ensuring swift notification and response to potential risks. The system provides a user-friendly interface for incident reporting, capturing crucial details and facilitating a standardized process for incident classification and investigation.",
            li3: "Key features include automated workflows for incident escalation, comprehensive documentation of investigations, and trend analysis tools for proactive risk mitigation. The digital platform also enables organizations to maintain a centralized database of incident records, promoting data-driven decision-making and continuous improvement in safety practices.",
            li4: "Transition to Vidura International's Electronic Incident Management system to elevate your incident reporting and management processes, contributing to a safer and more secure work environment.",
            li5: "",
            li6: "",
          },
        ]}
        image={imgg}
        text={"Explore the transformative capabilities of E-Incident Management by initiating a conversation with Vidura International."}
      />
      <Footer />
    </MainLayout>
  );
}

export default E_incidentpage;
