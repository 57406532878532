import React from 'react'
import styles from './AboutBanner.module.scss'
import { Grid, Typography } from '@mui/material'
import AboutCard from './AboutCard/AboutCard'
import icon_1 from '../../constrain/icons/2.svg'
import icon_2 from '../../constrain/icons/3.svg'
import icon_3 from '../../constrain/icons/4.svg'
import image_1 from '../../constrain/Images/aurora-1197753_1280.jpg'
import image_2 from '../../constrain/Images/mountain-landscape-2031539_1280.jpg'
import image_3 from '../../constrain/Images/hills-615429_1280.jpg'


const AboutBanner = () => {
    const bgOpecity_1 = "#0cc0df";
    const bgOpecity_2 = "#ff3131";
    const bgOpecity_3 = "#f17221";

    return (
        <>
            <Grid className={styles.aboutBannerContainer}>
                <Typography textAlign={"center"} variant='h2' fontWeight={600} fontSize={35} sx={{ paddingBlockStart: 10 }}>Mission, Vision, and Values</Typography>
                <Typography textAlign={"center"} sx={{ paddingBlockEnd: 5, lineHeight: 3 }}>We are driven by our Mission and Values</Typography>
                <Grid container>
                    <Grid item md={4} className={styles.contentContainer}>
                        <AboutCard
                            bgImage={image_1}
                            bgOpecity={bgOpecity_1}
                            label={'Mission'}
                            icon={icon_1}
                        />
                        <ul>
                            <li>We will provide strategic guidance and innovative solutions to empower organizations to reach their highest potential.
                            </li>
                            <li>We will be using cutting age technology to meet clients requirement and to provide comprehensive solutions
                            </li>
                            <li>We aim to be the trusted partner that catalyzes growth, inspires innovation, and drives transformative change. Through our unwavering commitment to excellence, we seek to make a meaningful impact on our clients, our industry, and the world at large.
                            </li>

                        </ul>
                    </Grid>
                    <Grid item md={4} className={styles.contentContainer1}>
                        <AboutCard
                            bgImage={image_2}
                            bgOpecity={bgOpecity_2}
                            label={'Vision'}
                            icon={icon_2}
                        />
                        <Typography>"Fostering Excellence in Every Endeavor."</Typography>
                    </Grid>
                    <Grid item md={4} className={styles.contentContainer2}>
                        <AboutCard
                            bgImage={image_3}
                            bgOpecity={bgOpecity_3}
                            label={'Values'}
                            icon={icon_3}
                        />
                        <Grid className={styles.content} >
                            <Typography><span style={{ fontWeight: 600 }}>Excellence:</span> We consistently deliver the highest quality, setting and maintaining rigorous standards in all our endeavors.</Typography>
                            <Typography><span style={{ fontWeight: 600 }}>Interigirity:</span> We uphold unwavering honesty, transparency, and ethical conduct, building trust and credibility.</Typography>
                            <Typography><span style={{ fontWeight: 600 }}>Client-Centric:</span> Our clients are our priority. We tailor our services to meet their unique needs, ensuring their success.</Typography>
                            <Typography><span style={{ fontWeight: 600 }}>Innovation:</span> We embrace creativity and cutting-edge solutions to tackle complex challenges and drive positive change.</Typography>
                            <Typography><span style={{ fontWeight: 600 }}>Collaboration:</span> We foster a culture of teamwork, recognizing that together, we achieve more, both within our team and with clients and partners.</Typography>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default AboutBanner;