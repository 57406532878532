import React from 'react'
import MainLayout from '../component/Layout/MainLayout'
import Footer from '../component/Footer/Footer'
import Cameradrone from '../component/Drone/Cameradrone'
import Commondigital from '../component/Drone/Commondigital'
import MainDigital from '../component/Drone/MainDigital'
import imga from ".././constrain/Images/commercial-drones-uses.jpg"

function Droneandcamera() {
  return (
    <MainLayout>
        <Commondigital heading={''} />
        <MainDigital
        heading={'Real time monitoring of site with drone and camera'}
        content={[
          {
            lione: "Vidura International introduces state-of-the-art real-time site monitoring, leveraging the power of drones and cutting-edge camera systems. Our advanced technology solution provides unparalleled surveillance, allowing for comprehensive oversight of your worksite.",
            litwo: "Equipped with high-resolution cameras and drone capabilities, our real-time monitoring system ensures continuous visibility into critical areas. This enables prompt identification of potential safety hazards, adherence to protocols, and swift response to emerging situations. The integration of drones enhances accessibility to remote or challenging locations, contributing to a more thorough monitoring process.",
            li3: "Vidura International is committed to fostering a safer work environment through innovative solutions. Our real-time monitoring system empowers organizations to proactively manage safety and security, promoting a culture of vigilance and responsiveness.",
            li4: "",
            li5: "",
            li6: "",
          },
        ]}
        image={imga} 
        text={'Discover the benefits of real-time monitoring with Vidura International. Connect with us to explore how this advanced technology can elevate safety and surveillance at your worksite.'}/>
        <Footer/>
    </MainLayout>
  )
}

export default Droneandcamera



// <Commondigital heading={''} />
//         <MainDigital
//          heading={''} 
//          content={[
//           {
//             lione: "",
//             litwo: "",
//             li3: "",
//             li4: "",
//             li5: "",
//             li6: "",
//           },
//          ]}
//           image={''}/>
