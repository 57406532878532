import React from "react";
import img1 from "../../constrain/Images/download.jpeg";
import img2 from "../../constrain/Images/save-earth-day-poster-environment-day-nature-green-ai-generative-glossy-background-images-tree-and-water-free-photo.jpg";
import img3 from "../../constrain/Images/flowers-276014_1280.jpg";
import "./Newscard.css";
import { Divider } from "@mui/material";
import CommonButtton from "../CommonButtton/CommonButtton";
import Media from "./Media";
import Nihnews from "./Nihnews";

interface NewsItem {
  id: number;
  img: string;
  label: string;
}

function NewsCard() {
  const data: NewsItem[] = [
    {
      id: 1,
      img: img1,
      label:
        "A clinical trial has launched to test whether early intensive immune modulation for hospitalized COVID-19 patients with relatively mild illness is beneficial. The placebo-controlled study, part of the ",
    },
    {
      id: 2,
      img: img2,
      label:
        "Immune modulators—treatments for modifying the immune system to better respond to disease or illness—are lifesaving for certain hospitalized COVID-19 patients. However, the optimal timing for administerin",
    },
    {
      id: 3,
      img: img3,
      label:
        "he drug being used to  is manufactured by Bristol Myers Squibb, Princeton, New Jersey. It was first approved by the U.S. Food and Drug Administration in 2005 as a treatment for rheumatoid arthritis in adults",
    },
  ];

  return (
    <>
      <div className="cardmedia">
        <div className="searchcard"> 
        <h4>Recent New Release</h4>
        <Divider sx={{marginInline:2}} />
        <form className="form-inline form-input">
          <input
            className="form-control mr-sm-2 style-input"
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
          <button
            className="btn btn-outline-success my-2 my-sm-0"
            type="submit"
          >
            Search
          </button>
        </form>

        <div className="newscard">
          {data.map((item) => (
            <div key={item.id} className="card differentcard">
              <img
                className="card-img-top"
                src={item.img}
                alt="Card  cap"
              />
              <div className="card-body">
                <p className="card-text">{item.label}</p>
              </div>
            </div>
          ))}
      
        </div>
        <Divider sx={{marginInline:2}}/>
        <CommonButtton name={"All new release"}  />
        </div>
        <Media/>
        <Nihnews/>
      </div>
      
    </>
  );
}

export default NewsCard;
