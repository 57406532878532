import React from 'react'
import image from "../../constrain/Images/VIDURA_images/VIDURA_images/pexels-arthur-ogleznev-1296265.jpg"

function Esgcard1() {
  return (
    <section className='auditing-card'>
    <h3 className='audit-card-title'>Our ESG Audit Process:</h3>
    <div className='audit-card-content'>
     <div className=''>
     <h5>Needs Assessment  </h5>
         <p>Understanding your sustainability goals and objectives.</p>
         <h5>Comprehensive Evaluation </h5>
         <p>Thorough examination of your environmental impact, social responsibility, and governance practices.</p>
         <h5>Detailed Reporting </h5>
         <p>Receive a comprehensive report highlighting strengths, areas for improvement, and strategic recommendations.</p>
         <h5>Post-Audit Support  </h5>
         <p>We stand by you after the audit, offering support in implementing recommended changes and ensuring ongoing sustainability compliance.</p>
        
     </div>
        <div className='audit-card-img'>
         <img src={image} style={{height:'32rem'}} alt=''/>
        </div>
    </div>
 </section>
  )
}

export default Esgcard1
