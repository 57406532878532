import React, { FunctionComponent } from 'react';
import './serviceSectorHeader.css'
interface ServiceSectorHeaderProps {
  //   id: number;
  //   img: string;
  serviceName:string;
}
 
const ServiceSectorHeader: FunctionComponent<ServiceSectorHeaderProps> = ({serviceName}) => {
  return (
    <div className='sector-service-header'>
    <div className='sector-service-details'>
      <div className='service-sector-intro-text'>
        <h6 className='service-sector-line'>
          Sector and Services  ------<span> {serviceName}</span>
        </h6>
        <h5 className='sector-vidura-big'>{serviceName} </h5>
        <br />
        <span className='sector-consultance'>Consultancy</span>
      </div>
    </div>
    <div className='welcome-banner'>
      <img src="" alt="" />
    </div>
  </div>
  );
};

export default ServiceSectorHeader;
