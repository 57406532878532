import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

interface AdvisoryCardProps {
  id: number;
  img: string;
  title: string;
  desc: string;
  link: string;
  btn: string;
}

const AdvisoryCard: FunctionComponent<AdvisoryCardProps> = ({ id, img, title, desc, link, }) => {

  const handleDownload = () => {
    console.log("download");
  };

  return (
    <div className="brochure-card" key={id}>
      <img src={img} loading="lazy" alt="" />
      <h6 className='brochure-card-title'>{title}</h6>
      {/* <p>{desc}</p> */}
      <Link to={title} className="btn-a-solid">
        {link}
      </Link>
    </div>
  );
};

export default AdvisoryCard;
