import React, { useState ,useEffect} from 'react';
import './galleryCardStyle.css';


interface GalleryCardProps {
  imageSrc: string;
  title: string;
}

const GalleryCard: React.FC<GalleryCardProps> = ({ imageSrc, title }) => {
  const imageUrls = [imageSrc]; 

  useEffect(() => {
    const preloadImages = async () => {
      const imagePromises = imageUrls.map((imageUrl) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = imageUrl;
          img.onload = resolve;
        });
      });
  
      await Promise.all(imagePromises);
    };
  
    preloadImages();
  }, []);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className={`gallery-card ${isHovered ? 'hovered' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img src={imageSrc} alt={title} />
      {isHovered && (
        <div className="transparent-cover">
          <p>Vidura Consultancy</p>
        </div>
      )}
    </div>
  );
};

export default GalleryCard;
