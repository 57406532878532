import React from 'react'
import MainLayout from '../component/Layout/MainLayout'
import './pageStyle.css'
import AdvisoryCard from '../component/Services/AdvisoryCard'
import ESGAdvisory from '../constrain/Images/esg-advisory.jpg'
import DevelopmentAdvisory from '../constrain/Images/real-Estate-development-advisory.jpg'
import HSEAdvisory from '../constrain/Images/hse-advisory.jpg'
import DevelopProcedure from '../constrain/Images/as-is-process.png'
import ServiceSectorHeader from '../component/Services/SectorAndService'
import Footer from '../component/Footer/Footer'


const advisoryData = [
  {
    id: 1, img: ESGAdvisory, title: "Advisory on framework development ", desc: "Joint our most awaited and self paced course for NDA and prepare yourself for the academy",
    link: "More Details", btn: "Download Brochure", download: "ndabrochure.pdf"
  },
  {
    id: 2, img: DevelopmentAdvisory, title: "ESG Management Plan ", desc: "Join our most awaited and self paced course for CDS and prepare yourself for the academy",
    link: "More Details", btn: "Download Brochure", download: "cds.pdf"
  },
  {
    id: 3, img: HSEAdvisory, title: "Environment aspect and Impact study", desc: "Join our most awaited and self paced course for AFCAT and prepare yourself for the academy",
    link: "More Details", btn: "Download Brochure", download: "afcat.pdf"
  },
  {
    id: 4, img: DevelopProcedure, title: "ESG reporting", desc: "Join our most awaited and self paced course for AFCAT and prepare yourself for the academy",
    link: "More Details", btn: "Download Brochure", download: "ota.pdf"
  },
  {
    id: 5, img: DevelopmentAdvisory, title: "Auditing", desc: "Join our most awaited and self paced course for CDS and prepare yourself for the academy",
    link: "More Details", btn: "Download Brochure", download: "cds.pdf"
  },
  {
    id: 6, img: HSEAdvisory, title: "Competence development / trainings", desc: "Join our most awaited and self paced course for AFCAT and prepare yourself for the academy",
    link: "More Details", btn: "Download Brochure", download: "afcat.pdf"
  },
  {
    id: 7, img: DevelopProcedure, title: "GHG accounting", desc: "Join our most awaited and self paced course for AFCAT and prepare yourself for the academy",
    link: "More Details", btn: "Download Brochure", download: "ota.pdf"
  },
  {
    id: 8, img: DevelopmentAdvisory, title: "Water and waste foot printing", desc: "Join our most awaited and self paced course for CDS and prepare yourself for the academy",
    link: "More Details", btn: "Download Brochure", download: "cds.pdf"
  },
  {
    id: 9, img: HSEAdvisory, title: "Green Building services,", desc: "Join our most awaited and self paced course for AFCAT and prepare yourself for the academy",
    link: "More Details", btn: "Download Brochure", download: "afcat.pdf"
  },
  {
    id: 10, img: DevelopProcedure, title: "Greenhouse gas accounting,", desc: "Join our most awaited and self paced course for AFCAT and prepare yourself for the academy",
    link: "More Details", btn: "Download Brochure", download: "ota.pdf"
  },
  {
    id: 11, img: DevelopmentAdvisory, title: "Carbon credit advisory", desc: "Join our most awaited and self paced course for CDS and prepare yourself for the academy",
    link: "More Details", btn: "Download Brochure", download: "cds.pdf"
  },
  {
    id: 12, img: HSEAdvisory, title: "Social accountability", desc: "Join our most awaited and self paced course for AFCAT and prepare yourself for the academy",
    link: "More Details", btn: "Download Brochure", download: "afcat.pdf"
  },
  {
    id: 13, img: DevelopProcedure, title: "Corporate Social Responsibility guidance", desc: "Join our most awaited and self paced course for AFCAT and prepare yourself for the academy",
    link: "More Details", btn: "Download Brochure", download: "ota.pdf"
  },
]

const ServicePage = () => {
  return (
    <MainLayout>
      <ServiceSectorHeader serviceName="ESG Menu Section"/>
      <div className="advisory-section">
        {advisoryData.map((advisory) => (
          <AdvisoryCard
            key={advisory.id}
            id={advisory.id}
            img={advisory.img}
            title={advisory.title}
            desc={advisory.desc}
            link={advisory.link}
            btn={advisory.btn}
          />
        ))}
      </div>
      <Footer/>
    </MainLayout>
  )
}

export default ServicePage
