import React, { FunctionComponent } from 'react';
import AuditingHeader from '../../component/AuditingAndConsulting/AuditingAndConsultingHeader';
import MainLayout from '../../component/Layout/MainLayout';
import Footer from '../../component/Footer/Footer';
import EnvironmentalAudit from '../../component/AuditingAndConsulting/EnvironmentalAudit';
interface EnvironmentalAuditingAndConsultingProps {

}

const EnvironmentalAuditingAndConsulting: FunctionComponent<EnvironmentalAuditingAndConsultingProps> = () => {

    const handleDownload = () => {
        console.log("download");
    };

    return (
        <div className='auditing-and-consulting'>
            <MainLayout>
                {/* <AuditingHeader /> */}
                <EnvironmentalAudit/>
                <Footer/>
            </MainLayout>
        </div>
    );
};

export default EnvironmentalAuditingAndConsulting;
