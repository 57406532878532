import React from 'react'
import MainLayout from '../component/Layout/MainLayout'
import Footer from '../component/Footer/Footer';
import News from '../component/Newsandevents/News';

const Newsandevent = () => {
  return (
    <MainLayout>
    <News/>
    <Footer/>
  </MainLayout>
  )
}

export default Newsandevent

