import React from "react";
import MainLayout from "../component/Layout/MainLayout";
import Footer from "../component/Footer/Footer";
import Wastemanage from "../component/Waste/Wastemanage";
import Commondigital from "../component/Drone/Commondigital";
import MainDigital from "../component/Drone/MainDigital";
import imgg from "../constrain/Images/wastemanagement6.jpg"

function Wastemanagement() {
  return (
    <MainLayout>
      <Commondigital heading={""} />
      <MainDigital
        heading={"Waste Management"}
        content={[
          {
            lione: "Vidura International is committed to providing holistic waste management solutions that align with your organization's sustainability goals. Our comprehensive approach covers the entire waste management lifecycle, from waste generation to disposal, emphasizing reduction, recycling, and responsible disposal practices.",
            litwo: "We begin with a detailed waste audit to understand the composition and volume of waste generated, enabling us to tailor a waste management strategy to your specific needs. Our services include the implementation of efficient waste segregation practices, promoting recycling initiatives, and exploring opportunities for waste-to-energy conversion.",
            li3: "Vidura International places a strong emphasis on education and awareness, empowering your workforce to actively participate in waste reduction efforts. By integrating technological solutions and industry best practices, we help organizations minimize their environmental footprint while ensuring compliance with waste management regulations.",
            li4: "Choose Vidura International for a sustainable waste management partner dedicated to enhancing environmental stewardship and contributing to a circular economy. ",
            li5: "",
            li6: "",
          },
        ]}
        image={imgg}
        text={"Initiate a discussion to explore how our waste management solutions can elevate your organization's commitment to responsible environmental practices."}
      />
      <Footer />
    </MainLayout>
  );
}

export default Wastemanagement;
