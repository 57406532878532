import React, { useEffect, useState } from 'react';
import { Grid, Input, TextareaAutosize, Typography, Box } from '@mui/material'
import InputField from '../InputField/InputField'
import emailjs from '@emailjs/browser';
import './enquiryform.css'
export interface ShareDetailsProps {

    setShareDetailsOpen: (isOpen: boolean) => void;

}

const ShareDetails = ({ setShareDetailsOpen }: ShareDetailsProps) => {
    const [isNEBOSH, setNEBOSH] = useState(false);
    const [isIOSH, setIOSH] = useState(false);
    const [isFirstAid, setFirstAid] = useState(false);
    const [isFireFighting, setIsFireFighting] = useState(false);


    const handleCheckboxChange = (event:any) => {
        const { name, checked } = event.target;
        switch (name) {
            case 'NEBOSH':
                setNEBOSH(checked);
                break;
            case 'IOSH':
                setIOSH(checked);
                break;
            case 'FirstAid':
                setFirstAid(checked);
                break;
            case 'FireFighting':
                setIsFireFighting(checked);
                break;
            default:
                break;
        }
    };

    const closeEnquiryForm = () => {
        setShareDetailsOpen(false)
    }

    const sendInfo = (e: any) => {
        e.preventDefault()
        // console.log(fullName, email, phoneNumber, message)

        emailjs.sendForm('service_tx8fm25', 'template_y014nuf', e.target, '8si9wlnxsZLmEBB_n')
            .then((response) => {
                console.log('Email sent successfully:', response);
                alert("Email sent successfully:")
            })
            .catch((error) => {
                console.error('Email sending failed:', error);
            });

        e.target.reset();

    }
    return (
        <div className="open-share-details-form">
            {/* <Box className=''> <span className='close-popup' onClick={closeEnquiryForm}>X</span></Box> */}
            <Typography variant='h2' fontWeight={600} fontSize={20} align='center' color={'white'} className='share-detail-header'>Welcome !!</Typography>
            <div className='WelocomeToChatBox'>
                <Typography variant='h2' fontWeight={600} fontSize={20} align='center' color={'white'} className='share-detail-header'>
                    Fill the form below for assistance
                </Typography>
            </div>

            <Grid className="popup-formContainer">
                <form className="popup-formContainer" onSubmit={sendInfo}>
                    <Grid className="popup-form">
                        <InputField type="text" name="fullName" placeholder={"Full Name"} />
                        <InputField type="text" name="phone" placeholder={"Phone"} />
                        <InputField type="email" name="email" placeholder={"Email"} />
                        <TextareaAutosize name="message" className="popup-messageContainer" placeholder='Message' />
                        <div style={{height:"100px",overflowY:"scroll"}}>
                            <label>
                                <input
                                    type="checkbox"
                                    name="NEBOSH"
                                    value={"NEBOSH"}
                                    checked={isNEBOSH}
                                    onChange={handleCheckboxChange}
                                />
                                NEBOSH Course
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    name="IOSH"
                                    value={"IOSH Course"}
                                    checked={isIOSH}
                                    onChange={handleCheckboxChange}
                                />
                                IOSH Course
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    name="FirstAid"
                                    value={"First Aid Course"}
                                    checked={isFirstAid}
                                    onChange={handleCheckboxChange}
                                />
                                First Aid Course
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    name="FireFighting"
                                    value={"Fire Fighting Course"}
                                    checked={isFireFighting}
                                    onChange={handleCheckboxChange}
                                />
                                Fire Fighting Course
                            </label>
                        </div>
                        <button type='submit' className='send-share-btn'>Send</button>
                    </Grid>
                </form>
            </Grid>
        </div>
    );
}

export default ShareDetails
