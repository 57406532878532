import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import menuImg from '../../constrain/Images/image1.svg'
import { Link } from 'react-router-dom';

import './menuCardStyle.css'
interface SubMenuListProps {
  homeMenu: {
    id: number;
    navMenu: string;
    path: string;
  }[];
}
const SubMenuList: React.FC<SubMenuListProps> = ({ homeMenu }) => {
  return (
    <Card className='sub-menu-style'>
      <CardContent >
        <Typography gutterBottom variant="h6" component="div">
          {homeMenu.map((item) => (
            <li className='all-menu-list' key={item.id}><Link to={item.path}> {item.navMenu}</Link></li>
          ))}
        </Typography>
      </CardContent>
    </Card>
  );
}


export default SubMenuList