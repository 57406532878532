import React, { useState } from "react";
import {
  AppBar, Box, Divider, Drawer, IconButton, Toolbar, Typography, Container
} from "@mui/material";

// import Logo from "../../constrain/Images/VIDURA-Transparent-Final.png";
import Logo from '../../constrain/Images/VIDURA.png'
import homeContentImg1 from '../../constrain/Images/image2.svg'
import homeContentImg2 from '../../constrain/Images/image3.svg'
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink, Link } from "react-router-dom";
import NavBarTabs from "./NavBarTabs";
import MenuCard from "./MenuCard";
import ContentCard from "./ContentCard";
import { homeMenu, contactMenu, serviceMenu, galleryMenu, aboutMenu, careerMenu, AuditingAndConsulting, ProfessionalResourcing, industrySubMenu, trainingAndLearning, strategicAdvisory, esgHseSoftwareService, digitalServices } from "./MenuData"
import AnimateContentCard from "./AnimateContentCard";
import "./navbarStyle.css";
import SubMenuList from "./SubMenuList";
import IndustriesSubMenu from "./IndustriesSubmenu";

const EHS = "The importance of collaboration with contractors and subcontractors in site Environmental, Health, and Safety (EHS) management cannot be overstated.."

interface SubMenu {
  name: string;
  isOpen: boolean;
}

const Navbar: React.FC = () => {
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>('');
  const [isHome, setHome] = useState(false);
  const [isAbout, setAbout] = useState(false);
  const [isContact, setContact] = useState(false);
  const [isService, setService] = useState(false);
  const [isGallery, setGallery] = useState(false);
  const [isCareer, setCareer] = useState(false);
  const [isAuditingAndConsulting, setAuditingAndConsulting] = useState(false);
  const [isProfessionalResourcing, setProfessionalResourcing] = useState(false);
  const [isLeadershipTeam, setLeadershipTeam] = useState(false);
  const [isDigitalServices, setDigitalServices] = useState(false);
  const [isIndustries, setIndustries] = useState(false);
  const [isTrainingLearning, setTrainingLearning] = useState(false);
  const [isStrategicAdvisory, setStrategicAdvisory] = useState(false);
  const [isCertificateAndCourse, setCertificateAndCourse] = useState(false);
  const [isNewsAndEvent, setNewsAndEvent] = useState(false);


  const [subMenuClick, setSubMenuClick] = useState("");
  const [subMenus, setSubMenus] = useState<SubMenu[]>([
    { name: "service", isOpen: false },
    { name: "digital-service", isOpen: false },
    { name: "Auditing-Consulting", isOpen: false },
    { name: "Industries", isOpen: false },
    { name: "Training-Learning", isOpen: false },
    { name: "Strategic-Advisory", isOpen: false },
    { name: "Professional-Resourcing", isOpen: false },


    // Add more sub-menu items as needed
  ]);


  const handleDrawerToggle = (): void => {
    setMobileOpen(!mobileOpen);
  };

  const showSubMenu = (menuName: string) => {
    const updatedSubMenus = subMenus.map((menu) => {
      if (menu.name === menuName) {
        return { ...menu, isOpen: !menu.isOpen };
      }
      return { ...menu, isOpen: false };
    });
    setSubMenus(updatedSubMenus);
    console.log(menuName, subMenus, subMenus[0].isOpen)
  };

  const handleTabClick = (tabTitle: string) => {
    console.log(tabTitle)
    if (tabTitle === "About") {
      setAbout(!isAbout)
      setHome(false)
      setContact(false)
      setService(false)
      setGallery(false)
      setAuditingAndConsulting(false)
      setProfessionalResourcing(false)
      setLeadershipTeam(false)
      setDigitalServices(false)
      setIndustries(false)
      setTrainingLearning(false)
      setStrategicAdvisory(false)
      setCertificateAndCourse(false)
      setNewsAndEvent(false)
    } else if (tabTitle === "Home") {
      setHome(!isHome)
      setAbout(false)
      setContact(false)
      setService(false)
      setGallery(false)
      setAuditingAndConsulting(false)
      setProfessionalResourcing(false)
      setLeadershipTeam(false)
      setDigitalServices(false)
      setIndustries(false)
      setTrainingLearning(false)
      setStrategicAdvisory(false)
      setCertificateAndCourse(false)
      setNewsAndEvent(false)

    } else if (tabTitle === "Service") {
      setService(!isService)
      setHome(false)
      setAbout(false)
      setContact(false)
      setGallery(false)
      setAuditingAndConsulting(false)
      setProfessionalResourcing(false)
      setLeadershipTeam(false)
      setDigitalServices(false)
      setIndustries(false)
      setTrainingLearning(false)
      setStrategicAdvisory(false)
      setCertificateAndCourse(false)
      setNewsAndEvent(false)

    } else if (tabTitle === "Contact") {
      setContact(!isContact)
      setHome(false)
      setAbout(false)
      setService(false)
      setGallery(false)
      setAuditingAndConsulting(false)
      setProfessionalResourcing(false)
      setLeadershipTeam(false)
      setDigitalServices(false)
      setIndustries(false)
      setTrainingLearning(false)
      setStrategicAdvisory(false)
      setCertificateAndCourse(false)
      setNewsAndEvent(false)

    } else if (tabTitle === "Gallery") {
      setGallery(!isGallery)
      setHome(false)
      setAbout(false)
      setContact(false)
      setService(false)
      setAuditingAndConsulting(false)
      setProfessionalResourcing(false)
      setLeadershipTeam(false)
      setDigitalServices(false)
      setIndustries(false)
      setTrainingLearning(false)
      setStrategicAdvisory(false)
      setCertificateAndCourse(false)


    } else if (tabTitle === "Career") {
      setCareer(!isCareer)
      setGallery(false)
      setHome(false)
      setAbout(false)
      setContact(false)
      setService(false)
      setAuditingAndConsulting(false)
      setProfessionalResourcing(false)
      setLeadershipTeam(false)
      setDigitalServices(false)
      setIndustries(false)
      setTrainingLearning(false)
      setStrategicAdvisory(false)
      setCertificateAndCourse(false)
      setNewsAndEvent(false)


    } else if (tabTitle === "Auditing and Consulting") {
      setAuditingAndConsulting(!isAuditingAndConsulting)
      setCareer(false)
      setGallery(false)
      setHome(false)
      setAbout(false)
      setContact(false)
      setService(false)
      setProfessionalResourcing(false)
      setLeadershipTeam(false)
      setDigitalServices(false)
      setIndustries(false)
      setTrainingLearning(false)
      setStrategicAdvisory(false)
      setCertificateAndCourse(false)
      setNewsAndEvent(false)


    } else if (tabTitle === "Professional Resourcing") {
      setProfessionalResourcing(!isProfessionalResourcing)
      setAuditingAndConsulting(false)
      setCareer(false)
      setGallery(false)
      setHome(false)
      setAbout(false)
      setContact(false)
      setService(false)
      setLeadershipTeam(false)
      setDigitalServices(false)
      setIndustries(false)
      setTrainingLearning(false)
      setStrategicAdvisory(false)
      setStrategicAdvisory(false)
      setCertificateAndCourse(false)


    } else if (tabTitle === "Leadership Team") {
      setLeadershipTeam(!isLeadershipTeam)
      setProfessionalResourcing(false)
      setAuditingAndConsulting(false)
      setCareer(false)
      setGallery(false)
      setHome(false)
      setAbout(false)
      setContact(false)
      setService(false)
      setDigitalServices(false)
      setIndustries(false)
      setTrainingLearning(false)
      setStrategicAdvisory(false)
      setCertificateAndCourse(false)


    } else if (tabTitle === "Digital Services") {
      setDigitalServices(!isDigitalServices)
      setLeadershipTeam(false)
      setProfessionalResourcing(false)
      setAuditingAndConsulting(false)
      setCareer(false)
      setGallery(false)
      setHome(false)
      setAbout(false)
      setContact(false)
      setService(false)
      setIndustries(false)
      setTrainingLearning(false)
      setStrategicAdvisory(false)
      setCertificateAndCourse(false)


    } else if (tabTitle === "Industries") {
      setIndustries(!isIndustries)
      setDigitalServices(false)
      setLeadershipTeam(false)
      setProfessionalResourcing(false)
      setAuditingAndConsulting(false)
      setCareer(false)
      setGallery(false)
      setHome(false)
      setAbout(false)
      setContact(false)
      setService(false)
      setTrainingLearning(false)
      setStrategicAdvisory(false)
      setCertificateAndCourse(false)


    } else if (tabTitle === "Training and Learning") {
      setTrainingLearning(!isTrainingLearning)
      setIndustries(false)
      setDigitalServices(false)
      setLeadershipTeam(false)
      setProfessionalResourcing(false)
      setAuditingAndConsulting(false)
      setCareer(false)
      setGallery(false)
      setHome(false)
      setAbout(false)
      setContact(false)
      setService(false)
      setStrategicAdvisory(false)
      setCertificateAndCourse(false)

    } else if (tabTitle === "Strategic Advisory") {
      setStrategicAdvisory(!isStrategicAdvisory)
      setTrainingLearning(false)
      setIndustries(false)
      setDigitalServices(false)
      setLeadershipTeam(false)
      setProfessionalResourcing(false)
      setAuditingAndConsulting(false)
      setCareer(false)
      setGallery(false)
      setHome(false)
      setAbout(false)
      setContact(false)
      setService(false)
      setCertificateAndCourse(false)

    }
    else if (tabTitle === "News & Events") {
      setNewsAndEvent(!isNewsAndEvent)
      setStrategicAdvisory(false)
      setTrainingLearning(false)
      setIndustries(false)
      setDigitalServices(false)
      setLeadershipTeam(false)
      setProfessionalResourcing(false)
      setAuditingAndConsulting(false)
      setCareer(false)
      setGallery(false)
      setHome(false)
      setAbout(false)
      setContact(false)
      setService(false)
      setCertificateAndCourse(false)

    }
    else if (tabTitle === "Certification & Courses") {
      setCertificateAndCourse(!isCertificateAndCourse)
      setNewsAndEvent(false)
      setStrategicAdvisory(false)
      setTrainingLearning(false)
      setIndustries(false)
      setDigitalServices(false)
      setLeadershipTeam(false)
      setProfessionalResourcing(false)
      setAuditingAndConsulting(false)
      setCareer(false)
      setGallery(false)
      setHome(false)
      setAbout(false)
      setContact(false)
      setService(false)
      setNewsAndEvent(false)

    }
    setActiveTab(tabTitle);
    // console.log(tabTitle)
  };

  // menu drawer
  const drawer = (
    <Box sx={{ padding: "5px" }}>
      <Container>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          sx={{
            mr: 2,
            display: { md: "none" },
            backgroundColor: "#3e8ed0",
            width: "50px"
          }}
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          color="goldenrod"
          variant="h6"
          component="div"
          sx={{ flexGrow: 1, my: 2 }}
        >

          <img src={Logo} alt="logo" className="vidura-Mobile-logo" />
        </Typography>
      </Container>


      <Divider />
      <ul className="mobile-navigation">
        <li className="sub-menu-container">
          <span><NavLink to={"/"} >Home</NavLink></span>
        </li>

        <li className="sub-menu-container">
          <span><NavLink to={"/about"} >About</NavLink></span>
        </li>

        <li className="sub-menu-container">
          <span onClick={() => showSubMenu("service")} className="side-sub-menu-list">Service -{">"}</span>
          <ul className={`${subMenus[0].isOpen ? 'sub-menu-list-open' : 'sub-menu-list'}`}>
            <li className="sub-menu-item"><NavLink to={"/esg"} >ESG</NavLink></li>
            <li className="sub-menu-item"><NavLink to={"/hse"} >HSE</NavLink></li>
            <li className="sub-menu-item"><NavLink to={"/skill-development"} >Skill Development</NavLink></li>
            <li className="sub-menu-item"><NavLink to={"/trainings"} >Trainings</NavLink></li>
            <li className="sub-menu-item"><NavLink to={"/fire-safety-management"} >Fire Safety Management</NavLink></li>
            <li className="sub-menu-item"><NavLink to={"/occupational-health-management"} >OHM</NavLink></li>
            <li className="sub-menu-item"><NavLink to={"/Competent-human-management"} >Competent Human Management</NavLink></li>
            <li className="sub-menu-item"><NavLink to={"/management-system-consulting"} >Management System Consulting</NavLink></li>
            <li className="sub-menu-item"><NavLink to={"/food-safety"} >Food Safety</NavLink></li>
            <li className="sub-menu-item"><NavLink to={"/mep-epc-contracts"} >MEP EPC Contracts</NavLink></li>
            <li className="sub-menu-item"><NavLink to={"/material-procurement-and-supply"} >Material Procurement & Supply</NavLink></li>
          </ul>
        </li>
        <li >
          <NavLink to={"/contact"} >
            Contact
          </NavLink>
        </li>
        <li>
          <span onClick={() => showSubMenu("digital-service")} className="side-sub-menu-list">Digital Service -{">"}</span>
          <ul className={`${subMenus[1].isOpen ? 'sub-menu-list-open' : 'sub-menu-list'}`}>
            {digitalServices.map((submenuItem, ind) => (
              <>
                <li className="sub-menu-item"><NavLink to={submenuItem.path} >{submenuItem.navMenu}</NavLink></li>
              </>
            ))}
          </ul>
        </li>
        <li>
          <NavLink to={"/leader"} >
            Leadership Team
          </NavLink>
        </li>

        <li>
          <NavLink to={"/career"} >
            Career
          </NavLink>
        </li>

        <li>
          {/* <NavLink to={"/contact"} >
            Industries
          </NavLink> */}
          <span onClick={() => showSubMenu("Industries")} className="side-sub-menu-list">Industries -{">"}</span>
          <ul className={`${subMenus[3].isOpen ? 'sub-menu-list-open' : 'sub-menu-list'}`}>
            {industrySubMenu.map((submenuItem, ind) => (
              <>
                <li className="sub-menu-item"><NavLink to={submenuItem.path} >{submenuItem.navMenu}</NavLink></li>
              </>
            ))}
          </ul>
        </li>
        <li>
          <span onClick={() => showSubMenu("Auditing-Consulting")} className="side-sub-menu-list">Auditing and Consulting -{">"}</span>
          <ul className={`${subMenus[2].isOpen ? 'sub-menu-list-open' : 'sub-menu-list'}`}>
            {AuditingAndConsulting.map((submenuItem, ind) => (
              <>
                <li className="sub-menu-item"><NavLink to={submenuItem.path} >{submenuItem.navMenu}</NavLink></li>
              </>
            ))}
          </ul>

        </li>
        <li>
          <span onClick={() => showSubMenu("Training-Learning")} className="side-sub-menu-list">Training and Learning -{">"}</span>
          <ul className={`${subMenus[4].isOpen ? 'sub-menu-list-open' : 'sub-menu-list'}`}>
            {trainingAndLearning.map((subMenuItem, ind) => (
              <>
                <li className="sub-menu-item"><NavLink to={subMenuItem.path} >{subMenuItem.navMenu}</NavLink></li>
              </>
            ))}
          </ul>
        </li>
        <li>
          <span onClick={() => showSubMenu("Strategic-Advisory")} className="side-sub-menu-list">Strategic Advisory -{">"}</span>
          <ul className={`${subMenus[5].isOpen ? 'sub-menu-list-open' : 'sub-menu-list'}`}>
            {strategicAdvisory.map((subMenuItem, ind) => (
              <>
                <li className="sub-menu-item"><NavLink to={subMenuItem.path} >{subMenuItem.navMenu}</NavLink></li>
              </>
            ))}
          </ul>

        </li>
        <li>
          <span onClick={() => showSubMenu("Professional-Resourcing")} className="side-sub-menu-list">Professional Resourcing{">"}</span>
          <ul className={`${subMenus[6].isOpen ? 'sub-menu-list-open' : 'sub-menu-list'}`}>
            {ProfessionalResourcing.map((subMenuItem, ind) => (
              <>
                <li className="sub-menu-item"><NavLink to={subMenuItem.path} >{subMenuItem.navMenu}</NavLink></li>
              </>
            ))}
          </ul>
        </li>

        <li>
          <NavLink to={"/certificate"} >
            Certification & Cources
          </NavLink>
        </li>
        <li>
          <NavLink to={"/news-event"} >
            News & Events
          </NavLink>
        </li>
        <li>
          <NavLink to={"/gallery"} >
            Gallery
          </NavLink>
        </li>
      </ul>
    </Box>
  );

  return (
    <>
      <div>
        <Box sx={{ position: "relative" }}>
          <AppBar component="nav" sx={{ bgcolor: "white", position: "inherit" }}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                sx={{
                  mr: 2,
                  display: { md: "none" },
                  backgroundColor: "#3e8ed0"
                }}
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                color="goldenrod"
                variant="h6"
                component="div"
                sx={{ flexGrow: 1 }}
              >
                <Link to="/">
                  <img src={Logo} alt="logo" className="vidura-logo" />
                </Link>
              </Typography>
              <Box sx={{ display: { sm: "none", xs: "none", md: "block" } }}>
                <ul className="navigation-menu">
                  <li className="nav-item" onMouseEnter={() => handleTabClick('Leadership Team')}>
                    <Link to="/"><span className="navMenuBtn" >Home</span></Link>
                  </li>
                  {/* <li className="nav-item">
                    <NavBarTabs
                      title="Home"
                      isActive={activeTab === 'Home'}
                      onMouseEnter={() => handleTabClick('Home')}
                    />
                  </li> */}
                  <li className="nav-item" onMouseEnter={() => handleTabClick('Leadership Team')}>
                    <Link to="/about"><span className="navMenuBtn" >About</span></Link>
                  </li>
                  {/* <li className="nav-item" >
                    <NavBarTabs
                      title="About Us"
                      isActive={activeTab === 'About'}
                      onMouseEnter={() => handleTabClick('About')}
                    />
                  </li> */}
                  <li className="nav-item">
                    <NavBarTabs
                      title="Services"
                      isActive={activeTab === 'Service'}
                      onMouseEnter={() => handleTabClick('Service')}
                    />
                  </li>
                  {/* <li className="nav-item">
                    <NavBarTabs
                      title="Gallery"
                      isActive={activeTab === 'Gallery'}
                      onClick={() => handleTabClick('Gallery')}
                    />
                  </li> */}
                  <li className="nav-item" >
                    <NavBarTabs
                      title="Digital Services"
                      isActive={activeTab === 'Digital Services'}
                      onMouseEnter={() => handleTabClick('Digital Services')}
                    />
                  </li>
                  <li className="nav-item" onMouseEnter={() => handleTabClick('Leadership Team')}>
                    <Link to="/leader"><span className="navMenuBtn" >Leadership Team</span></Link>

                    {/* <NavBarTabs
                      title="Leadership Team"
                      isActive={activeTab === 'Leadership Team'}
                      onMouseEnter={() => handleTabClick('Leadership Team')}
                    /> */}
                  </li>
                  <li className="nav-item"
                    onMouseEnter={() => handleTabClick('Contact')}
                  >
                    <Link to="/contact"><span className="navMenuBtn" >Contact Us</span></Link>
                    {/* <NavBarTabs
                      title="Contact"
                      isActive={activeTab === 'Contact'}
                      onMouseEnter={() => handleTabClick('Contact')}
                    /> */}

                  </li>
                  <li className="nav-item"
                    onMouseEnter={() => handleTabClick('Career')}

                  >
                    <Link to="/career"><span className={`navMenuBtn ${activeTab === 'Career' ? 'activeTab' : ""}`} >Career</span></Link>

                    {/* <NavBarTabs
                      title="Career"
                      isActive={activeTab === 'Career'}
                      onMouseEnter={() => handleTabClick('Career')}
                    /> */}
                  </li>
                </ul>
              </Box>
            </Toolbar>
            <Box sx={{ display: { sm: "none", xs: "none", md: "block" } }}>
              <div className="Menu-list-container" >
                <ul className="navigation-menu">
                  <li className="sub-nav-item"
                    onMouseEnter={() => handleTabClick('Industries')}
                  >
                    Industries
                    {/* <Link to={"/industries"}>Industries</Link> */}
                  </li>
                  <li className="sub-nav-item"
                    onMouseEnter={() => handleTabClick('Auditing and Consulting')}
                  >
                    Auditing and Consulting
                  </li>
                  <li className="sub-nav-item"
                    onMouseEnter={() => handleTabClick('Training and Learning')}
                  >
                    Training and Learning
                    {/* <Link to={"/training-learning"}> Training and Learning</Link> */}


                  </li>
                  <li className="sub-nav-item"
                    onMouseEnter={() => handleTabClick('Strategic Advisory')}

                  >
                    Strategic Advisory
                  </li>
                  <li className="sub-nav-item"
                    onMouseEnter={() => handleTabClick('Professional Resourcing')}
                  >
                    Professional Resourcing
                  </li>
                  <li className="sub-nav-item"
                    onMouseEnter={() => handleTabClick('Certification & Cources')}

                  >
                    <Link to={"/certificate-&-course/nebosh-courses"}>Certification & Cources</Link>
                  </li>
                  <li className="sub-nav-item"
                    onMouseEnter={() => handleTabClick('News & Events')}
                  >
                    <Link to={"/news-event"}>News & Events</Link>
                  </li>
                </ul>
              </div>
            </Box>


          </AppBar>
          <Box component="nav" >
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              sx={{
                display: { sm: "block", md: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: "240px",
                },
              }}
            >
              {drawer}
            </Drawer>
          </Box>

          <div className="smoothOpening">
            {activeTab === 'Home' && isHome ?
              <div className="menuCard" onMouseLeave={() => handleTabClick('Home')}>
                <MenuCard homeMenu={homeMenu} title={"VIDURA HOME"} />
                <AnimateContentCard />
              </div> : null}

            {activeTab === 'About' && isAbout ?
              <div className="menuCard" onMouseLeave={() => handleTabClick('About')}>
                <MenuCard homeMenu={aboutMenu} title={"VIDURA ABOUT"} />
                <ContentCard img={homeContentImg1} title={"IT consulting"} des={EHS} />
                <ContentCard img={homeContentImg2} title={"Marketing and Advertising Consulting"} des={EHS} />
              </div> : null}
            {activeTab === 'Service' && isService ?
              <div className="menuCard-servie" onMouseLeave={() => handleTabClick('Service')}>
                <MenuCard homeMenu={serviceMenu} title={"Vidura Services"} />
                {/* <ContentCard img={homeContentImg1} title={"Environmental, Health, and Safety management"} des={EHS} />
                <ContentCard img={homeContentImg2} title={"Financial Consulting"} des={EHS} /> */}
              </div> : null}
            {/* {activeTab === 'Contact' && isContact ?
              <div className="menuCard" onMouseLeave={() => handleTabClick('Contact')}>
                <MenuCard homeMenu={contactMenu} title={"VIDURA CONTACT"} />
                <ContentCard img={homeContentImg1} title={"Real Estate Consulting"} des={EHS} />
                <ContentCard img={homeContentImg2} title={"Social Media and Digital Marketing Consulting"} des={EHS} />
              </div> : null} */}

            {activeTab === 'Digital Services' && isDigitalServices ?
              <div className="menuCard" onMouseLeave={() => handleTabClick('Contact')}>
                <MenuCard homeMenu={digitalServices} title={"Digital Services"} />
                <MenuCard homeMenu={esgHseSoftwareService} title={"ESG/HSE Software services"} />
              </div> : null}



            {activeTab === 'Gallery' && isGallery ?
              <div className="menuCard">
                <MenuCard homeMenu={galleryMenu} title={"VIDURA GALLERY"} />
                <ContentCard img={homeContentImg1} title={"Strategy Consulting"} des={EHS} />
                <ContentCard img={homeContentImg2} title={"Legal Consulting"} des={EHS} />
              </div> : null}


            {/* {activeTab === 'Career' && isCareer ?
              <div className="menuCard" onMouseLeave={() => handleTabClick('Career')}>
                <MenuCard homeMenu={careerMenu} title={"VIDURA GALLERY"} />
                <ContentCard img={homeContentImg1} title={"Strategy Consulting"} des={EHS} />
                <ContentCard img={homeContentImg2} title={"Legal Consulting"} des={EHS} />
              </div> : null} */}
          </div>
          <div className="IndustriesSubmenu">
            {activeTab === 'Industries' && isIndustries ?
              <div className="menuCard IndustriesSubmenu-style" onMouseLeave={() => handleTabClick('Industries')}>
                <SubMenuList homeMenu={industrySubMenu} />
                {/* <IndustriesSubMenu submenu={industrySubMenu} /> */}
              </div> : null}
          </div>
          <div className="AuditingAndConsulting">
            {activeTab === 'Auditing and Consulting' && isAuditingAndConsulting ?
              <div className="menuCard" onMouseLeave={() => handleTabClick('Auditing and Consulting')}>
                <SubMenuList homeMenu={AuditingAndConsulting} />
              </div> : null}
          </div>
          <div className="StrategicAndAdvisory">
            {activeTab === 'Strategic Advisory' && isStrategicAdvisory ?
              <div className="menuCard" onMouseLeave={() => handleTabClick('Strategic Advisory')}>
                <SubMenuList homeMenu={strategicAdvisory} />
              </div> : null}
          </div><div className="TrainingAndLearning">
            {activeTab === 'Training and Learning' && isTrainingLearning ?
              <div className="menuCard" onMouseLeave={() => handleTabClick('Training and Learning')}>
                <SubMenuList homeMenu={trainingAndLearning} />
              </div> : null}
          </div>
          <div className="ProfessionalResourcing">
            {activeTab === 'Professional Resourcing' && isProfessionalResourcing ?
              <div className="menuCard" onMouseLeave={() => handleTabClick('Professional Resourcing')}>
                <SubMenuList homeMenu={ProfessionalResourcing} />
              </div> : null}
          </div>
          {/* <Box>
            <Toolbar />
          </Box> */}
        </Box>
      </div>
    </>
  );
};

export default Navbar;
