import React from 'react'
import MainLayout from '../component/Layout/MainLayout'
import Leader from '../component/Leadershipteam/Leader'
import Footer from '../component/Footer/Footer'

function Leadership() {
  return (
    <MainLayout>
      <Leader />
      <Footer />
    </MainLayout>
  )
}

export default Leadership