import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import AuditingHeader from '../../component/AuditingAndConsulting/AuditingAndConsultingHeader';
import MainLayout from '../../component/Layout/MainLayout';
import AuditingAndConsulting from '../../component/AuditingAndConsulting/HSEAuditAndConsulting';
import Footer from '../../component/Footer/Footer';

interface HSEAuditingAndConsultingProps {

}

const HSEAuditingAndConsulting: FunctionComponent<HSEAuditingAndConsultingProps> = () => {

    const handleDownload = () => {
        console.log("download");
    };

    return (
        <div className='auditing-and-consulting'>
            <MainLayout>
                <AuditingHeader heading='HSE Auditing'/>
                <AuditingAndConsulting/>
                <Footer/>
            </MainLayout>
        </div>
    );
};

export default HSEAuditingAndConsulting;
