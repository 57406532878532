import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import './ourApproach.css'
import img1 from '../../constrain/Images/close-up-young-business-person-doing-internship.jpg'

interface OurApproachProps {

}

const OurApproach: FunctionComponent<OurApproachProps> = () => {

    const handleDownload = () => {
        console.log("download");
    };

    return (
        <div className='our-approach-section'>
            <h3 className='vidura-consultancy-approach'>
                At <span>Vidura</span><span>Consultancy</span>, we understand the importance of personal and professional development. That's why we offer a comprehensive Development Advisory program designed to help you reach your full potential and achieve your goals.
            </h3>
            <div className='Our-Development-Advisory-program'>
                <p className='expert-para'>
                    Our Development Advisory program is carefully crafted to provide you with the knowledge, skills, and guidance you need to excel in your chosen field. Whether you're preparing for the CDS (Combined Defence Services) examination or looking to enhance your career prospects, our program is tailored to meet your specific needs.
                </p>
                <div className='our-development-approach-img'>
                    <img src={img1} alt='our-development-advisory' />
                </div>
            </div>

            <div className='What-We-Offer'>
                <div className='Expert-Guidance-img'>
                    <img src={img1} alt='our-development-advisory' />
                </div>
                <div className='expert-guidance'>
                    <h3>Expert Guidance</h3>
                    <p className='expert-para'>
                        Our team of experienced advisors and mentors are here to guide you every step of the way. From setting clear goals to developing a personalized study plan, we're committed to your success.
                    </p>
                </div>
            </div>
            <div className='What-We-Offer'>
                <div className='expert-guidance'>
                    <h3>Comprehensive Resources</h3>
                    <p className='expert-para'>
                        Our team of experienced advisors and mentors are here to guide you every step of the way. From setting clear goals to developing a personalized study plan, we're committed to your success.
                    </p>
                </div>
                <div className='Expert-Guidance-img'>
                    <img src={img1} alt='our-development-advisory' />
                </div>
            </div>
            <div className='What-We-Offer'>
                <div className='Expert-Guidance-img'>
                    <img src={img1} alt='our-development-advisory' />
                </div>
                <div className='expert-guidance'>
                    <h3>Self-Paced Learning</h3>
                    <p className='expert-para'>
                        Our team of experienced advisors and mentors are here to guide you every step of the way. From setting clear goals to developing a personalized study plan, we're committed to your success.
                    </p>
                </div>
            </div>
            <div className='What-We-Offer'>
                <div className='expert-guidance'>
                    <h3>Community Support</h3>
                    <p className='expert-para'>
                        Our team of experienced advisors and mentors are here to guide you every step of the way. From setting clear goals to developing a personalized study plan, we're committed to your success.
                    </p>
                </div>
                <div className='Expert-Guidance-img'>
                    <img src={img1} alt='our-development-advisory' />
                </div>
            </div>
        </div>
    );
};

export default OurApproach;
