import img1 from '../Images/close-up-people-working-with-laptop.jpg'
import img2 from '../Images/close-up-young-business-person-doing-internship.jpg'
import img3 from '../Images/group-people-working-out-business-plan-office.jpg'



export const galleryPropsData=[
    {id:1,imageSrc:img1,title:"IT"},
    {id:2,imageSrc:img2,title:"Social Media"},
    {id:3,imageSrc:img3,title:"Digital Media"},
    {id:4,imageSrc:img1,title:"IT"},
    {id:5,imageSrc:img2,title:"Social Media"},
    {id:6,imageSrc:img3,title:"Digital Media"},
    {id:7,imageSrc:img1,title:"IT"},
    {id:8,imageSrc:img2,title:"Social Media"},
    
  ]