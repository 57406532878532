import React from 'react';
import MainLayout from '../../component/Layout/MainLayout';
import Footer from '../../component/Footer/Footer';
import KnowMore from '../../component/Services/KnowMore';
import EsgAdvisoryCard from '../../component/Advisory/EsgAdvisoryCard';
import img1 from '../../constrain/Images/Morgan-Advanced-Materials-bg.jpg';
import img2 from '../../constrain/Images/foundation-in-health-safety-IGC-1.jpg';
import img3 from '../../constrain/Images/group-people-working-out-business-plan-office.jpg';
import img4 from '../../constrain/Images/health-safety-management-systems-plan-IGC-2.jpg';
import img5 from '../../constrain/Images/work-equipment-hazardous.jpg';
import img6 from '../../constrain/Images/green-overlay.png';
import headerImage from '../../constrain/Images/ESG-reporting.webp';
import image from "../../constrain/Images/VIDURA_images/VIDURA_images/Social Accountability.jpg"
import ESGManagementHeader from '../../component/Services/Header/ESGManagementHeader';
import MainPage from '../HseMenuPage/MainPage';

interface EsgAdvisoryDataItem {
  id: number;
  img: string;
  title: string;
  desc: string;
  link: string;
  btn: string;
  download: string;
}

const EsgAdvisoryData: EsgAdvisoryDataItem[] = [
  {
    id: 1,
    img: img1,
    title: "ESG Advisory",
    desc: "Join our most awaited and self-paced course for NDA and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "ndabrochure.pdf",
  },
  {
    id: 2,
    img: img2,
    title: "Development advisory",
    desc: "Join our most awaited and self-paced course for CDS and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "cds.pdf",
  },
  {
    id: 3,
    img: img3,
    title: "HSE advisory for changing the culture",
    desc: "Join our most awaited and self-paced course for AFCAT and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "afcat.pdf",
  },
  {
    id: 4,
    img: img4,
    title: "Development of procedures/process",
    desc: "Join our most awaited and self-paced course for AFCAT and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "ota.pdf",
  },
  {
    id: 5,
    img: img5,
    title: "HSE advisory for changing the culture",
    desc: "Join our most awaited and self-paced course for AFCAT and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "afcat.pdf",
  },
  {
    id: 6,
    img: img6,
    title: "Development of procedures/process",
    desc: "Join our most awaited and self-paced course for AFCAT and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "ota.pdf",
  },
];

const SocialAccountability: React.FC = () => {
  return (
    <MainLayout>
      {/* <ESGManagementHeader headerImage={headerImage} title="Social Accountability" />
      <div className='esg-section'>
        <h3>Designed To Achieve Your Safety Training Goals</h3>
      </div>
      <div className='esgAdvisory-section'>
        {EsgAdvisoryData.map((esdAdvisory) => (
          <EsgAdvisoryCard
            key={esdAdvisory.id}
            id={esdAdvisory.id}
            img={esdAdvisory.img}
            title={esdAdvisory.title}
            desc={esdAdvisory.desc}
            link={esdAdvisory.link}
            btn={esdAdvisory.btn}
          />
        ))}
      </div> */}
      <KnowMore heading={''}/>
      <MainPage 
      heading={'Social Accountability'} 
      content={[{
        lione:"At Vidura International, we believe in the transformative power of Social Accountability. Our specialized services go beyond compliance, aiming to empower organizations to make a positive impact on society.",
        litwo:"Our seasoned experts work collaboratively with your organization to assess, implement, and enhance social responsibility initiatives. From ethical labour practices to community engagement, we guide you in fostering a culture of accountability that extends beyond business operations." ,
        li3:"Vidura International's approach to Social Accountability is rooted in transparency and ethical practices. We help you not only meet social standards but exceed them, building trust with stakeholders and contributing to the well-being of the communities you serve.",
        li4:"Stay informed about your social accountability initiatives with our monitoring and reporting mechanisms, complete with established key performance indicators (KPIs) for regular assessment and transparent reporting. Choose Vidura International for Social Accountability services that pave the way for a socially responsible and sustainable future.",
        li5:"Initiate a collaborative discussion to implement strategies tailored to elevate your organization's commitment to social responsibility.",
        li6:""
      }]}
       image={image}/>
      <Footer />
    </MainLayout>
  );
};

export default SocialAccountability;
