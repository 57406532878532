import React from 'react'
import styles from './FirstAidCourses.module.scss'
import { Grid } from '@mui/material'
import HeadingText from '../HeadingText/HeadingText'
import data from './data.json'

const FireAndSafetyCourses = () => {
    return (
        <Grid className={styles.firstAidCourses}>
            <HeadingText data={data.fireAndSafetyHeading} />
            <HeadingText data={data.fireAndSafetyCourse} />
        </Grid>
    )
}

export default FireAndSafetyCourses