import React from "react";
import "./Media.css";
import {  Divider } from "@mui/material";

function Media() {
  return (
    <>
      <div className="medianews">
        <h4>For the Media</h4>
      </div>
      <Divider style={{ margin: '20px 0' }}/>

      <div className="container differentcontainer">
        <div className="row containermedia">
          <div className="col differentcol">
            <a href="/news-event">
              <h5>Media Resources</h5>
            </a>
            <p>
              Answers to common questions from the media including photo
              permissions.
            </p>
          </div>
          <div className="col">
            <a href="/news-event">
              <h5>Media Resources</h5>
            </a>
            <p>
              Answers to common questions from the media including photo
              permissions.
            </p>
          </div>
          <div className="col">
            <a href="/news-event">
              <h5>Media Resources</h5>
            </a>
            <p>
              Answers to common questions from the media including photo
              permissions.
            </p>
          </div>
          <div className="w-100"></div>
          <div className="col">
            <a href="/news-event">
              <h5>Media Resources</h5>
            </a>
            <p>
              Answers to common questions from the media including photo
              permissions.
            </p>
          </div>
          <div className="col">
            <a href="/news-event">
              <h5>Media Resources</h5>
            </a>
            <p>
              Answers to common questions from the media including photo
              permissions.
            </p>
          </div>
          <div classNamenews-event="col">
            <a href="/news-event">
              <h5>Media Resources</h5>
            </a>
            <p>
              Answers to common questions from the media including photo
              permissions.
            </p>
          </div>
        </div>
        
      </div>
    </>
  );
}

export default Media;
