import React, { FunctionComponent, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./auditingAndConsulting.css";
import AuditCard from "./AuditCard";
import hseAudit1 from "../../constrain/Images/What-is-HSE-Audit.jpg";
import hseAudit2 from "../../constrain/Images/What-is-HSE-Audit.jpg";
import hseAudit3 from "../../constrain/Images/safety-audit-500x500.webp";
import hseAudit4 from "../../constrain/Images/safety1.jpg";
import hseAudit5 from "../../constrain/Images//cq5dam.web.436.228.webp";
import AuditRightSectionCard from "./AuditRightSectionCard";
import Auditcard1 from "./Auditcard1";
import card1img from "../../constrain/Images/VIDURA_images/VIDURA_images/audit-4189560_1280.jpg"
import card2img from "../../constrain/Images/VIDURA_images/VIDURA_images/pexels-mikael-blomkvist-8960996.jpg";

interface IMSAuditProps {}

const IMSAudit: FunctionComponent<IMSAuditProps> = () => {
  const [isVisible, setIsVisible] = useState(false);
  const handleVisibilityChange = () => {
    const element = document.querySelector(".audit-show-when-visible");
    if (element) {
      const rect = element.getBoundingClientRect();
      const elementIsVisible =
        rect.top < window.innerHeight && rect.bottom >= 0;
      setIsVisible(elementIsVisible);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleVisibilityChange);
    return () => {
      window.removeEventListener("scroll", handleVisibilityChange);
    };
  }, []);

  return (
    <section className="audit-page">
      <div className="auditing-and-consulting-main">
        <div className="auditing-main-left-section">
          <AuditCard
            img={card1img}
            title="Audit Innovation"
            auditName="Integrated Management System (IMS) Audit Services"
            para={[
              {
                text: "Streamlining Excellence, Ensuring Compliance with Vidura International At Vidura International, we understand that achieving operational excellence and maintaining compliance with various management standards are paramount to the success of your organization. Our Integrated Management System (IMS) Audit Services are meticulously designed to assess, harmonize, and optimize your management processes.",
              },
            ]}
          />

          <Auditcard1 
          mainheading={"Our IMS Audit Process:"} 
          img={card2img} 
          para={[
            {
                text:"Understanding your organization's goals, existing management systems, and areas for improvement.",heading:"Needs Assessment"
               },
               {text:"Thorough examination of your QMS, EMS, and OHSMS, identifying integration points and areas for optimization",heading:"On-Site Evaluation"},
               {text:": In-depth analysis of management processes, documentation, and compliance levels.",heading:"Data Analysis "},{
                text:"Receive a detailed report with actionable recommendations for harmonizing and optimizing your IMS.",heading:"Comprehensive Reporting"
               }
          ]} />
          {/* <AuditCard img={hseAudit5} title='Audit Innovation' auditName='IMS Audit' />
                <AuditCard img={hseAudit3} title='Audit Innovation' auditName='IMS Audit' />
                <AuditCard img={hseAudit4} title='Audit Innovation' auditName='IMS Audit' /> */}
        </div>
        <div className='auditing-main-right-section'>
                <AuditRightSectionCard
                 mainheading={"Why Vidura International for IMS Audits?"} 
                 content={[
                    {text:"Our experienced auditors conduct thorough assessments, examining the integration of your ISO 9001:2015 Quality Management System (QMS), ISO 1400:2015 Environmental Management System (EMS), and ISO 45001:2018 Occupational Health and Safety Management System (OHSMS).", heading:"Comprehensive Approach"},
                    {text:"Ensure seamless integration of your management systems, identifying synergies and improving overall efficiency.",heading:"Harmonization"},
                    {text:"Our IMS audits are tailored to your industry, ensuring alignment with ISO standards, regulatory requirements, and organizational goals.", heading:"Customized Solutions"},
                    {text:"Receive recommendations for continual improvement, enhancing your overall management system performance",heading:"Continuous Improvement"}
                 ]} />
            </div>
      </div>
      {/* <div className="audit-show-when-visible">
        <div className={`dynamic-audit-content ${isVisible ? "visible" : ""}`}>
          <h4>Reduce Audit Burden</h4>
          <p>
            Achieving a higher quality, more insight audit shouldn't take more
            effort from you. Through focus, flexibility, and and advanced audit
            technology, Vidura delivers more relevant insights , all while
            reducing the burden on your team
          </p>
          <p className="discover-more">
            <div className="discover-more-about-audit-btn">+</div>
            Discover the benifit of a more focused audit
          </p>
        </div>
        <div className={`dynamic-audit-content ${isVisible ? "visible" : ""}`}>
          <h4>Deeper Insight</h4>
          <p>
            Insight come in many shapes and sizes. Vidura produces insights that
            are tailored for your specific business and the industry it operates
            in. This focused approach enhances audit quality and improves your
            overall experience
          </p>
          <p className="discover-more">
            <div className="discover-more-about-audit-btn">+</div>
            Discover the benifit of a more focused audit
          </p>
        </div>
        <div className={`dynamic-audit-content ${isVisible ? "visible" : ""}`}>
          <h4>A Smarter Audit</h4>
          <p>
            Driving value through a high-quality audit is not only what we do,
            it's in our DNA. Our diverse and highly skilled teams are constantly
            analyzing changes in your risk profile to focus on the risks facing
            your business
          </p>

          <p className="discover-more">
            <div className="discover-more-about-audit-btn">+</div>
            Discover the benifit of a more focused audit
          </p>
        </div>
      </div> */}
    </section>
  );
};

export default IMSAudit;
