import React, {useState, useEffect} from 'react'
import nurseimg from "../../constrain/Images/nurseimg.jpg"
import Staffdeployment from './Staffdeployment';
import "./Staffandnurse.css";
import AuditRightSectionCard from '../AuditingAndConsulting/AuditRightSectionCard';
import Auditcard1 from '../AuditingAndConsulting/Auditcard1';
import AuditCard from '../AuditingAndConsulting/AuditCard';


function Staffandnurse() {
  const [isVisible, setIsVisible] = useState(false);
  const handleVisibilityChange = () => {
    const element = document.querySelector(".audit-show-when-visible");
    if (element) {
      const rect = element.getBoundingClientRect();
      const elementIsVisible =
        rect.top < window.innerHeight && rect.bottom >= 0;
      setIsVisible(elementIsVisible);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleVisibilityChange);
    return () => {
      window.removeEventListener("scroll", handleVisibilityChange);
    };
  }, []);

  return (
    // <>

    //   {/* <div className="Staffcontainer">
    //     <div>
    //       <div className="Staff-img">
    //         <img src={nurseimg} alt="" />
    //       </div>
    //       <div className="Staffcontent">
    //         <h1>Medical/Nursing Staff Deployment </h1>
    //       </div>
    //     </div>
    //   </div>

    //   <Staffdeployment/>
    //   <Staffcard/>
    //   <Manpowercard/>
    //   <Staffdeployment/>
    //   <Nursecard/> */}

     

      
    // </>

    <section className="audit-page">
      <div className="auditing-and-consulting-main">
        <div className="auditing-main-left-section">
          <AuditCard
            img=""
            title="Audit Innovation"
            auditName="Providing Healthcare Excellence Through Strategic Staff Deployment"
            para={[
              {
                text: "At Vidura International, we understand the critical role that skilled medical and nursing staff play in delivering quality healthcare services. Our Medical/Nursing Staff Deployment services are crafted to meet the dynamic staffing needs of healthcare organizations, ensuring they have a competent and compassionate team to deliver exceptional patient care.",
              },
            ]}
          />

          <Auditcard1 
          mainheading={"Key Components of Medical/Nursing Staff Deployment Services"} 
          img="" 
          para={[
            {
                text:"Collaboratively plan and assess your medical and nursing staffing needs to ensure optimal coverage and efficiency.",heading:"Strategic Staff Planning"
               },
               {text:"Source, screen, and recruit qualified medical and nursing professionals, facilitating a seamless onboarding process.",heading:"Recruitment and Onboarding"},
               {text:"Ensure that deployed staff possess the necessary certifications and comply with healthcare regulations.",heading:"Certification and Compliance "},{
                text:"Implement ongoing training programs to enhance the skills and knowledge of medical and nursing staff.",heading:"Continuous Training and Development"
               }
          ]} />
         
        </div>
        <div className='auditing-main-right-section'>
                <AuditRightSectionCard
                 mainheading={"Why Choose Vidura International for Medical/Nursing Staff Deployment"} 
                 content={[
                    {text:"Benefit from our in-depth understanding of the healthcare sector and its staffing requirements for Industries (construction, process and others)", heading:"Healthcare Industry Expertise"},
                    {text:"Utilize our thorough recruitment process to source, assess, and deploy qualified medical and nursing professionals.",heading:"Comprehensive Recruitment Process"},
                    {text:"Enjoy flexibility in staffing to accommodate peak periods, special projects, or to cover unexpected absences.", heading:"Flexible Staffing Solutions"},
                    {text:"Ensure the deployment of skilled and certified medical and nursing staff who meet industry standards.",heading:"Quality Assurance"}
                 ]} />
            </div>
      </div>
     
    </section>
  )
}

export default Staffandnurse
