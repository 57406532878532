import React from "react";
import MainLayout from "../../component/Layout/MainLayout";
import Footer from "../../component/Footer/Footer";
import KnowMore from "../../component/Services/KnowMore";
import EsgAdvisoryCard from "../../component/Advisory/EsgAdvisoryCard";
import EsgStripDataCard from "../../component/ESGcomponent/EsgStripData";
import img1 from "../../constrain/Images/Morgan-Advanced-Materials-bg.jpg";
import img2 from "../../constrain/Images/foundation-in-health-safety-IGC-1.jpg";
import img3 from "../../constrain/Images/group-people-working-out-business-plan-office.jpg";
import img4 from "../../constrain/Images/health-safety-management-systems-plan-IGC-2.jpg";
import img5 from "../../constrain/Images/work-equipment-hazardous.jpg";
import img6 from "../../constrain/Images/green-overlay.png";

import img7 from "../../constrain/Images/consultancy3.jpg";
import img8 from "../../constrain/Images/healthandsafety.jpg";
import img9 from "../../constrain/Images/slide_2.jpg";
import img10 from "../../constrain/Images/world-map-and-produce.jpg";
import EsgManagemntHeroSection from "../../component/ESGcomponent/ESGManagementHeroSection";
import { Box, Grid, styled } from "@mui/material";
import Paper from "@mui/material/Paper";
import image from "../../constrain/Images/VIDURA_images/VIDURA_images/Advisory On Framework Development.png"
import MainPage from "./MainPage";
interface EsgAdvisoryDataItem {
  id: number;
  img: string;
  title: string;
  desc: string;
  link: string;
  btn: string;
  download: string;
}
interface EsgStripDataItem {
  id: number;
  img1: string;
  img2: string;
  title: string;
  desc: string;
  link: string;
  btn: string;
  download: string;
}
const EsgAdvisoryData: EsgAdvisoryDataItem[] = [
  {
    id: 1,
    img: img1,
    title: "ESG Advisory",
    desc: "Join our most awaited and self-paced course for NDA and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "ndabrochure.pdf",
  },
  {
    id: 2,
    img: img2,
    title: "Development advisory",
    desc: "Join our most awaited and self-paced course for CDS and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "cds.pdf",
  },
  {
    id: 3,
    img: img3,
    title: "HSE advisory for changing the culture",
    desc: "Join our most awaited and self-paced course for AFCAT and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "afcat.pdf",
  },
  {
    id: 4,
    img: img4,
    title: "Development of procedures/process",
    desc: "Join our most awaited and self-paced course for AFCAT and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "ota.pdf",
  },
  {
    id: 5,
    img: img5,
    title: "HSE advisory for changing the culture",
    desc: "Join our most awaited and self-paced course for AFCAT and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "afcat.pdf",
  },
  {
    id: 6,
    img: img6,
    title: "Development of procedures/process",
    desc: "Join our most awaited and self-paced course for AFCAT and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "ota.pdf",
  },
];

const EsgStripData: EsgStripDataItem[] = [
  {
    id: 1,
    img1: img7,
    img2: img8,
    title: "ESG Advisory",
    desc: "Join our most awaited and self-paced course for NDA and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "ndabrochure.pdf",
  },
  {
    id: 2,
    img1: img9,
    img2: img10,
    title: "Development advisory",
    desc: "Join our most awaited and self-paced course for CDS and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "cds.pdf",
  },
];

const EsgAdvisory: React.FC = () => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  return (
    <MainLayout>
      <KnowMore heading="Advisory On Framework Development" />
      {/* <div className="esg-section">
        <h3>Designed To Achieve Your Safety Training Goals</h3>
      </div> */}
      {/* <main className='main-esg-content'>
        {EsgStripData.map((item, ind) => (
          <EsgStripDataCard
            key={item.id}
            id={item.id}
            img1={item.img1}
            img2={item.img2}
            title={item.title}
            desc={item.desc}
            link={item.link}
            btn={item.btn}
          />
        ))}

      </main> */}
      {/* <EsgManagemntHeroSection/> */}
      {/* <div className='esgAdvisory-section'>
        {EsgAdvisoryData.map((esdAdvisory) => (
          <EsgAdvisoryCard
            key={esdAdvisory.id}
            id={esdAdvisory.id}
            img={esdAdvisory.img}
            title={esdAdvisory.title}
            desc={esdAdvisory.desc}
            link={esdAdvisory.link}
            btn={esdAdvisory.btn}
          />
        ))}
      </div> */}
      
      <MainPage 
      heading="Advisory On Framework Development"
      content  = {[{
            lione:"Embark on a journey towards sustainable practices with Vidura International's Advisory on Framework Development. Our seasoned experts provide strategic guidance to organizations aspiring for sustainability excellence.",
            litwo:"Aligned with global reporting standards such as GRI, IFC and SEBI and other guidelines, our advisory services ensure that your frameworks meet the highest transparency and credibility benchmarks. We specialize in crafting robust frameworks for environmental, social, and governance (ESG) initiatives tailored to your organization's unique needs." ,
            li3:"Our advisory services encompass comprehensive ESG management planning, including impactful impact studies that delve into the environmental and social ramifications of your activities. We streamline your reporting processes for ESG metrics, covering GHG accounting, water and waste foot printing, and more.",
            li4:"At Vidura International, we go beyond conventional advisory services. Our commitment extends to offering green building services, integrating sustainable practices into your infrastructure projects.",
            li5:"Why choose Vidura International? Our seasoned experts bring a wealth of experience to guide you through every step of framework development. We offer tailored solutions, recognizing and addressing the unique needs and goals of your organization.",
            li6:"Elevate your sustainability journey with Vidura International's Advisory on Framework Development. Contact us to initiate a collaborative discussion on enhancing your organization's sustainability initiatives."
          }
        ]}
        image={image}
     />
      <Footer />
    </MainLayout>
  );
};

export default EsgAdvisory;









{/* <KnowMore heading="" />
<MainPage
  heading=""
  content={[
    {
      lione:
        "",
      litwo:
        "",
      li3: "",
      li4: "",
      li5: "",
      li6: "",
    },
  ]}
  image={}
/> */}
