import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import cardImg from '../../constrain/Images/bannerImg.svg'

interface ContentCardProps {
  img: string;
  title:string;
  des:string
}

const ContentCard: React.FC<ContentCardProps> = ({ img,title,des }) => {

  return (
    <Card>
      <CardMedia
        sx={{ height: 140 }}
        image={img}
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {des}
        </Typography>
      </CardContent>
      <CardActions>
        <Button sx={{color:"#86BC25"}} size="small">Share</Button>
        <Button sx={{color:"#86BC25"}} size="small">Learn More</Button>
      </CardActions>
    </Card>
  );
}


export default ContentCard