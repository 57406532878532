import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import './developmentAdvisoryStyle.css'

interface DevelopmentAdvisoryBannerProps {

}

const DevelopmentAdvisoryBanner: FunctionComponent<DevelopmentAdvisoryBannerProps> = () => {

    const handleDownload = () => {
        console.log("download");
    };

    return (
        <div className='Advelopment-advisory-header'>
            <h3 className='development-ad-heading'>Development Advisory</h3>
            <p className='development-para'>Our Development Advisory program is carefully crafted to provide you with the knowledge, skills, and guidance you need to excel in your chosen field. Whether you're preparing for the CDS (Combined Defence Services) examination or looking to enhance your career prospects, our program is tailored to meet your specific needs.</p>
            <div className='development-bottom-menu'>
                <button><Link to={"/esg advisory"}>ESG Advisory {">"} </Link></button>
                <button><Link to={"/hse advisory"}>HSE advisory for changing the culture {">"}</Link></button>
                <button><Link to={"/Development of procedures/process"}>Development of procedures/process {">"}</Link></button>
            </div>
        </div>
    );
};

export default DevelopmentAdvisoryBanner;
