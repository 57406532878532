import React, { Fragment } from 'react'
import Industries from '../component/Industries/Industries'
import Footer from '../component/Footer/Footer'

const IndustriesPage = () => {
    return (
        <Fragment>
            <Industries />
            <Footer />
        </Fragment>
    )
}

export default IndustriesPage