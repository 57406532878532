import React from 'react'
import styles from './TeamCard.module.scss'
import { Box, Grid, Typography } from '@mui/material'
import m1 from "../../../constrain/Images/2.jpg";
export interface ITeamCard {
    img: string;
    name: string;
    description: string;
    experience: string;
    content: string;
}
const TeamCard = ({ img, name, description, experience, content }: ITeamCard) => {
    return (
        <Grid className={styles.teamCardContainer}>
            <Box>
                <img src={img} alt='img' />
            </Box>
            <Box>
                <Typography textAlign={"center"} variant='h4' fontWeight={600} fontSize={25}>{name}</Typography>
                <Typography textAlign={"center"} variant='h6' fontWeight={500} fontSize={20}>
                    {description}
                </Typography>
                <Typography textAlign={"center"} variant='h6' fontWeight={500} fontSize={20} sx={{ paddingBlock: 2 }}>
                    <span style={{ fontWeight: 600 }}>Experience:</span> {experience}
                </Typography>
                <Typography textAlign={"center"} variant='h6' fontWeight={500} fontSize={20}>
                    {content}
                </Typography>
            </Box>
        </Grid>
    )
}

export default TeamCard