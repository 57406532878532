import React, { FunctionComponent } from 'react';
import './headerStyle.css'
import StayConnected from '../StayConnected';

interface KnowMoreProps {
    headerImage: string;
    title:string;
    //   id: number;
    //   img: string;
    //   title: string;
    //   desc: string;
    //   link: string;
    //   btn: string;
}

const ESGManagementHeader: FunctionComponent<KnowMoreProps> = ({ headerImage,title }) => {
    return (
        <>
            <div className="esg-management-header-section" style={{ backgroundImage: `url(${headerImage})` }}>
                <div className='esg-header-details'>
                    <h3 className='esg-title'>{title}</h3>
                </div>
            </div>
            <StayConnected/>
        </>

    );
};

export default ESGManagementHeader;
