import React from "react";
import MainLayout from "../component/Layout/MainLayout";
import Lwsystem from "../component/Learningmanagement/Lwsystem";
import Footer from "../component/Footer/Footer";
import Commondigital from "../component/Drone/Commondigital";
import MainDigital from "../component/Drone/MainDigital";
import imgg from "../constrain/Images/MyLearning-image-2x.png"

function LearningsystemPage() {
  return (
    <MainLayout>
      {/* <Lwsystem /> */}
       <Commondigital heading={""} />
      <MainDigital
        heading={"Learning management system"}
        content={[
          {
            lione: "Vidura International presents a state-of-the-art Learning Management System (LMS), designed to enhance the educational experience and skill development of professionals in the field of Health, Safety, and Environment (HSE). Our LMS is a comprehensive platform that facilitates the creation, management, and delivery of training programs, ensuring continuous learning and compliance with industry standards.",
            litwo: "The LMS is equipped with user-friendly features, including interactive courses, assessments, and progress tracking, providing a seamless learning journey for individuals and organizations. It covers a wide array of safety modules, regulatory compliance training, and skill development programs tailored to various industries.",
            li3: "Key benefits of Vidura International's LMS include accessibility, flexibility, and scalability, allowing organizations to deliver training anytime, anywhere. The platform supports a variety of multimedia formats, enabling engaging and effective learning experiences. With real-time monitoring and reporting capabilities, employers can track the progress of their workforce, ensuring that they stay up-to-date with the latest safety practices.",
            li4: "Incorporate Vidura International's Learning Management System into your training strategy to foster a culture of continuous improvement and competence development within your workforce.",
            li5: "",
            li6: "",
          },
        ]}
        image={imgg}
        text={"Incorporate Vidura International's Learning Management System into your training strategy to foster a culture of continuous improvement and competence development within your workforce."}
      />
      <Footer />
    </MainLayout>
  );
}

export default LearningsystemPage;
