import React from 'react';
import { AiOutlineCloudUpload, AiFillControl, AiOutlineArrowRight } from 'react-icons/ai';
import { CgTrack } from 'react-icons/cg';
import {BiLogoDiscourse} from 'react-icons/bi';
import './FoodSafetyStyle.css'





const FoodSafetyRules: React.FC = () => {
    return (
        <>
            <section className='foodSafety-main-section'>
                <div className='food-safety-rules'>
                    <h1>Food Safety Rules  </h1>
                    <p>Food safety rules are essential to ensure the safety and well-being of consumers</p>
                    <p>They are put in place to prevent foodborne illnesses, contamination, and other health risks associated with the consumption of food. Here is the content that should be included in food safety rules:</p>
                </div>

                <div className='food-safety-rules-list'>
                    <ul>
                        <li><AiOutlineCloudUpload style={{ fontSize: '24px', color: 'green' }} />
                        Personal Hygiene:
                        <p>Handwashing: Proper handwashing techniques should be emphasized for all food handlers.</p>
                        <p>Clothing: Food handlers should wear clean and appropriate clothing, including hairnets, if necessary.</p>
                        <p>Illness: Food handlers should not work when they are sick or have symptoms of illness.</p>
                        </li><br />
                        <li><AiFillControl style={{ fontSize: '24px', color: 'green' }} /> 
                        Food Storage:
                        <p>Temperature Control: Guidelines for storing food at the correct temperatures to prevent bacterial growth.</p>
                        <p>Separation: Rules for keeping raw and cooked foods separate to avoid cross-contamination</p>
                        <p>Labeling: Properly labeling food items with date and contents to ensure freshness and easy identification.</p>
                        </li><br />
                        <li><CgTrack style={{ fontSize: '24px', color: 'green' }} />
                        Cleaning and Sanitizing:
                        <p>Cleaning Procedures: Clear instructions on how to clean surfaces and equipment.</p>
                        <p>Sanitizing: Proper sanitizing methods to kill harmful microorganisms on kitchen tools and surfaces.</p>
                        
                        </li><br />
                        <li> <BiLogoDiscourse style={{ fontSize: '24px', color: 'green' }} /> 
                        Food Preparation:
                        <p>Cross-Contamination: Measures to prevent the transfer of bacteria from one food to another.</p>
                        <p>Cooking Temperatures: Guidelines for cooking different types of food to their recommended safe internal temperatures.</p>
                        <p>Thawing: Safe methods for thawing frozen food, such as in the refrigerator.</p>
                        </li>
                    </ul>
                </div>
            </section>
            <div className='bottom-strip'></div>
        </>
    );
};

export default FoodSafetyRules;
