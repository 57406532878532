import React, { Fragment } from 'react'
import MainLayout from '../component/Layout/MainLayout'
import Footer from '../component/Footer/Footer'
import AboutBanner from '../component/AboutBanner/AboutBanner'
import Banner from '../component/AboutBanner/Banner/Banner'
import bgImage from '../constrain/Images/about_banner_img.jpg'

const AboutPage = () => {
  return (
    <MainLayout>
      <Fragment>
        <Banner
          img={bgImage}
          heading={"About Us"}
          description={"VIDURA is the firm with a Versatile team of HSE professionals working to Integrate all aspects of Occupational Health, Safety and Environment Dynamics for clients willing to design or refurbish the management system to make it Unwavering and Righteous in nature Aligned with business commitments."}
          isAction={true}
        />
        <AboutBanner />
        <Footer />
      </Fragment>
    </MainLayout>

  )
}

export default AboutPage
