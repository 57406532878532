import React, { FunctionComponent } from 'react';
import AuditingHeader from '../../component/AuditingAndConsulting/AuditingAndConsultingHeader';
import MainLayout from '../../component/Layout/MainLayout';
import Footer from '../../component/Footer/Footer';
import ProcessSafetyAudit from '../../component/AuditingAndConsulting/ProcessSafetyAudit';
interface ProcessSafetyAuditingProps {

}

const ProcessSafetyAuditing: FunctionComponent<ProcessSafetyAuditingProps> = () => {

    const handleDownload = () => {
        console.log("download");
    };

    return (
        <div className='auditing-and-consulting'>
            <MainLayout>
                <AuditingHeader heading={'Process Safety Audit'} />
                <ProcessSafetyAudit/>
                <Footer/>
            </MainLayout>
        </div>
    );
};

export default ProcessSafetyAuditing;
