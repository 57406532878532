import React, { useEffect } from 'react';
import "./AnimateContentCard.css";
import CancelIcon from '@mui/icons-material/Cancel';
import pdf from "../../constrain/Images/pdfIcon.png";

const AnimateContentCard: React.FC = () => {
  const closeViduraPopup = () => {
    const viduraPopup = document.getElementById("viduraPopup");
    if (viduraPopup) {
        viduraPopup.style.display = "none";
    }
  }

  const openViduraPopup = (title: string) => {
    const viduraPopup = document.getElementById("viduraPopup");
    if (viduraPopup) {
        viduraPopup.style.display = "flex";
    }

    console.log(title)
    switch (title) {
     
      case 'vidura':
        showNotesLinks(['viduraLink1', 'viduraLink2', 'viduraLink3', 'viduraLink4']);
        hideNotesLinks(['ndaNotesLink1', 'ndaNotesLink2', 'ndaNotesLink3', 'ndaNotesLink4', 'afcatNotesLink1', 'afcatNotesLink2', 'afcatNotesLink3', 'afcatNotesLink4']);
        break;
    
      default:
        break;
    }
  }

  const showNotesLinks = (links: string[]) => {
    links.forEach(linkId => {
      console.log(linkId)

      const link = document.getElementById(linkId);
      if (link) {
        link.style.display = "flex";
      }
    });
  }

  const hideNotesLinks = (links: string[]) => {
    links.forEach(linkId => {
      const link = document.getElementById(linkId);
      if (link) {
        link.style.display = "none";
      }
    });
  }

  return (
    <div className='study-material-section'>
      <div className="study-material-popup" id='viduraPopup'>
        <div className="study-material-popup-box">
          <CancelIcon id="studyMaterialCloseIcon" onClick={closeViduraPopup} />
          <div className="study-material-pdf-section">
            <a href="/" download="true" id='viduraLink1'>
              <div className="study-material-pdf-card">
                <img src={pdf} alt="" />
                <p style={{fontSize:"1rem"}}>Vidura Brocheure</p>
              </div>
            </a>
            {/* Repeat the similar structure for other links */}
          </div>
          <div className="study-material-popup-buttons">
            <button className="btn-a-solid">Register Now</button>
            <button className="btn-a-solid">View All Notes</button>
          </div>
        </div>
      </div>
      <div className="study-material-section-middle">
        <div className="study-material-clickable-card" id='study-material-clickable-card-2' onClick={() => openViduraPopup('vidura')}></div>
      </div>
      <div className="study-material-section-top">
        <h3><span>Vidura</span> Consultancy</h3>
      </div>
      <div className="study-material-section-bottom">
        <a href="gallery" className="btn-a-solid">All About Vidura Consultancy</a>
      </div>
    </div>
  )
}

export default AnimateContentCard;
