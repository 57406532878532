import React, { Fragment } from "react";
import MainLayout from "../component/Layout/MainLayout";
import Footer from "../component/Footer/Footer";
import Carrer from "../component/Carrer/Carrer";

function CarrerPage() {
  return (
    // <Fragment>
       <MainLayout>
           <Carrer />
        <Footer/>
      </MainLayout> 
   
    // </Fragment>
  );
}

export default CarrerPage;
