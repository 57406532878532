import React from 'react'
import styles from './ShotBlog.module.scss'
import { Grid, Typography } from '@mui/material'
import EastIcon from '@mui/icons-material/East';

export interface IShotBlog {
    image: string;
    heading: string;
    content: string;
}
const ShotBlog = ({ image, heading, content }: IShotBlog) => {
    return (
        <Grid container className={styles.fetureEvent}>
            <Grid item md={6}>
                <img src={image} alt='img' />
            </Grid>
            <Grid item md={6}>
                <Typography variant='h2' fontWeight={600} fontSize={45}>{heading}</Typography>
                <Typography>{content}</Typography>
                <Typography>Learn more<EastIcon /></Typography>
            </Grid>
        </Grid>
    )
}

export default ShotBlog