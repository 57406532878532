import React from 'react'
import MainLayout from '../component/Layout/MainLayout'
import ServiceCard from '../component/Services/ServiceCard'
import serviceImg from '../constrain/Images/featured11.webp'
import './pageStyle.css'
import AdvisoryCard from '../component/Services/AdvisoryCard'
import ESGAdvisory from '../constrain/Images/esg-advisory.jpg'
import DevelopmentAdvisory from '../constrain/Images/real-Estate-development-advisory.jpg'
import HSEAdvisory from '../constrain/Images/hse-advisory.jpg'
import DevelopProcedure from '../constrain/Images/as-is-process.png'
import ServiceSectorHeader from '../component/Services/SectorAndService'
import Footer from '../component/Footer/Footer'


const advisoryData = [
  {
    id: 1, img: ESGAdvisory, title: "Advisory On Development of HSE & ESG Management Systems", desc: "Joint our most awaited and self paced course for NDA and prepare yourself for the academy",
    link: "More Details", btn: "Download Brochure", download: "ndabrochure.pdf"
  },
  {
    id: 2, img: DevelopmentAdvisory, title: "HSE & ESG Management System Reporting", desc: "Join our most awaited and self paced course for CDS and prepare yourself for the academy",
    link: "More Details", btn: "Download Brochure", download: "cds.pdf"
  },
  {
    id: 3, img: HSEAdvisory, title: "HSE & ESG Auditing", desc: "Join our most awaited and self paced course for AFCAT and prepare yourself for the academy",
    link: "More Details", btn: "Download Brochure", download: "afcat.pdf"
  },
  {
    id: 4, img: DevelopProcedure, title: "Competence Development / Trainings", desc: "Join our most awaited and self paced course for AFCAT and prepare yourself for the academy",
    link: "More Details", btn: "Download Brochure", download: "ota.pdf"
  },
  {
    id: 5, img: DevelopmentAdvisory, title: "HSE & ESG Culture Development / Behaviour Based Training", desc: "Join our most awaited and self paced course for CDS and prepare yourself for the academy",
    link: "More Details", btn: "Download Brochure", download: "cds.pdf"
  },
  {
    id: 6, img: HSEAdvisory, title: "Handholding For HSE & ESG Excellence Awards Like BSC- FSA, BSC-ISA, NSCI Awards, CSDCI Awards, Golden Peacock Etc.", desc: "Join our most awaited and self paced course for AFCAT and prepare yourself for the academy",
    link: "More Details", btn: "Download Brochure", download: "afcat.pdf"
  },
  {
    id: 7, img: DevelopProcedure, title: "HSE & ESG Softwares – PTW, Incident Reporting Etc.", desc: "Join our most awaited and self paced course for AFCAT and prepare yourself for the academy",
    link: "More Details", btn: "Download Brochure", download: "ota.pdf"
  },
  {
    id: 8, img: DevelopmentAdvisory, title: "HSE & ESG Videos / Animations – Training and Incident Learning", desc: "Join our most awaited and self paced course for CDS and prepare yourself for the academy",
    link: "More Details", btn: "Download Brochure", download: "cds.pdf"
  },
  {
    id: 9, img: HSEAdvisory, title: "Third Party Inspections by Competent Persons", desc: "Join our most awaited and self paced course for AFCAT and prepare yourself for the academy",
    link: "More Details", btn: "Download Brochure", download: "afcat.pdf"
  },
  {
    id: 10, img: DevelopProcedure, title: "Environment Monitoring", desc: "Join our most awaited and self paced course for AFCAT and prepare yourself for the academy",
    link: "More Details", btn: "Download Brochure", download: "ota.pdf"
  },
  {
    id: 11, img: DevelopmentAdvisory, title: "Guidance On Legal Compliances", desc: "Join our most awaited and self paced course for CDS and prepare yourself for the academy",
    link: "More Details", btn: "Download Brochure", download: "cds.pdf"
  }
]

const HSEPage = () => {
  return (
    <MainLayout>
      <ServiceSectorHeader serviceName="HSE & ESG Sector"/>
      <div className="advisory-section" style={{backgroundColor:"#86bc25"}}>
        {advisoryData.map((advisory) => (
          <AdvisoryCard
            key={advisory.id}
            id={advisory.id}
            img={advisory.img}
            title={advisory.title}
            desc={advisory.desc}
            link={advisory.link}
            btn={advisory.btn}
          />
        ))}
      </div>
      <Footer/>
    </MainLayout>
  )
}

export default HSEPage
