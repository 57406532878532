import React from 'react'
import styles from './IndustriesCard.module.scss'
import { Card, CardContent, Grid, Typography } from '@mui/material'

export interface IIndustriesCard {
    heading: string;
    content: string;
}
const IndustriesCard = ({ heading, content }: IIndustriesCard) => {
    return (
        <Grid className={styles.industriesCardContainer}>
            <Card>
                <CardContent className={styles.industriesCard}>
                    <Typography variant='h4' fontWeight={600} fontSize={24}>{heading}</Typography>
                    <Typography>{content}</Typography>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default IndustriesCard