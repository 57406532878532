import React, { createContext, useContext, useState } from 'react';

const ShareDetailsContext = createContext();

export function useShareDetails() {
  return useContext(ShareDetailsContext);
}

export function ShareDetailsProvider({ children }) {
  const [isShareDetailsOpen, setShareDetailsOpen] = useState(false);

  const openShareDetails = () => {
    setShareDetailsOpen(!isShareDetailsOpen);
  };

  return (
    <ShareDetailsContext.Provider value={{ isShareDetailsOpen, openShareDetails,setShareDetailsOpen }}>
      {children}
    </ShareDetailsContext.Provider>
  );
}
