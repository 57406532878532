import React from 'react';
import MainLayout from '../../component/Layout/MainLayout';
import Footer from '../../component/Footer/Footer';
import EsgStripDataCard from '../../component/ESGcomponent/EsgStripData';
import EsgAdvisoryCard from '../../component/Advisory/EsgAdvisoryCard';
import img1 from '../../constrain/Images/Morgan-Advanced-Materials-bg.jpg';
import img2 from '../../constrain/Images/foundation-in-health-safety-IGC-1.jpg';
import img3 from '../../constrain/Images/group-people-working-out-business-plan-office.jpg';
import img4 from '../../constrain/Images/health-safety-management-systems-plan-IGC-2.jpg';
import img5 from '../../constrain/Images/work-equipment-hazardous.jpg';
import img6 from '../../constrain/Images/FeaturedImage-5.webp';
import img7 from '../../constrain/Images/world-map-and-produce.jpg';
import img8 from '../../constrain/Images/as-is-process.png';
import img9 from '../../constrain/Images/close-up-people-working-with-laptop.jpg';
import img from "../../constrain/Images/VIDURA_images/VIDURA_images/Hse Auditing.jpg"
import headerImage from '../../constrain/Images/ESG-reporting.webp';
import EsgManagemntHeroSection from '../../component/ESGcomponent/ESGManagementHeroSection';
import ESGManagementHeader from '../../component/Services/Header/ESGManagementHeader';
import KnowMore from '../../component/Services/KnowMore';
import MainPage from './MainPage';

interface EsgAdvisoryDataItem {
  id: number;
  img: string;
  title: string;
  desc: string;
  link: string;
  btn: string;
  download: string;
}

interface EsgStripDataItem {
  id: number;
  img1: string;
  img2: string;
  title: string;
  desc: string;
  link: string;
  btn: string;
  download: string;
}

const EsgAdvisoryData: EsgAdvisoryDataItem[] = [
  {
    id: 1,
    img: img1,
    title: "ESG Advisory",
    desc: "Join our most awaited and self-paced course for NDA and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "ndabrochure.pdf",
  },
  {
    id: 2,
    img: img2,
    title: "Development advisory",
    desc: "Join our most awaited and self-paced course for CDS and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "cds.pdf",
  },
  {
    id: 3,
    img: img3,
    title: "HSE advisory for changing the culture",
    desc: "Join our most awaited and self-paced course for AFCAT and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "afcat.pdf",
  },
  {
    id: 4,
    img: img4,
    title: "Development of procedures/process",
    desc: "Join our most awaited and self-paced course for AFCAT and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "ota.pdf",
  },
  {
    id: 5,
    img: img5,
    title: "HSE advisory for changing the culture",
    desc: "Join our most awaited and self-paced course for AFCAT and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "afcat.pdf",
  },
  {
    id: 6,
    img: img6,
    title: "Development of procedures/process",
    desc: "Join our most awaited and self-paced course for AFCAT and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "ota.pdf",
  },
];
const EsgStripData: EsgStripDataItem[] = [
  {
    id: 1,
    img1: img7,
    img2: img8,
    title: "ESG Advisory",
    desc: "Join our most awaited and self-paced course for NDA and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "ndabrochure.pdf",
  },
  {
    id: 2,
    img1: img9,
    img2: img6,
    title: "Development advisory",
    desc: "Join our most awaited and self-paced course for CDS and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "cds.pdf",
  },
];
const HSEAuditingPage: React.FC = () => {
  return (
    <MainLayout>
      {/* <ESGManagementHeader headerImage={headerImage} title="Auditing" />
      <div className='esg-section'>
        <h3>Designed To Achieve Your Safety Training Goals</h3>
      </div>
      <main className='main-esg-content'>
        {EsgStripData.map((item, ind) => (
          <EsgStripDataCard
            key={item.id}
            id={item.id}
            img1={item.img1}
            img2={item.img2}
            title={item.title}
            desc={item.desc}
            link={item.link}
            btn={item.btn}
          />
        ))}

      </main>
      <EsgManagemntHeroSection/>
      <div className='esgAdvisory-section'>
        {EsgAdvisoryData.map((esdAdvisory) => (
          <EsgAdvisoryCard
            key={esdAdvisory.id}
            id={esdAdvisory.id}
            img={esdAdvisory.img}
            title={esdAdvisory.title}
            desc={esdAdvisory.desc}
            link={esdAdvisory.link}
            btn={esdAdvisory.btn}
          />
        ))}
      </div> */}

<KnowMore heading="" />
<MainPage
  heading="HSE & ESG Auditing"
  content={[
    {
      lione:
        "Vidura International's HSE & ESG Auditing services are designed to be more than just a compliance check; they are a holistic assessment of your organization's Health, Safety, and Environment (HSE & ESG) practices. Our seasoned experts conduct meticulous audits that go beyond the surface, providing valuable insights to fortify your commitment to a safe and sustainable work environment.",
      litwo:
        "Collaboratively, we work with your team to understand the intricacies of your operations, identify potential risks, and ensure that your HSE & ESG practices align with the highest standards. Our approach focuses not only on meeting regulatory requirements but also on instilling a culture of continuous improvement within your organization.",
      li3: "Stay informed about the outcomes of your HSE & ESG audits with our comprehensive reporting mechanisms, including actionable recommendations for enhancement. Vidura International's commitment is to elevate your HSE & ESG practices, making them an integral part of your organizational DNA.",
      li4: "Choose Vidura International for HSE & ESG Auditing that transforms audits into opportunities for growth and excellence. Initiate a collaborative discussion to implement auditing strategies tailored to elevate your organization's health, safety, and environmental performance.",
      li5: "",
      li6: "",
    },
  ]}
  image={img}
/>

      <Footer />
    </MainLayout>
  );
};

export default HSEAuditingPage;
