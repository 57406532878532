import React from 'react';
import MainLayout from '../../component/Layout/MainLayout';
import Footer from '../../component/Footer/Footer';
import KnowMore from '../../component/Services/KnowMore';
import EsgAdvisoryCard from '../../component/Advisory/EsgAdvisoryCard';
import img1 from '../../constrain/Images/Morgan-Advanced-Materials-bg.jpg';
import img2 from '../../constrain/Images/foundation-in-health-safety-IGC-1.jpg';
import img3 from '../../constrain/Images/group-people-working-out-business-plan-office.jpg';
import img4 from '../../constrain/Images/health-safety-management-systems-plan-IGC-2.jpg';
import img5 from '../../constrain/Images/work-equipment-hazardous.jpg';
import img6 from '../../constrain/Images/green-overlay.png';
import headerImage from '../../constrain/Images/ESG-reporting.webp';
import image from "../../constrain/Images/VIDURA_images/VIDURA_images/Greenhouse Gas Accounting.jpg"
import ESGManagementHeader from '../../component/Services/Header/ESGManagementHeader';
import MainPage from '../HseMenuPage/MainPage';

interface EsgAdvisoryDataItem {
  id: number;
  img: string;
  title: string;
  desc: string;
  link: string;
  btn: string;
  download: string;
}

const EsgAdvisoryData: EsgAdvisoryDataItem[] = [
  {
    id: 1,
    img: img1,
    title: "ESG Advisory",
    desc: "Join our most awaited and self-paced course for NDA and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "ndabrochure.pdf",
  },
  {
    id: 2,
    img: img2,
    title: "Development advisory",
    desc: "Join our most awaited and self-paced course for CDS and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "cds.pdf",
  },
  {
    id: 3,
    img: img3,
    title: "HSE advisory for changing the culture",
    desc: "Join our most awaited and self-paced course for AFCAT and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "afcat.pdf",
  },
  {
    id: 4,
    img: img4,
    title: "Development of procedures/process",
    desc: "Join our most awaited and self-paced course for AFCAT and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "ota.pdf",
  },
  {
    id: 5,
    img: img5,
    title: "HSE advisory for changing the culture",
    desc: "Join our most awaited and self-paced course for AFCAT and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "afcat.pdf",
  },
  {
    id: 6,
    img: img6,
    title: "Development of procedures/process",
    desc: "Join our most awaited and self-paced course for AFCAT and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "ota.pdf",
  },
];

const GreenhouseGasAccounting: React.FC = () => {
  return (
    <MainLayout>
      {/* <ESGManagementHeader headerImage={headerImage} title="Greenhouse Gas Accounting" />
      <div className='esg-section'>
        <h3>Designed To Achieve Your Safety Training Goals</h3>
      </div>
      <div className='esgAdvisory-section'>
        {EsgAdvisoryData.map((esdAdvisory) => (
          <EsgAdvisoryCard
            key={esdAdvisory.id}
            id={esdAdvisory.id}
            img={esdAdvisory.img}
            title={esdAdvisory.title}
            desc={esdAdvisory.desc}
            link={esdAdvisory.link}
            btn={esdAdvisory.btn}
          />
        ))}
      </div> */}
      <KnowMore heading={''}/>
      <MainPage 
      heading={'Greenhouse Gas Accounting'} 
      content={[{
        lione:"Vidura International's Greenhouse Gas Accounting services offer a comprehensive approach to understanding and managing your organization's carbon footprint. In a world increasingly focused on environmental responsibility, our seasoned experts meticulously assess your greenhouse gas emissions, providing valuable insights beyond numerical data.",
        litwo:"Collaboratively, we develop strategies to reduce and offset your carbon footprint, aligning with global standards and best practices. Our goal is not just to measure emissions but to help you craft a meaningful narrative about your commitment to environmental sustainability." ,
        li3:"Stay informed about your progress with our monitoring and reporting mechanisms, complete with established key performance indicators (KPIs) for regular assessment and transparent reporting. Vidura International goes beyond compliance, striving for excellence in environmental responsibility.",
        li4:" Choose us to guide your organization toward a greener, more sustainable future. Initiate a collaborative discussion to implement Greenhouse Gas Accounting services tailored to elevate your commitment to environmental stewardship. ",
        li5:"",
        li6:""
      }]} 
      image={image}/>
      <Footer />
    </MainLayout>
  );
};

export default GreenhouseGasAccounting;
