import React from "react";
import MainLayout from "../component/Layout/MainLayout";
import Footer from "../component/Footer/Footer";
import Energymangements from "../component/Energy/Energymangements";
import Commondigital from "../component/Drone/Commondigital";
import MainDigital from "../component/Drone/MainDigital";
import imgg from "../constrain/Images/energy-management-system.png"

function EnergyManagement() {
  return (
    <MainLayout>
      <Commondigital heading={""} />
      <MainDigital
        heading={"Energy management"}
        content={[
          {
            lione: "Vidura International introduces comprehensive energy management services designed to optimize energy usage and enhance sustainability across diverse industries. Our tailored solutions focus on minimizing energy consumption, reducing costs, and mitigating environmental impact.",
            litwo: "Our team of experts conducts thorough energy audits to identify areas of improvement and implement efficient energy-saving strategies. We guide organizations in adopting renewable energy sources, implementing energy-efficient technologies, and establishing robust monitoring systems.",
            li3: "Vidura International is dedicated to helping businesses achieve their energy efficiency goals, contributing to a greener and more sustainable future. By integrating innovative technologies and best practices, we empower organizations to make informed decisions, reduce carbon footprints, and improve overall operational efficiency.",
            li4: "",
            li5: "",
            li6: "",
          },
        ]}
        image={imgg}
        text={"Explore the possibilities of effective energy management with Vidura International. Contact us to embark on a journey towards sustainable and responsible energy practices."}
      />
      <Footer />
    </MainLayout>
  );
}

export default EnergyManagement;
