import React from 'react';
import foodSafetyImg from '../../constrain/Images/food-safety.jpg'
import { AiFillCaretRight, AiFillCloud, AiFillEdit, AiFillMacCommand } from 'react-icons/ai';

import './FoodSafetyStyle.css'


const cardData = [
    {
        title: 'Food Safety Training',
        content:[{id:0,desc:"Mandatory Training: Ensuring all food handlers receive appropriate food safety training"},
        {id:1,desc:"Mandatory Training: Ensuring all food handlers receive appropriate food safety training"},
    ],
    },
    {
        title: 'Reporting and Record-Keeping',
        content:[{id:0,desc:"Reporting Illness: Procedures for food handlers to report illnesses to their supervisor"},
        {id:1,desc:"Incident Records: Keeping records of any incidents or food safety violations"},
    ],
    },
    {
        title: 'Personal Hygiene',
        content:[{id:0,desc:"Handwashing: Proper handwashing techniques should be emphasized for all food handlers."},
        {id:1,desc:"Clothing: Food handlers should wear clean and appropriate clothing, including hairnets, if necessary."},
    ],
    },
    {
        title: 'Food Storage',
        content:[{id:0,desc:"Labeling: Properly labeling food items with date and contents to ensure freshness and easy identification."},
        {id:1,desc:"Separation: Rules for keeping raw and cooked foods separate to avoid cross-contamination."},
    ],
    },
    {
        title: 'Food Preparation:',
        content:[{id:0,desc:"Cooking Temperatures: Guidelines for cooking different types of food to their recommended safe internal temperatures."},
        {id:1,desc:"Thawing: Safe methods for thawing frozen food, such as in the refrigerator"},
    ],
    },
    {
        title: 'Cleaning and Sanitizing:',
        content:[{id:0,desc:"Sanitizing: Proper sanitizing methods to kill harmful microorganisms on kitchen tools and surfaces."},
        {id:1,desc:"Cleaning Procedures: Clear instructions on how to clean surfaces and equipment."},
    ],
    },
    {
        title: 'Food Handling and Service',
        content:[{id:0,desc:"Serving Utensils: Encourage the use of utensils to handle food, especially for buffets and self-service."},
        {id:1,desc:"Use of gloves: When to use gloves and how to change them properly."},
    ],
    },
    {
        title: 'Allergen Contro',
        content:[{id:0,desc:"Identification: Proper labeling and knowledge of ingredients to prevent allergic reactions."},
        {id:1,desc:"Cross-Contamination: Procedures to avoid cross-contact with allergenic foods."},
    ],
    },
];


const FoodSafetyList: React.FC = () => {
    return (
        <>
            <section className='food-safety-list-section'>
                {cardData.map((item, ind) => (
                    <div className='food-safety-list-card' key={ind}>
                        <div>
                            <p className='food-safety-list-title'>{item.title}</p>
                            {item?.content.map((contentList)=>(
                                <p>{contentList.desc}</p>
                            ))}
                        </div>
                    </div>
                ))}
            </section>
        </>
    );
};

export default FoodSafetyList;
