import React, { FunctionComponent, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./auditingAndConsulting.css";
import AuditCard from "./AuditCard";
import hseAudit1 from "../../constrain/Images/What-is-HSE-Audit.jpg";
import hseAudit2 from "../../constrain/Images/What-is-HSE-Audit.jpg";
import card1img from "../../constrain/Images/Screenshot (230).png"
import card2img from "../../constrain/Images/Screenshot (233).png"
import AuditRightSectionCard from "./AuditRightSectionCard";
import Auditcard1 from "./Auditcard1";

interface ProcessSafetyAuditProps {}

const ProcessSafetyAudit: FunctionComponent<ProcessSafetyAuditProps> = () => {
  const [isVisible, setIsVisible] = useState(false);
  const handleVisibilityChange = () => {
    const element = document.querySelector(".audit-show-when-visible");
    if (element) {
      const rect = element.getBoundingClientRect();
      const elementIsVisible =
        rect.top < window.innerHeight && rect.bottom >= 0;
      setIsVisible(elementIsVisible);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleVisibilityChange);
    return () => {
      window.removeEventListener("scroll", handleVisibilityChange);
    };
  }, []);

  return (
    <section className="audit-page">
      <div className="auditing-and-consulting-main">
        <div className="auditing-main-left-section">
          <AuditCard
            img={card1img}
            title="Audit Innovation"
            auditName="Ensuring Operational Safety Excellence with Vidura International"
                para={[
                    {
                      text: "At Vidura International, we recognize the paramount importance of process safety in high-risk industries. Our Process Safety Audit Services are meticulously designed to assess, analyze, and enhance the safety integrity of your critical processes.",
                    },
                  
            ]}
          />

          <Auditcard1 
          mainheading={"Our Process Safety Audit Process:"} 
          img={card2img} 
          para={[{
            text:":: Understanding your organization's process safety goals, operational processes, and potential risks.",heading:"Needs Assessment"
           },
           {text:": Thorough examination of critical processes, identifying potential hazards and safety vulnerabilities.",heading:"On-Site Evaluation"},
           {text:"In-depth analysis of safety documentation, protocols, and compliance levels.",heading:"Data Analysis "},{
            text:": Receive a detailed report with actionable recommendations for enhancing process safety.",heading:"Comprehensive Reporting"
           }
        ]}/>
         
        </div>
        <div className='auditing-main-right-section'>
                <AuditRightSectionCard
                 mainheading={"Why Vidura International for Process Safety Audits?"}
                 content={[
                    {text:"Our experienced auditors conduct thorough evaluations, examining every aspect of your processes to identify potential safety hazards.", heading:"Comprehensive Safety Assessment"},
                    {text:"Receive actionable recommendations to mitigate identified risks and enhance the overall safety of your operations",heading:"Risk Mitigation Strategies"},
                    {text:"Ensure adherence to industry-specific regulations and standards, avoiding potential penalties and ensuring a safe working environment", heading:"Regulatory Compliance"},
                    {text:"Our process safety audits are tailored to your industry, ensuring alignment with relevant safety standards and best practices",heading:"Customized Solutions"}
                ]}
                 />
            </div>
      </div>
      {/* <div className="audit-show-when-visible">
        <div className={`dynamic-audit-content ${isVisible ? "visible" : ""}`}>
          <h4>Reduce Audit Burden</h4>
          <p>
            Achieving a higher quality, more insight audit shouldn't take more
            effort from you. Through focus, flexibility, and and advanced audit
            technology, Vidura delivers more relevant insights , all while
            reducing the burden on your team
          </p>
          <p className="discover-more">
            <div className="discover-more-about-audit-btn">+</div>
            Discover the benifit of a more focused audit
          </p>
        </div>
        <div className={`dynamic-audit-content ${isVisible ? "visible" : ""}`}>
          <h4>Deeper Insight</h4>
          <p>
            Insight come in many shapes and sizes. Vidura produces insights that
            are tailored for your specific business and the industry it operates
            in. This focused approach enhances audit quality and improves your
            overall experience
          </p>
          <p className="discover-more">
            <div className="discover-more-about-audit-btn">+</div>
            Discover the benifit of a more focused audit
          </p>
        </div>
        <div className={`dynamic-audit-content ${isVisible ? "visible" : ""}`}>
          <h4>A Smarter Audit</h4>
          <p>
            Driving value through a high-quality audit is not only what we do,
            it's in our DNA. Our diverse and highly skilled teams are constantly
            analyzing changes in your risk profile to focus on the risks facing
            your business
          </p>

          <p className="discover-more">
            <div className="discover-more-about-audit-btn">+</div>
            Discover the benifit of a more focused audit
          </p>
        </div>
      </div> */}
    </section>
  );
};

export default ProcessSafetyAudit;
