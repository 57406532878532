import React, { FunctionComponent,useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import './auditingAndConsulting.css'
import AuditCard from './AuditCard';
import hseAudit1 from '../../constrain/Images/What-is-HSE-Audit.jpg'
import hseAudit2 from '../../constrain/Images/What-is-HSE-Audit.jpg'
import hseAudit3 from '../../constrain/Images/safety-audit-500x500.webp'
import hseAudit4 from '../../constrain/Images/safety1.jpg'
import hseAudit5 from '../../constrain/Images//cq5dam.web.436.228.webp'
import AuditRightSectionCard from './AuditRightSectionCard';

interface EnvironmentalAuditProps {

}

const EnvironmentalAudit: FunctionComponent<EnvironmentalAuditProps> = () => {
    const [isVisible, setIsVisible] = useState(false);
    const handleVisibilityChange = () => {
        const element = document.querySelector('.audit-show-when-visible');
        if (element) {
            const rect = element.getBoundingClientRect();
            const elementIsVisible = rect.top < window.innerHeight && rect.bottom >= 0;
            setIsVisible(elementIsVisible);
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleVisibilityChange);
        return () => {
            window.removeEventListener('scroll', handleVisibilityChange);
        };
    }, []);

    return (
        <section className='audit-page'>
        <div className='auditing-and-consulting-main'>
            <div className='auditing-main-left-section'>
               
            </div>
           
        </div>
        <div className='audit-show-when-visible'>
                <div className={`dynamic-audit-content ${isVisible ? 'visible' : ''}`}>
                    <h4>Reduce Audit Burden</h4>
                    <p>Achieving a higher quality, more insight audit shouldn't take more effort from you. Through focus, flexibility, and and advanced audit technology, Vidura delivers more relevant insights , all while reducing the burden on your team</p>
                    <p className='discover-more'>
                        <div className='discover-more-about-audit-btn'>+</div>
                        Discover the benifit of a more focused audit
                    </p>
                </div>
                <div className={`dynamic-audit-content ${isVisible ? 'visible' : ''}`}>
                    <h4>Deeper Insight</h4>
                    <p>Insight come in many shapes and sizes. Vidura produces insights that are tailored for your specific business and the industry it operates in. This focused approach enhances audit quality and improves your overall experience</p>
                    <p className='discover-more'>
                        <div className='discover-more-about-audit-btn'>+</div>
                        Discover the benifit of a more focused audit
                    </p>
                </div>
                <div className={`dynamic-audit-content ${isVisible ? 'visible' : ''}`}>
                    <h4>A Smarter Audit</h4>
                    <p>Driving value through a high-quality audit is not only what we do, it's in our DNA. Our diverse and highly skilled teams are constantly analyzing changes in your risk profile to focus on the risks facing your business</p>
                    
                    <p className='discover-more'>
                        <div className='discover-more-about-audit-btn'>+</div>
                        Discover the benifit of a more focused audit
                    </p>

                </div>

            </div>
        </section>
    );
};

export default EnvironmentalAudit;
