import React from 'react';
import MainLayout from '../../component/Layout/MainLayout';
import Footer from '../../component/Footer/Footer';
import EsgStripDataCard from '../../component/ESGcomponent/EsgStripData';
import EsgAdvisoryCard from '../../component/Advisory/EsgAdvisoryCard';
import img1 from '../../constrain/Images/Morgan-Advanced-Materials-bg.jpg';
import img2 from '../../constrain/Images/foundation-in-health-safety-IGC-1.jpg';
import img3 from '../../constrain/Images/group-people-working-out-business-plan-office.jpg';
import img4 from '../../constrain/Images/health-safety-management-systems-plan-IGC-2.jpg';
import img5 from '../../constrain/Images/work-equipment-hazardous.jpg';
import img6 from '../../constrain/Images/green-overlay.png';
import headerImage from '../../constrain/Images/ESG-reporting.webp';
import img7 from '../../constrain/Images/FeaturedImage-5.webp';
import img8 from '../../constrain/Images/world-map-and-produce.jpg';
import img9 from '../../constrain/Images/as-is-process.png';
import img10 from '../../constrain/Images/close-up-people-working-with-laptop.jpg';
import EsgManagemntHeroSection from '../../component/ESGcomponent/ESGManagementHeroSection';
import ESGManagementHeader from '../../component/Services/Header/ESGManagementHeader';
import KnowMore from '../../component/Services/KnowMore';
import MainPage from '../HseMenuPage/MainPage';
import image from "../../constrain/Images/VIDURA_images/VIDURA_images/Green Building Services.jpg"

interface EsgAdvisoryDataItem {
  id: number;
  img: string;
  title: string;
  desc: string;
  link: string;
  btn: string;
  download: string;
}
interface EsgStripDataItem {
  id: number;
  img1: string;
  img2: string;
  title: string;
  desc: string;
  link: string;
  btn: string;
  download: string;
}

const EsgAdvisoryData: EsgAdvisoryDataItem[] = [
  {
    id: 1,
    img: img1,
    title: "ESG Advisory",
    desc: "Join our most awaited and self-paced course for NDA and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "ndabrochure.pdf",
  },
  {
    id: 2,
    img: img2,
    title: "Development advisory",
    desc: "Join our most awaited and self-paced course for CDS and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "cds.pdf",
  },
  {
    id: 3,
    img: img3,
    title: "HSE advisory for changing the culture",
    desc: "Join our most awaited and self-paced course for AFCAT and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "afcat.pdf",
  },
  {
    id: 4,
    img: img4,
    title: "Development of procedures/process",
    desc: "Join our most awaited and self-paced course for AFCAT and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "ota.pdf",
  },
  {
    id: 5,
    img: img5,
    title: "HSE advisory for changing the culture",
    desc: "Join our most awaited and self-paced course for AFCAT and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "afcat.pdf",
  },
  {
    id: 6,
    img: img6,
    title: "Development of procedures/process",
    desc: "Join our most awaited and self-paced course for AFCAT and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "ota.pdf",
  },
];

const EsgStripData: EsgStripDataItem[] = [
  {
    id: 1,
    img1: img7,
    img2: img8,
    title: "ESG Advisory",
    desc: "Join our most awaited and self-paced course for NDA and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "ndabrochure.pdf",
  },
  {
    id: 2,
    img1: img9,
    img2: img10,
    title: "Development advisory",
    desc: "Join our most awaited and self-paced course for CDS and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "cds.pdf",
  },
];

const GreenBuilldingService: React.FC = () => {
  return (
    <MainLayout>
      {/* <ESGManagementHeader headerImage={headerImage} title="Green Building Services" />
      <div className='esg-section'>
        <h3>Designed To Achieve Your Safety Training Goals</h3>
      </div>
      <main className='main-esg-content'>
        {EsgStripData.map((item, ind) => (
          <EsgStripDataCard
            key={item.id}
            id={item.id}
            img1={item.img1}
            img2={item.img2}
            title={item.title}
            desc={item.desc}
            link={item.link}
            btn={item.btn}
          />
        ))}

      </main>
      <EsgManagemntHeroSection/>
      <div className='esgAdvisory-section'>
        {EsgAdvisoryData.map((esdAdvisory) => (
          <EsgAdvisoryCard
            key={esdAdvisory.id}
            id={esdAdvisory.id}
            img={esdAdvisory.img}
            title={esdAdvisory.title}
            desc={esdAdvisory.desc}
            link={esdAdvisory.link}
            btn={esdAdvisory.btn}
          />
        ))}
      </div> */}

      <KnowMore heading={''}/>
      <MainPage 
      heading={'Green Building Services'}
       content={[
        {
          lione:"In the pursuit of sustainable infrastructure, Vidura International brings you comprehensive Green Building Services. We understand that the construction industry plays a pivotal role in shaping a greener future, and our services are designed to align your projects with the principles of environmental responsibility.",
          litwo:"Our seasoned experts provide holistic guidance, ensuring your building projects meet the highest standards of green construction. From sustainable material choices to energy-efficient designs, we work collaboratively to integrate eco-friendly practices into every phase of your construction projects." ,
          li3:"Vidura International's Green Building Services extend beyond compliance, aiming to create structures that not only minimize environmental impact but also contribute positively to the surrounding ecosystem. Whether it is optimizing energy usage, implementing water conservation measures, or incorporating renewable energy sources, our goal is to shape buildings that stand as beacons of sustainability.",
          li4:"Stay informed about your green building initiatives with our monitoring and reporting mechanisms, complete with established key performance indicators (KPIs) for regular assessment and transparent reporting. Choose Vidura International for Green Building Services that pave the way for environmentally conscious construction practices.",
          li5:"Initiate a collaborative discussion to implement strategies tailored to elevate your organization's commitment to green building practices",
          li6:""
        }
       ]} 
       image={image}/>
      <Footer />
    </MainLayout>
  );
};

export default GreenBuilldingService;
