import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import './DevAdvisoryContent.css'

interface DevelopmentAdvisoryContentProps {

}

const DevelopmentAdvisoryContent: FunctionComponent<DevelopmentAdvisoryContentProps> = () => {

    const handleDownload = () => {
        console.log("download");
    };

    return (
        <div className='development-advisory-content-header'>
            <div className='our-objective'>
                <h3 className='objective-heading'>Our Intention & Objectives</h3>
                <h4 className='objective-Title'>ASK-EHS provides comprehensive services that cater to every need of the industry</h4>
                <div className='objective-list'>
                    <div className=''>
                        <ul className='objective-lists'>
                            <li>Building a training course repository that provides cost effective access to superior programs</li>
                            <li>Developing training programs that are highly interactive and visually captivating</li>
                            <li>Delivering through multiple language options (International and National)</li>
                            <li>Industry & trade specific training programs</li>

                        </ul>
                    </div>
                    <div className=''>
                        <ul className='objective-lists'>
                            <li>Providing highly accredited training programs to individuals, looking to enhance their careers in EH&S</li>
                            <li>Customized solutions for corporate entities – Perpetual license to use</li>
                            <li>Partnering with Resellers, consultants, training companies, institutions and academies to provide globally recognized training programs</li>
                        </ul>
                    </div>

                </div>

            </div>

        </div>
    );
};

export default DevelopmentAdvisoryContent;
