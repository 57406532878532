import React from 'react';
import { Card, CardContent, Grid, Typography } from "@mui/material";
import styles from "./Industries.module.scss";
import MainLayout from "../Layout/MainLayout";
import Divider from "@mui/material/Divider";
import IndustriesCard from "./IndustriesCard/IndustriesCard";
import data from "./IndustriesCard/data.json";
import CommonButtton from "../CommonButtton/CommonButtton";
import ShotBlog from "../ShotBlog/ShotBlog";
import fblogImage from "../../constrain/Images/air-blog_1536x1536.jpg";
import featureCapability from "../../constrain/Images/radar_thumb_1139974926_1536x1536.jpg";
import featurePablication from "../../constrain/Images/perspectives-on-advanced-mobility_issue-thumb_1536x1536.webp";
import featureInsight from "../../constrain/Images/space-the-missing-element-of-your-strategy-948404646-standard-1536x1536.webp";
import airshow from "../../constrain/Images/airshow.jpg";
import airshow2 from "../../constrain/Images/airshow2.jpg";

const Industries = () => {
  console.log(data.ourPeople);
  return (
    <MainLayout>
      <Grid className={styles.industriesContainer}>
        <Grid className={styles.industriesBanner}>
          <Typography variant="h4" fontWeight={600} fontSize={50}>
            Aerospace &<br></br> Defense
          </Typography>
          <Typography fontSize={18}>
            Creating and accelerating critical advantages through cutting-edge
            <br></br> strategy and operations
          </Typography>
        </Grid>
        <Grid className={styles.industriesAbout}>
          <Typography>
            The aerospace and defense (A&D) industry is facing numerous
            challenges linked to an unforeseen pandemic, rapidly evolving
            technologies, and vulnerable supply chains. We help our clients push
            the boundaries in air and space and safeguard livelihoods through
            capabilities, technologies, and talent.
          </Typography>
          <Typography>
            Our approach combines a deep understanding of industry dynamics with
            advanced analytics and an unparalleled network of experts. We work
            with clients to build internal capabilities, adopt digital and
            analytics tools, and transform work processes to improve their
            business in substantial, sustainable ways.
          </Typography>
        </Grid>
        <Grid className={styles.fetureEventContainer}>
          <Typography
              style={{ textDecoration: "underline", color:"green" }}
            textAlign={"center"}
            variant="h2"
            fontWeight={600}
            fontSize={30}
          >
            FEATURED EVENTS
          </Typography>
          {data.featuredEvents.map((item) => {
            return (
              <Grid>
                <ShotBlog
                  image={item.image}
                  heading={item.heading}
                  content={item.content}
                />
              </Grid>
            );
          })}
        </Grid>
        <Divider
          sx={{
            border: "1px solid black",
            marginInline: 16,
            marginBlockStart: 8,
            marginBlockEnd: 4,
          }}
        />
        <Grid className={styles.helpClientContainer}>
          <Typography
            style={{ textDecoration: "underline", color: "green" }}
            variant="h5"
            fontWeight={700}
            fontSize={30}
          >
            HOW WE HELP CLIENTS
          </Typography>
          <Grid container className={styles.healpClients}>
            {data.dataOne.map((item) => {
              return (
                <Grid key={item.id} item md={3}>
                  <IndustriesCard
                    heading={item.heading}
                    content={item.content}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Divider
          sx={{
            border: "1px solid black",
            marginInline: 16,
            marginBlockStart: -4,
            marginBlockEnd: 4,
          }}
        />
        <Grid className={styles.weServeContainerr}>
          <Typography
            style={{ textDecoration: "underline", color: "green" }}
            variant="h5"
            fontWeight={700}
            fontSize={30}
          >
            HOW WE HELP CLIENTS
          </Typography>
          <Grid container className={styles.weServe}>
            {data.dataTwo.map((item) => {
              return (
                <Grid
                  item
                  md={4}
                  key={item.id}
                  className={styles.industriesCardContainer}
                >
                  <Card>
                    <CardContent className={styles.industriesCard}>
                      <Typography variant="h4" fontWeight={600} fontSize={24}>
                        {item.heading}
                      </Typography>
                      <Typography>{item.content}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid container className={styles.industriesPriviewContainer}>
          {data.priviewData.map((item) => {
            return (
              <Grid
                item
                md={3}
                key={item.id}
                className={styles.industriesPriview}
              >
                <Typography variant="h2" fontWeight={600} align="center">
                  {item.number}
                </Typography>
                <Typography align="center" fontSize={20}>
                  {item.label}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
        <Grid className={styles.ourPeopleContainer}>
          <Typography
            style={{ textDecoration: "underline", color:"green" }}
            variant="h5"
            fontWeight={700}
            fontSize={30}
            textAlign={"center"}
          >
            OUR PEOPLE
          </Typography>
          <Grid container className={styles.setContainer}>
            {data.ourPeople.map((item) => {
              return (
                <Grid item md={6} key={item.id} className={styles.ourPeople}>
                  <Grid>
                    <img src={item.img} alt="img" />
                  </Grid>
                  <Typography variant="h5" fontWeight={600} align="center">
                    {item.heading}
                  </Typography>
                  <Typography align="center" fontWeight={600} fontSize={14}>
                    {item.label}
                  </Typography>
                  <Typography
                    align="center"
                    fontSize={16}
                    sx={{ paddingInline: 5, marginBlockStart: 2 }}
                  >
                    {item.content}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
          <CommonButtton name={"More People"} />
        </Grid>
        <Divider
          sx={{
            border: "1px solid black",
            marginInline: 16,
            marginBlockStart: 7,
            marginBlockEnd: 4,
          }}
        />
        <Grid className={styles.featuredBlogContainer}>
          <Typography   style={{ textDecoration: "underline", color:"green" }} variant="h5" fontWeight={700} fontSize={30}>
            FEATURED BLOG
          </Typography>
          <ShotBlog
            image={fblogImage}
            heading={"Future Air Mobility blog"}
            content={
              "Perspectives and analyses on urban air mobility, drones, electric aviation, and more"
            }
          />
        </Grid>
        <Grid className={styles.featuredCapabilityContainer}>
          <Typography  style={{ textDecoration: "underline", color:"green" }} variant="h5" fontWeight={600} fontSize={25}>
            FEATURED CAPABILITY
          </Typography>
          <ShotBlog
            image={featureCapability}
            heading={"Radar"}
            content={
              "Radar combines budgetary, market, and competitive data with powerful visualization and analytical tools to help your organization analyze the market effectively and make timely strategic decisions."
            }
          />
        </Grid>
        <Grid className={styles.featuredPublicationContainer}>
          <Typography   style={{ textDecoration: "underline", color:"green" }} variant="h5" fontWeight={600} fontSize={25}>
            FEATURED PUBLICATION
          </Typography>
          <ShotBlog
            image={featurePablication}
            heading={"Perspectives on advanced air mobility"}
            content={
              "Navigating the emerging passenger urban and regional air-mobility industry"
            }
          />
        </Grid>
        <Grid className={styles.featuredInsightsContainer}>
          <Typography   style={{ textDecoration: "underline", color:"green" }} variant="h5" fontWeight={600} fontSize={25}>
            FEATURED INSIGHTS
          </Typography>
          <ShotBlog
            image={featureInsight}
            heading={"Space: The missing element of your strategy"}
            content={
              "March 27, 2023 - The space sector is at an inflection point similar to what commercial air travel experienced after World War II and the internet saw in the 1990s. Is your company ready to capture the opportunity?"
            }
          />
          <Grid container className={styles.featureInsight}>
            {data.feauredInsights.map((item) => {
              return (
                <Grid item md={6}>
                  <ShotBlog
                    image={item.img}
                    heading={item.heading}
                    content={item.content}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default Industries;
