import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import AuditingHeader from '../../component/AuditingAndConsulting/AuditingAndConsultingHeader';
import MainLayout from '../../component/Layout/MainLayout';
import AuditingAndConsulting from '../../component/AuditingAndConsulting/HSEAuditAndConsulting';
import Footer from '../../component/Footer/Footer';
import ESGAuditing from '../../component/AuditingAndConsulting/ESGAudit';
interface ESGAuditingAndConsultingProps {

}

const ESGAuditingAndConsulting: FunctionComponent<ESGAuditingAndConsultingProps> = () => {

    const handleDownload = () => {
        console.log("download");
    };

    return (
        <div className='auditing-and-consulting'>
            <MainLayout>
                <AuditingHeader heading="ESG Auditing"/>
                <ESGAuditing/>
                <Footer/>
            </MainLayout>
        </div>
    );
};

export default ESGAuditingAndConsulting;
