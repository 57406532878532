import React from 'react'
import { Route, Routes } from 'react-router-dom'
import PageOne from './PageOne'
import PageTwo from './PageTwo'
import NEBOSHCourses from './NEBOSHCourses'
import FirstAidCourses from './FirstAidCourses'
import FireAndSafetyCourses from './FireAndSafetyCourses'

const CertificateRoutes = () => {
    return (
        <Routes>
            <Route path='/certificate-&-course/nebosh-courses' element={<NEBOSHCourses />} />
            {/* <Route path='/certificate-&-course/iosh-courses' element={<PageTwo />} /> */}
            <Route path='/certificate-&-course/first-aid-courses' element={<FirstAidCourses />} />
            <Route path='/certificate-&-course/fire-fighting-courses' element={<FireAndSafetyCourses />} />
        </Routes>
    )
}

export default CertificateRoutes