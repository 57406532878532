import React from 'react'
import MainLayout from '../component/Layout/MainLayout'
import Manpower from '../component/Manpower/Manpowermangement'
import Footer from '../component/Footer/Footer'
import AuditingHeader from '../component/AuditingAndConsulting/AuditingAndConsultingHeader'

function Manpowermangement() {
  return (
    // <MainLayout>
    //     <Manpower/>
    //     <Footer/>
    // </MainLayout>

<div className='auditing-and-consulting'>
<MainLayout>
    <AuditingHeader heading="Safety Manpower Management"/>
    <Manpower/>
    <Footer/>
</MainLayout>
</div>
  )
}

export default Manpowermangement
