import React from "react";
import MainLayout from "../component/Layout/MainLayout";
import Ptw from "../component/e-ptw/Ptw";
import Footer from "../component/Footer/Footer";
import Commondigital from "../component/Drone/Commondigital";
import MainDigital from "../component/Drone/MainDigital";
import imgg from "../constrain/Images/resources-e-ptw-bg-img.jpg"

function PtwPage() {
  return (
    <MainLayout>
      {/* <Ptw /> */}
      <Commondigital heading={""} />
      <MainDigital
        heading={"E-PTW"}
        content={[
          {
            lione: "Vidura International introduces an advanced Electronic Permit-to-Work (E-PTW) system, revolutionizing the way organizations manage and authorize work activities in hazardous environments. Our E-PTW solution is a digital platform that replaces traditional paper-based permit systems, offering enhanced efficiency, accuracy, and safety.",
            litwo: "With Vidura's E-PTW, the entire permit issuance process becomes streamlined, ensuring swift approval and seamless coordination between different stakeholders. The system allows for real-time tracking of work permits, reducing the risk of conflicting operations and enhancing overall situational awareness.",
            li3: "Key features include a user-friendly interface for permit submission, automated approval workflows, and instant accessibility to permit details for all involved parties. The E-PTW system also provides a centralized repository for historical permit data, facilitating comprehensive analysis and reporting for continuous improvement in safety practices.",
            li4: "Transition to Vidura International's Electronic Permit-to-Work system to optimize your permit management processes, mitigate risks, and contribute to a safer work environment.",
            li5: "",
            li6: "",
          },
        ]}
        image={imgg}
        text={"Discover the transformative benefits of E-PTW by engaging in a conversation with Vidura International."}
      />
      <Footer />
    </MainLayout>
  );
}

export default PtwPage;
