import React from 'react'
import styles from './Contact.module.scss'
import { Button,Grid, Typography } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
// @ts-ignore
import videoSrc from '../../constrain/Images/sample4.mp4'
import { useShareDetails } from '../../context/ShareDetailsContext';


const Contact = () => {
  const { isShareDetailsOpen, openShareDetails, setShareDetailsOpen } = useShareDetails();
  const handleEnquiry = () => {
    setShareDetailsOpen(!isShareDetailsOpen)
  }
  return (
    <Grid className={styles.contactpageContainer}>
      <Grid className={styles.contactBanner}>
        <Grid className={styles.videoContainer}>
          <video src={videoSrc} autoPlay={true} loop muted={true}> </video>
        </Grid>
        <Grid className={styles.opecityContainer}> </Grid>
        <Grid className={styles.contactContainer}>
          <Grid container className={styles.contact}>
            <Grid item md={6}>
              <Typography variant='h4' fontWeight={600} fontSize={40}>CONTACT US</Typography>
              <Button variant='outlined' onClick={handleEnquiry}>
                Enquiry
                <ArrowForwardIcon />
              </Button>
            </Grid>
            <Grid item md={6} className={styles.contactForm}>
              {/* <ContactForm /> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={styles.enquiry}>
        <Typography align='center' variant='h4' fontWeight={600} fontSize={28}>
          Enquiry
        </Typography>
      </Grid>
      <Grid container className={styles.mapContainer}>
        <Grid item md={6}>
          <iframe scrolling="no" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Plot%20No.%20188,%20Block%20D%20Rosewood%20city%20Sohna%20Sohna%20Gurgaon%20Haryana%20Gurgaon+(Address:%20Plot%20No.%20188,%20Block%20D%20Rosewood%20city%20Sohna%20Sohna%20Gurgaon%20Haryana%20-%20122103%20India)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>

        </Grid>
        <Grid item md={6}>
          <Typography variant='h2' fontWeight={600} fontSize={35}>Head Office</Typography>
          <Typography>Address: 512A, ILD TRADE CENTRE, SEC-47A,SOHNA ROAD, GURGAON
            Haryana
            India</Typography>
          <Typography variant='h5'>Telephone</Typography>
          <Typography><PhoneIcon />+91 73043 26277,</Typography>
          {/* <Typography variant='h5'>Mobile</Typography>
          <Typography><WhatsAppIcon />+80560000530</Typography> */}
          <Typography variant='h5'>Carporate Enquiries</Typography>
          <Typography><PhoneIcon />+91 87677 45999</Typography>
          <Typography variant='h5'>Email</Typography>
          <Typography><EmailOutlinedIcon />info@vidurainternational.com</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Contact;

