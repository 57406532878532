import React from 'react';
import MainLayout from '../../component/Layout/MainLayout';
import Footer from '../../component/Footer/Footer';
import foodImg1 from '../../constrain/Images/food-safety.jpg'
import FoodSafetyHeader from '../../component/FoodSafety/FoodSafetyHeader';
import FoodSafetyMainContentSection from '../../component/FoodSafety/FoodSafetyMainSection';
import FoodSafetyRules from '../../component/FoodSafety/FoodSafetyRules';
import FoodSafetyList from '../../component/FoodSafety/FoodSafetyList';
const FoodSafty: React.FC = () => {
  return (
    <MainLayout>
     <FoodSafetyRules/>
     <FoodSafetyHeader/>
     <FoodSafetyMainContentSection/>
     <FoodSafetyList/>
      <Footer />
    </MainLayout>
  );
};

export default FoodSafty;
