import React from 'react'
import { Grid, Typography, Box } from '@mui/material'

export interface IIndustriesMenuBanner {
    heading: string;

}
const IndustriesMenuBanner = ({ heading }: IIndustriesMenuBanner) => {
    return (
        <Grid>
            <Box sx={{ paddingBlockStart: 10, paddingBlockEnd: 5 }}>
                <Typography align='center' textTransform={"capitalize"} variant='h2' fontWeight={700} fontSize={60} sx={{ color: "balck" }}>
                    {heading}
                </Typography>
                <Typography align='center' variant='h5' fontSize={25} sx={{ color: "balck", paddingBlockStart: 3 }}>
                    Consultancy
                </Typography>
            </Box>
        </Grid>
    )
}

export default IndustriesMenuBanner;