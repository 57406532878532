import React from "react";
import MainLayout from "../component/Layout/MainLayout";
// import Digitalesg from '../component/Digitalapp/Digitalesghsg/Digitalesg'
import Footer from "../component/Footer/Footer";
import Digitalesghsg from "../component/Digitalesgg/Digitalesghsg";
import Commondigital from "../component/Drone/Commondigital";
import MainDigital from "../component/Drone/MainDigital";
import digital from "../constrain/Images/pngtree-d-rendering-of-a-digital-service-or-app-for-cloud-storage-image_3853633.jpg"

function DigitalesgPage() {
  return (
    <MainLayout>
      {/* <Digitalesghsg /> */}
      <Commondigital heading={""} />
      <MainDigital
        heading={"Digital ESG/HSE platform"}
        content={[
          {
            lione: "Vidura International introduces a cutting-edge Digital ESG/HSE Platform, revolutionizing how organizations manage and streamline their Environmental, Social, and Governance (ESG) and Health, Safety, and Environment (HSE) initiatives. Our platform integrates advanced technologies to provide a centralized and user-friendly solution for comprehensive ESG and HSE management.",
            litwo: "This digital platform offers real-time data tracking, reporting, and analysis, empowering organizations to make informed decisions and meet their sustainability goals effectively. It includes features such as incident reporting, risk assessment, compliance monitoring, and performance analytics, ensuring a proactive approach to ESG and HSE management.",
            li3: "The Digital ESG/HSE Platform is designed to enhance transparency, accountability, and efficiency across all aspects of environmental and safety practices. Customizable dashboards and reporting tools enable organizations to align with global reporting standards, regulatory requirements, and stakeholder expectations.",
            li4: "Vidura International's Digital ESG/HSE Platform represents a significant leap forward in digitizing and optimizing ESG and HSE processes. By leveraging this technology, organizations can elevate their sustainability efforts, drive operational excellence, and contribute to a safer and more responsible future.",
            li5: "",
            li6: "",
          },
        ]}
        image={digital}
        text={"Explore the possibilities of our Digital ESG/HSE Platform by initiating a discussion with Vidura International."}
      />
      <Footer />
    </MainLayout>
  );
}

export default DigitalesgPage;
