import React from 'react'
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import data from './data.json'

export interface ICourseTableSheet {
    S_num: number;
    subject: string;
    duration: number

}
const CourseTableSheet = ({ S_num, subject, duration }: ICourseTableSheet) => {
    return (
        <TableContainer sx={{ border: "1px solid  #86bc25", marginBlockEnd: 5 }}>
            <Table>
                <TableHead>
                    <TableRow sx={{ backgroundColor: "#86bc25" }}>
                        <TableCell sx={{ color: "#ffffff", fontWeight: 600 }}>S.NO</TableCell>
                        {/* <TableCell sx={{color:"#ffffff", fontWeight:600}}>Subject Code</TableCell> */}
                        <TableCell sx={{ color: "#ffffff", fontWeight: 600 }}>COURSE</TableCell>
                        <TableCell sx={{ color: "#ffffff", fontWeight: 600 }}>COURSE DURATION</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.neboshCourses.map((item: any) => {
                        return (
                            <TableRow>
                                <TableCell>{item.S_num}</TableCell>
                                <TableCell>{item.subject}</TableCell>
                                <TableCell>{item.duration}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default CourseTableSheet