import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import MainLayout from '../component/Layout/MainLayout';
import Footer from '../component/Footer/Footer';
import DevelopmentAdvisoryBanner from '../component/Advisory/DevelopmentAdvisory';
import DevelopmentAdvisoryContent from '../component/Advisory/DevelopmentAdvisoryContent';
import OurApproach from '../component/Advisory/OurApproach';

interface developmentAdvisoryProps {

}

const DevelopmentAdvisory: FunctionComponent<developmentAdvisoryProps> = () => {

    const handleDownload = () => {
        console.log("download");
    };

    return (

        <MainLayout>
            <div className="developmentAdvisory-section">
                <div className='development-advisory-banner'>
                            <DevelopmentAdvisoryBanner/>
                </div>
                <DevelopmentAdvisoryContent/>
                <OurApproach/>
            </div>
            <Footer />
        </MainLayout>

    );
};

export default DevelopmentAdvisory;
