import React ,{ FunctionComponent }from 'react';
import esgManagemntImg from '../../constrain/Images/Green Growth Blog.png'
import './esgManagementHeroStyle.css'


interface EsgStripDataProps {
    id: number;
    img1: string;
    img2: string;
    title: string;
    desc: string;
    link: string;
    btn: string;
  }
const EsgStripDataCard: FunctionComponent<EsgStripDataProps> = ({ id, img1,img2, title, desc, link, }) => {

    return (
        <>
        <div className='esg-content-strip-card-1'>
          <section className='strip-img-section'>
            <img src={img1} alt="" />
          </section>
          <section className='strip-desc-section'>
            <h4>
              {title}
            </h4>
            <p>
              {desc}
              {desc}
              {desc}
            </p>
          </section>
        </div>
        <div className='esg-content-strip-card-2'>
          <section className='strip-desc-section'>
            <h4>
              {title}
            </h4>
            <p>
              {desc}
              {desc}
              {desc}
              {desc}
            </p>
          </section>
          <section className='strip-img-section'>
            <img src={img2} alt="" />
          </section>
        </div>
      </>
    );
};

export default EsgStripDataCard;
