import React, { FunctionComponent, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./auditingAndConsulting.css";
import AuditCard from "./AuditCard";
import hseAudit1 from "../../constrain/Images/What-is-HSE-Audit.jpg";
import hseAudit2 from "../../constrain/Images/What-is-HSE-Audit.jpg";
import hseAudit3 from "../../constrain/Images/safety-audit-500x500.webp";
import hseAudit4 from "../../constrain/Images/safety1.jpg";
import hseAudit5 from "../../constrain/Images//cq5dam.web.436.228.webp";
import AuditRightSectionCard from "./AuditRightSectionCard";
import Auditcard1 from "./Auditcard1";
import image from "../../constrain/Images/VIDURA_images/VIDURA_images/audit-4189560_1280.jpg"
import card2img from "../../constrain/Images/VIDURA_images/VIDURA_images/pexels-anamul-rezwan-1216589.jpg"

interface AuditingAndConsultingProps {}

const AuditingAndConsulting: FunctionComponent<
  AuditingAndConsultingProps
> = () => {
  const [isVisible, setIsVisible] = useState(false);
  const handleVisibilityChange = () => {
    const element = document.querySelector(".audit-show-when-visible");
    if (element) {
      const rect = element.getBoundingClientRect();
      const elementIsVisible =
        rect.top < window.innerHeight && rect.bottom >= 0;
      setIsVisible(elementIsVisible);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleVisibilityChange);
    return () => {
      window.removeEventListener("scroll", handleVisibilityChange);
    };
  }, []);

  return (
    <section className="audit-page">
      <div className="auditing-and-consulting-main">
        <div className="auditing-main-left-section">
          <AuditCard
            img={image}
            title="Audit Innovation"
            auditName="Elevate Your Safety Standards with Vidura International"
            para={[{text:"At Vidura International, we understand the critical importance of maintaining a robust Health, Safety, and Environment (HSE & ESG) framework for your organization's success. Our HSE & ESG Audit Services are meticulously crafted to assess, evaluate, and enhance your existing safety & ESG protocols."}]}
          />
          
          <Auditcard1 
          mainheading="Our HSE & ESG Audit Process"
          img = {card2img}
          para={[{
            text:"Understanding your unique business needs and objectives",heading:"Pre-Audit Consultation:"
           },
           {text:"Thorough examination of your workplace, processes, and documentation.",heading:"On-Site Evaluation"},
           {text:"A detailed report highlighting strengths, areas for improvement, and actionable recommendations.",heading:"Comprehensive Reporting "},{
            text:"We stand by you even after the audit, offering support in implementing recommended changes and ensuring ongoing compliance.",heading:"Post-Audit Support"
           }
        ]}
          />
        </div>
        <div className="auditing-main-right-section">
          <AuditRightSectionCard
          mainheading = "Why Vidura International?"
         content ={[
          {text: "Our experienced HSE & ESG professionals conduct thorough audits, examining every facet of your organization's HSE & ESG & ESG practices." , heading: "Comprehensive Audits"},
          {text: "Recognizing the uniqueness of each business, our HSE & ESG audits are customized to align with your specific industry, regulatory requirements, and organizational goals to ensure maximum relevance and effectiveness.", heading:"Tailored Solutions & Client-Centric Approach: "},
          {text:" Stay ahead of regulatory changes with our audits. We provide clear insights into compliance levels, helping you address any gaps and mitigate potential risks.", heading:"Compliance Assurance"},
          {text:" Our audit reports not only identify areas for improvement but also offer practical recommendations to enhance your HSE & ESG performance continually.", heading:"Continuous Improvement"},
          {text:"Anticipate and mitigate potential risks before they escalate. Our audits are designed to identify hazards and provide proactive risk management strategies.", heading:"Proactive Risk Management" },
          {text:"Our team comprises seasoned HSE & ESG professionals with a wealth of experience across diverse industries and countries.", heading:"Expertise"},
          {text:"Vidura International is dedicated to helping you achieve and maintain the highest standards of health, safety, and environmental practices by following legal requirements, with implementing national and international best practices", heading:"Commitment to Excellence"}
          ]}
          />
        </div>
      </div>
      {/* <div className='audit-show-when-visible'>
                <div className={`dynamic-audit-content ${isVisible ? 'visible' : ''}`}>
                    <h4>Reduce Audit Burden</h4>
                    <p>Achieving a higher quality, more insight audit shouldn't take more effort from you. Through focus, flexibility, and and advanced audit technology, Vidura delivers more relevant insights , all while reducing the burden on your team</p>
                    <p className='discover-more'>
                        <div className='discover-more-about-audit-btn'>+</div>
                        Discover the benifit of a more focused audit
                    </p>
                </div>
                <div className={`dynamic-audit-content ${isVisible ? 'visible' : ''}`}>
                    <h4>Deeper Insight</h4>
                    <p>Insight come in many shapes and sizes. Vidura produces insights that are tailored for your specific business and the industry it operates in. This focused approach enhances audit quality and improves your overall experience</p>
                    <p className='discover-more'>
                        <div className='discover-more-about-audit-btn'>+</div>
                        Discover the benifit of a more focused audit
                    </p>
                </div>
                <div className={`dynamic-audit-content ${isVisible ? 'visible' : ''}`}>
                    <h4>A Smarter Audit</h4>
                    <p>Driving value through a high-quality audit is not only what we do, it's in our DNA. Our diverse and highly skilled teams are constantly analyzing changes in your risk profile to focus on the risks facing your business</p>

                    <p className='discover-more'>
                        <div className='discover-more-about-audit-btn'>+</div>
                        Discover the benifit of a more focused audit
                    </p>

                </div>

            </div> */}
    </section>
  );
};

export default AuditingAndConsulting;
