
import React from 'react';
import './AuditingStyle.css'

interface Paragraph {
  text: string;
}

interface Auditcard1Props {
  mainheading: string;
  img:string;
  para: { heading: string; text: string }[];
}

function Auditcard1({ mainheading, img,para }: Auditcard1Props) {
  return (
    <section className='auditing-card'>
      <h3 className='audit-card-title'>{mainheading}</h3>
      <div className='audit-card-content'>
        <div className=''>
          {para.map((item, index) => (
            <React.Fragment key={index}>
              <h5>{item.heading}</h5>
              <p>{item.text}</p>
            </React.Fragment>
          ))}
        </div>
        <div className='audit-card-img'>
          <img className='auditcard1img' src={img} alt='' />
        </div>
      </div>
    </section>
  );
}

export default Auditcard1;

