import React from 'react';
import esgManagemntImg from '../../constrain/Images/Green Growth Blog.png'
import './esgManagementHeroStyle.css'






const EsgManagemntHeroSection: React.FC = () => {
    return (
        <section className='esg-management-hero-section'>
            <div className='left-image-section'>
                <img src={esgManagemntImg} alt='' />
                <h3>
                    How to Develop an Effective ESG Strategy: Best Practices, Challenges, Tips
                </h3>
            </div>
            <div className='right-content-section'>
                <h3>
                    How to Develop an Effective ESG Strategy: Best Practices, Challenges, Tips
                </h3>
                <p className='esg-first-para'>
                    Environmental, social, and governance (ESG) is currently playing a significant role on boards in almost every industry, as over 75% of investors in private markets plan to stop purchasing non-ESG products. However, developing an effective ESG strategy comes with some challenges.
                </p>
                <p>
                    That’s why each board of directors must fully grasp what kind of impact their organization has on society and the environment.  They also need to be aware of which ESG factors will be most integral to the organization’s future.
                </p>
                <p>
                    This article explores ESG in business strategy. We’ll also analyze the key steps and best practices on how to develop an ESG strategy that aligns with your company’s values and objectives and why a board should apply ESG in business strategy.
                </p>
            </div>

        </section>

    );
};

export default EsgManagemntHeroSection;
