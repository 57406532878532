import React, { useState } from 'react'
import Navbar from '../Header/Navbar';
import img from '../../constrain/Images/whatsapp-img.png'
import ShareDetails from '../Enquiry/ShareDetails';
import { useShareDetails } from '../../context/ShareDetailsContext';
import { FaPhoneVolume } from 'react-icons/fa6';
import PhoneCall from '../../constrain/Images/white-phone-call.webp'



const MainLayout = ({ children }: { children: React.ReactNode }) => {
    const { isShareDetailsOpen, openShareDetails, setShareDetailsOpen } = useShareDetails();

    // const openShareDetails = () => {
    //     console.log(isShareDetailsOpen)
    //     setShareDetailsOpen(!isShareDetailsOpen)
    // }

    return (
        <>
                <Navbar />
                <div className="page-layout">{children}</div>

                <div className={`${isShareDetailsOpen ? "openSharebox" : "closedSharebox"}`}>

                    <ShareDetails setShareDetailsOpen={setShareDetailsOpen} />
                </div>

                {isShareDetailsOpen?<span  className='cross-btn' onClick={openShareDetails}>X</span>: <button className='share-detail' onClick={openShareDetails}>
                    {/* <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/be/Facebook_Messenger_logo_2020.svg/2048px-Facebook_Messenger_logo_2020.svg.png" alt="share details" /> */}
                    Enquiry <span className='qusetion-mar'>?</span>
                </button>}
               
                <a href='tel:+917304326277'>
                <img src={PhoneCall} width={80} style={{ position: "fixed",zIndex:9, bottom:132,borderRadius:50, left: 20, cursor: 'pointer' }} alt='img' />

                </a>
                <a href='https://wa.me/7304326277'>
                    <img src={img} width={80} style={{ position: "fixed",zIndex:9, bottom: 25, left: 20, cursor: 'pointer' }} alt='img' />
                </a>
        </>
    )
}

export default MainLayout
