import React from 'react';
import MainLayout from '../../component/Layout/MainLayout';
import Footer from '../../component/Footer/Footer';
import EsgStripDataCard from '../../component/ESGcomponent/EsgStripData';
import EsgAdvisoryCard from '../../component/Advisory/EsgAdvisoryCard';
import img1 from '../../constrain/Images/Morgan-Advanced-Materials-bg.jpg';
import img2 from '../../constrain/Images/foundation-in-health-safety-IGC-1.jpg';
import img3 from '../../constrain/Images/group-people-working-out-business-plan-office.jpg';
import img4 from '../../constrain/Images/health-safety-management-systems-plan-IGC-2.jpg';
import img5 from '../../constrain/Images/work-equipment-hazardous.jpg';
import img6 from '../../constrain/Images/green-overlay.png';
import img7 from '../../constrain/Images/consultancy3.jpg';
import img8 from '../../constrain/Images/healthandsafety.jpg';
import img9 from '../../constrain/Images/slide_2.jpg'
import img10 from '../../constrain/Images/world-map-and-produce.jpg';
import image from "../../constrain/Images/VIDURA_images/VIDURA_images/Environment Aspect And Impact Study.jpg"
import headerImage from '../../constrain/Images/ESG_and_technology.jpg';
import ESGManagementHeader from '../../component/Services/Header/ESGManagementHeader';
import EsgManagemntHeroSection from '../../component/ESGcomponent/ESGManagementHeroSection';
import KnowMore from '../../component/Services/KnowMore';
import MainPage from '../HseMenuPage/MainPage';

interface EsgAdvisoryDataItem {
  id: number;
  img: string;
  title: string;
  desc: string;
  link: string;
  btn: string;
  download: string;
}

interface EsgStripDataItem {
  id: number;
  img1: string;
  img2: string;
  title: string;
  desc: string;
  link: string;
  btn: string;
  download: string;
}

const EsgAdvisoryData: EsgAdvisoryDataItem[] = [
  {
    id: 1,
    img: img1,
    title: "ESG Advisory",
    desc: "Join our most awaited and self-paced course for NDA and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "ndabrochure.pdf",
  },
  {
    id: 2,
    img: img2,
    title: "Development advisory",
    desc: "Join our most awaited and self-paced course for CDS and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "cds.pdf",
  },
  {
    id: 3,
    img: img3,
    title: "HSE advisory for changing the culture",
    desc: "Join our most awaited and self-paced course for AFCAT and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "afcat.pdf",
  },
  {
    id: 4,
    img: img4,
    title: "Development of procedures/process",
    desc: "Join our most awaited and self-paced course for AFCAT and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "ota.pdf",
  },
  {
    id: 5,
    img: img5,
    title: "HSE advisory for changing the culture",
    desc: "Join our most awaited and self-paced course for AFCAT and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "afcat.pdf",
  },
  {
    id: 6,
    img: img6,
    title: "Development of procedures/process",
    desc: "Join our most awaited and self-paced course for AFCAT and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "ota.pdf",
  },
];
const EsgStripData: EsgStripDataItem[] = [
  {
    id: 1,
    img1: img7,
    img2: img8,
    title: "ESG Advisory",
    desc: "Join our most awaited and self-paced course for NDA and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "ndabrochure.pdf",
  },
  {
    id: 2,
    img1: img9,
    img2: img10,
    title: "Development advisory",
    desc: "Join our most awaited and self-paced course for CDS and prepare yourself for the academy",
    link: "More Details",
    btn: "Download Brochure",
    download: "cds.pdf",
  },
];
const EnvironmentAspectAndImpactStudy: React.FC = () => {
  return (
    <MainLayout>
      {/* <ESGManagementHeader headerImage={headerImage} title="Environment Aspect and Impact study" />
      <div className='esg-section'>
        <h3>Designed To Achieve Your Safety Training Goals</h3>
      </div>
      <main className='main-esg-content'>
        {EsgStripData.map((item, ind) => (
          <EsgStripDataCard
            key={item.id}
            id={item.id}
            img1={item.img1}
            img2={item.img2}
            title={item.title}
            desc={item.desc}
            link={item.link}
            btn={item.btn}
          />
        ))}

      </main>
      <EsgManagemntHeroSection/>
      <div className='esgAdvisory-section'>
        {EsgAdvisoryData.map((esdAdvisory) => (
          <EsgAdvisoryCard
            key={esdAdvisory.id}
            id={esdAdvisory.id}
            img={esdAdvisory.img}
            title={esdAdvisory.title}
            desc={esdAdvisory.desc}
            link={esdAdvisory.link}
            btn={esdAdvisory.btn}
          />
        ))}
      </div> */}
      <KnowMore heading={''}/>
      <MainPage
       heading={'Environment Aspect and Impact Study'} 
       content={[
        {
           lione:"Embark on a transformative journey towards environmental sustainability with Vidura International's specialized Environment Aspect and Impact Study. In today's world, where organizations face increasing scrutiny for their ecological footprint, our service is tailored to guide businesses in understanding and mitigating their environmental impact.",litwo:"Our seasoned experts conduct a comprehensive study of your organization's environmental aspects, identifying key elements contributing to your ecological footprint. Through collaborative efforts, we analyze the environmental impact of your operations and develop strategic mitigation strategies. These strategies align with your business objectives while minimizing adverse effects on the environment." ,
            li3:"Upon conducting the study, you will receive a detailed roadmap for the phased implementation of mitigation strategies. This ensures the seamless integration of sustainable practices into your day-to-day operations. Stay informed about the progress of your environmental impact reduction through our monitoring and reporting mechanisms, complete with established key performance indicators (KPIs) for regular assessment and transparent reporting",
            li4:"Foster positive relationships with stakeholders by transparently communicating your environmental impact reduction efforts to investors, customers, and the community. At Vidura International, our approach is not just tailored but crafted with precision to suit the specific needs and aspirations of your organization, ensuring relevance and impactful outcomes.",
            li5:"Choose Vidura International for expert guidance, tailored solutions, and global standards compliance. Initiate a collaborative discussion to implement Environment Aspect and Impact Study services, tailored to elevate your organization's sustainability profile.",
            li6:""
        }
       ]} 
       image={image}/>
      <Footer />
    </MainLayout>
  );
};

export default EnvironmentAspectAndImpactStudy;
