import React from "react";
import MainLayout from "../component/Layout/MainLayout";
import Occupationhealthh from "../component/Occupationhealth/Occupationhealthh";
import Footer from "../component/Footer/Footer";
import Commondigital from "../component/Drone/Commondigital";
import MainDigital from "../component/Drone/MainDigital";
import imgg from "../constrain/Images/vital-record-1100x550.jpg"

function OccupationPage() {
  return (
    <MainLayout>
      {/* <Occupationhealthh/> */}

      <Commondigital heading={""} />
      <MainDigital
        heading={"Occupational health management"}
        content={[
          {
            lione: "Vidura International introduces a comprehensive Occupational Health Management system designed to prioritize the well-being of your workforce. Our platform focuses on integrating occupational health practices seamlessly into your organizational framework, ensuring a proactive and preventive approach to employee health.",
            litwo: "With Vidura's Occupational Health Management system, organizations can monitor and manage various aspects of employee health, from regular health check-ups and medical surveillance to managing work-related illnesses and injuries. The platform facilitates efficient data management, enabling organizations to track health trends, identify potential risks, and implement preventive measures.",
            li3: "Key features include health assessments, medical record management, and wellness programs aimed at promoting a healthy and productive workforce. Vidura's Occupational Health Management system aligns with regulatory standards and industry best practices to support organizations in creating a safe and health-conscious work environment.",
            li4: "Prioritize the health of your workforce with Vidura International's Occupational Health Management system. Elevate your organization's commitment to employee well-being and foster a culture of health and safety.",
            li5: "",
            li6: "",
          },
        ]}
        image={imgg}
        text={"Explore the comprehensive capabilities of Occupational Health Management by initiating a conversation with Vidura International."}
      />
      <Footer />
    </MainLayout>
  );
}

export default OccupationPage;
