import React from "react";
import "./Flowtrainig.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import img from "../../constrain/Images/360_F_530791557_T8hNharBQQJqmw0R2FRjCrC9CgNwaeui.jpg";

function Flowtraining() {
  return (
    <>
      <div className="flowconatiner1">
        <div className="flowbox">
          <div className="flowbox1">
            <h6>Identification of trainig needs</h6>
          </div>
        </div>
        <div className="iconarrow">
          <img src={img} alt="" />
        </div>

<div className="box1">
<div className="flowbox">
          <div className="flowbox2">
            <h6>Preparing Trainig Schedule / Plan</h6>
          </div>
        </div>
        <div className="iconarrow">
          <img src={img} alt="" />
        </div>

</div>
        

        <div className="flowbox">
          <div className="flowbox1">
            <h6>Preparing Trainig Module</h6>
          </div>
        </div>
        <div className="iconarrow">
          <img src={img} alt="" />
        </div>

        <div className="flowbox">
          <div className="flowbox1">
            <h6>Delivering Training</h6>
          </div>
        </div>
        <div className="iconarrow">
          <img src={img} alt="" />
        </div>

        <div className="flowbox">
          <div className="flowbox1">
            <h6>Training Feedback</h6>
          </div>
        </div>
        <div className="iconarrow">
          <img src={img} alt="" />
        </div>

        <div className="flowbox">
          <div className="flowbox1">
            <h6>Re-Training</h6>
          </div>
        </div>
       
      </div>
    </>
  );
}

export default Flowtraining;
