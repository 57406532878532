import React, { Fragment } from 'react';
import './tagLineStyle.css'
import { Container } from '@mui/system';
import icon from '../../constrain/icons/right-arrow.png'
import EastIcon from '@mui/icons-material/East';
import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useShareDetails } from '../../context/ShareDetailsContext';

interface IntroductionProps {
  //   videoSrc: string;
}

const Introduction: React.FC<IntroductionProps> = () => {
  const { isShareDetailsOpen, openShareDetails, setShareDetailsOpen } = useShareDetails();
  const handleEnquiry = () => {
    setShareDetailsOpen(!isShareDetailsOpen)
  }
  return (
    <Fragment>
      <div className='introductionContainer'>
        <Container>
          <div className='introduction-line-heading'>
            <p>ENQUIRE TODAY</p>
            <p>GET A FREE CONSULTATION</p>
            <Box sx={{ display: "flex" }} >
              <Button onClick={handleEnquiry}>Enquiry <EastIcon /></Button>
            </Box>
          </div>
        </Container>
      </div>
      <div className='introduction-line'>
        {/* <Container>
          <h3 className='who-we-are'>Who we are?</h3>

          <div className='intro-div'>
            <div className='itroduction-image'>
              <img src={personeWelcom} alt='intro-image'></img>
            </div>
            <p className='short-desc'>ViDuRa Consulting LLP is a leading consulting firm that partners with bold leaders in business and society to tackle their most important  Environment and Social governance (ESG) and Health and Safety (OHS) challenges and capture their greatest opportunities.
              We help in pinpointing the Sustainability strategy to reshape the safe and healthy future for the organizations. Transforming safety culture through advanced technology like Artificial intelligence, Virtual realty, animation. Resourcing, developing competence and capabilities across the spheres of sustainability and Occupational health and safety for the organizations.
              Our success is guided by a deep collaboration with global professionals of diverse ESG/HSE background to make the world a better, healthy, sustainable safe place.
            </p>

          </div>



        </Container> */}

      </div>
    </Fragment>
  );
};

export default Introduction;
