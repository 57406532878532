import React from 'react'
import { Box, Grid } from '@mui/material'
import MainLayout from '../../../component/Layout/MainLayout'
import data from '../data.json'
import SubMenuCard from '../SubMenuCard/SubMenuCard'
import IndustriesMenuBanner from '../IndustriesMenuBanner/IndustriesMenuBanner'
import Footer from '../../../component/Footer/Footer'

const Education = () => {
    return (
        <>
            <MainLayout>
                <Grid sx={{ paddingInline: 4 }}>
                    <IndustriesMenuBanner heading={'Education'} />
                    <Grid container spacing={2}>
                        {data.education.map((item) => {
                            return (
                                <Grid item sm={6} md={4} lg={3} className='aerospaceDefenceCard'>
                                    <SubMenuCard
                                        data={item}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            </MainLayout>
            <Box sx={{ marginBlockStart: 5 }}>
                <Footer />
            </Box>
        </>
    )
}

export default Education;