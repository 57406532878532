import React from 'react'
import "./Peoplework.css";
import { Grid, Typography } from '@mui/material';

function Peoplework() {
  return (
    <>
      <Grid className="peopleworkcontainer">
        <Grid className="container-fluid workheading">
          <Typography variant='h4' fontWeight={600}>
            The people who work at <span>Vidura</span><br /> share the vision and value of our community
          </Typography>
          <Typography>"Vidura's leadership team fosters a culture of innovation and collaboration, driving our organization's growth and impact."</Typography>
        </Grid>
      </Grid>

    </>
  )
}

export default Peoplework