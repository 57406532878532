export const homeMenu = [
  { id: 1, navMenu: "home", path: "/" },
  // {id:2,navMenu:"performance",path:"/performance"},
  // {id:3,navMenu:"digital",path:"/digital"},
  // {id:4,navMenu:"regulatory",path:"/regulatory"},
  // {id:5,navMenu:"service",path:"/service"},
]

export const aboutMenu = [
  { id: 1, navMenu: "about ", path: "/about" },
  // {id:2,navMenu:"business performance",path:"/business"},
  // {id:3,navMenu:" about digital",path:"/digital"},
  // {id:4,navMenu:"regulatory",path:"/regulatory"},
  // {id:5,navMenu:"",path:"/business"},  
]


export const contactMenu = [
  { id: 1, navMenu: "contact", path: "/contact" },
  { id: 2, navMenu: "performance", path: "/business" },
  { id: 3, navMenu: "digital", path: "/digital" },
  { id: 4, navMenu: "regulatory", path: "/regulatory" },
  { id: 5, navMenu: "business", path: "/business" },
]

export const serviceMenu = [
  { id: 1, navMenu: "ESG ", path: "/esg" },
  { id: 2, navMenu: "HSE ", path: "/hse" },
  { id: 3, navMenu: "Skill Development", path: "/skill-development" },
  { id: 4, navMenu: "Trainings ", path: "/trainings" },
  { id: 5, navMenu: "Fire Safety Management", path: "/fire-safety-management" },
  { id: 6, navMenu: "Occupational Health Management", path: "/occupational-health-management" },
  { id: 7, navMenu: "Competent Human Resourcing ", path: "/Competent-human-management" },
  { id: 8, navMenu: "Management System Consulting ", path: "/management-system-consulting" },
  // { id: 9, navMenu: "Occupational Health Management ", path: "/food-safety" },
  { id: 10, navMenu: "MEP And Other EPC Contracts", path: "/mep-epc-contracts" },
  { id: 11, navMenu: "Material Procurement And Supply", path: "/material-procurement-and-supply" },
]

export const galleryMenu = [
  { id: 1, navMenu: "Gallery", path: "/gallery" },
  { id: 2, navMenu: "Performance", path: "/business" },
  { id: 3, navMenu: "Digital", path: "/digital" },
  { id: 4, navMenu: "Regulatory", path: "/regulatory" },
  { id: 5, navMenu: "Business", path: "/business" },
]

export const careerMenu = [
  { id: 1, navMenu: "Career", path: "/career" },
  { id: 2, navMenu: "Performance", path: "/business" },
  { id: 3, navMenu: "Digital", path: "/digital" },
  { id: 4, navMenu: "Regulatory", path: "/regulatory" },
  { id: 5, navMenu: "Business", path: "/business" },
]

export const AuditingAndConsulting = [
  { id: 1, navMenu: "HSE Audit", path: "/auditing/consulting/hse-audit" },
  { id: 2, navMenu: "ESG Audit", path: "/auditing/consulting/esg-audit" },
  { id: 3, navMenu: "Carbon Audit ", path: "/auditing/consulting/carbon-audit" },
  { id: 4, navMenu: "Energy Audit", path: "/auditing/consulting/energy-audit" },
  { id: 5, navMenu: "IMS Audit", path: "/auditing/consulting/ims-audit" },
  { id: 6, navMenu: "Process Safety Audit", path: "/auditing/consulting/process-safety-audit" },
  { id: 7, navMenu: "Electrical Safety Audit", path: "/auditing/consulting/electrical-safety-audit" },
  { id: 8, navMenu: "Environmental Audit", path: "#" },
  { id: 9, navMenu: "Standard Specific Audit", path: "/auditing/consulting/standard-specific-audit" },

]

export const ProfessionalResourcing = [
  { id: 1, navMenu: "Safety Manpower Management ", path: "/Manpower-management" },
  { id: 2, navMenu: "Medical/Nursing Staff Deployment", path: "/Medical-nurse-staff" },
  { id: 3, navMenu: "Supply Of Different Trade Professionals", path: "/Professional-resourcing-suppy" },

]

export const industrySubMenu = [

  { id: 1, navMenu: "Aerospace & Defence", path: "/industries/aerospace-defence" },
  { id: 2, navMenu: "Finance Service", path: "/industries/finance-service" },
  { id: 3, navMenu: "Public Sector", path: "/industries/public-sector" },
  { id: 4, navMenu: "Agricultre", path: "/industries/agricultre" },
  { id: 5, navMenu: "Industries & Electronics", path: "/industries/electronics" },
  { id: 6, navMenu: "Real Estate", path: "/industries/real-estate" },
  { id: 7, navMenu: "Automotive & Assembly", path: "/industries/automotive-&-assembly" },
  { id: 8, navMenu: "Healthcare", path: "/industries/healthcare" },
  { id: 9, navMenu: "Retail", path: "/industries/retail" },
  { id: 10, navMenu: "Chemicals", path: "/industries/chemicals" },
  { id: 11, navMenu: "Life Sciences", path: "/industries/life-science" },
  { id: 12, navMenu: "Semiconductors", path: "/industries/semiconductors" },
  { id: 13, navMenu: "Consumer Packaged Goods", path: "/industries/consumer-packaged-goods" },
  { id: 14, navMenu: "Metals & Mining", path: "/industries/metals-&-mining" },
  { id: 15, navMenu: "Social Sector", path: "/industries/social-sector" },
  { id: 16, navMenu: "Education", path: "/industries/education" },
  { id: 17, navMenu: "Oil & Gas", path: "/industries/oil-&-gas" },
  { id: 18, navMenu: "Technology , Media& Telecommunication", path: "/industries/technology-media-&-telecommunication" },
  { id: 19, navMenu: "Electric Power & Natural Gas", path: "/industries/electric-power-&-natural-gas" },
  { id: 20, navMenu: "Paper, Forest Products & Packaging", path: "/industries/paper-forest-product-&-packaging" },
  { id: 21, navMenu: "Travel, Logistics & Infracture", path: "/industries/travel-logistics-&-infracture" },
  { id: 22, navMenu: "Engineering, Construction & Building Materials", path: "/industries/engineering-construction-&-building-materials" },
  { id: 23, navMenu: "Private Equity & Principal Investor", path: "/industries/private-equity-&-principal-investor" },
]

export const trainingAndLearning = [
  { id: 1, navMenu: "Training on Different Safety Modules ", path: "/training on different safety modules" },
  { id: 2, navMenu: "Legal Training ", path: "/legal training" },
  { id: 3, navMenu: "First AID Training ", path: "/first aid training" },
  { id: 4, navMenu: "Fire Fighting Training  ", path: "/fire fighting training" },
  { id: 5, navMenu: "HSE Induction Training", path: "/hse induction training" },
  { id: 6, navMenu: "ESG Trainings", path: "/esg trainings" },
  { id: 7, navMenu: "GWO and FARM TOKLI Trainings", path: "/gwo and farm tikli training" },
  { id: 8, navMenu: "Behaviour Based Safety Trainings", path: "/behaviour based safety trainings" },
  { id: 9, navMenu: "POSH Training", path: "/posh training" }
]

export const strategicAdvisory = [
  { id: 1, navMenu: "Complete Safety Management System", path: "/Complete Safety Management System" },
  { id: 2, navMenu: "ESG Adviosry", path: "/ESG Adviosry" },
  { id: 3, navMenu: "ISO 45001", path: "/ISO 45001" },
  { id: 4, navMenu: "UN Sustainability Goals", path: "/UN Sustainability Goals" },
  { id: 5, navMenu: "Carbon Counting And Management", path: "/Carbon Counting And Management" },
  { id: 6, navMenu: "Energy Management", path: "/Energy Management" },
  { id: 7, navMenu: "Waste Management", path: "#" },

]

export const digitalServices = [
  { id: 1, navMenu: "AI Chat Boat for Safety And Other", path: "/digital/service/ai chat boat for safety and others" },
  { id: 2, navMenu: "Animation And Other Video Creation", path: "/digital/service/animation and others video creation" },
  { id: 3, navMenu: "Digital App Services", path: "/digital/service/digital app service" },
  { id: 4, navMenu: "Virtual Reality Training Setup", path: "/digital/service/virtual reality training setup" },
  { id: 5, navMenu: "Real Time Monitoring Of Site With Drone And Camera", path: "/digital/service/real time monitoring of site with drone and camera" },
  { id: 6, navMenu: "Energy Management", path: "/digital/service/energy management" },
  { id: 7, navMenu: "Waste Management", path: "/digital/service/waste management" },
]
export const esgHseSoftwareService = [
  { id: 1, navMenu: "Digital ESG/HSE Platforms", path: "/esg/software/digital esg/hse platforms" },
  { id: 2, navMenu: "Learning Management System (LMS)", path: "/esg/software/digital esg/hse platforms/esg/software/learning managemen" },
  { id: 3, navMenu: "E- PTW ", path: "/esg/software/e-ptw" },
  { id: 4, navMenu: "E-Incident Management ", path: "/esg/software/e-incident management" },
  { id: 5, navMenu: "Health Safety And Environment Management", path: "/esg/software/Health Safety And Environment Management" },
  { id: 6, navMenu: "Occupational Health Management", path: "/esg/software/occupationa health management" },
]
export const certificateAndCourse = [
  { id: 1, navMenu: "NEBOSH Courses", path: "/certificate-&-course/nebosh-courses" },
  { id: 2, navMenu: "IOSH Courses", path: "/certificate-&-course/iosh-courses" },
  { id: 3, navMenu: "First AID courses", path: "/certificate-&-course/first-aid-courses" },
  { id: 4, navMenu: "Fire Fighting Courses", path: "/certificate-&-course/fire-fighting-courses" }
]
