import React from 'react'
import styles from './FirstAidCourses.module.scss'
import { Grid } from '@mui/material'
import HeadingText from '../HeadingText/HeadingText'
import data from './data.json'

const FirstAidCourses = () => {
    return (
        <Grid className={styles.firstAidCourses}>
            <HeadingText data={data.firstAirCourseHeading} />
            <HeadingText data={data.firstAirCourse} />
        </Grid>
    )
}

export default FirstAidCourses