import React from "react";
import "./Newssidebar.css";
import { Divider } from "@mui/material";
interface NewsSidebarProps {
  isOpenNav: boolean;
}


function Newssidebar(props: NewsSidebarProps) {
  const items = [
    "New Release",
    "Latest Release",
    "Media Resourse",
    "Media Contact",
  ];

  return (
    <>
      <div className={`newssidebar ${props.isOpenNav ? "openNav":"closeNav"}`}>
        <div className="newsheading">
          <h3>News & Events</h3>
        </div>
        <Divider className="divide"/>
        <div className="newsli">
          <ul className="">
            {items.map((item, index) => (
              <li key={index}>
                <a href="/news-event">{item}</a>
              </li>
            ))}
          </ul>
        </div>

        <div className="Media-contact">
          <div>
            <h3>Media Contact</h3>
            <Divider />
            <div className="media-contactli">
              <ul>
                <a href="/news-event">
                  <li>Renate Myles</li>
                </a>
                <a href="/news-event">
                  <li>Amanda Fine</li>
                </a>
                <a href="/news-event">
                  <li>Emily Ritter</li>
                </a>
                <a href="/news-event">
                  <li>Emma Wojtowicz</li>
                </a>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Newssidebar;
