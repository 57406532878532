import React, { FunctionComponent } from 'react'
import "./Commondigital.css"

interface digitalProps {
   
    heading:string;
  }

const Commondigital: FunctionComponent<digitalProps>  = ({heading}) =>{
  return (
   <>
    <div className="digitalcontentheading">
         
    </div>
   </>
  )
}

export default Commondigital