import React from 'react';
import MainLayout from '../../component/Layout/MainLayout';
import Footer from '../../component/Footer/Footer';
import EsgAdvisoryCard from '../../component/Advisory/EsgAdvisoryCard';
import EsgStripDataCard from '../../component/ESGcomponent/EsgStripData';
import img1 from '../../constrain/Images/Morgan-Advanced-Materials-bg.jpg';
import img2 from '../../constrain/Images/foundation-in-health-safety-IGC-1.jpg';
import img3 from '../../constrain/Images/group-people-working-out-business-plan-office.jpg';
import img4 from '../../constrain/Images/health-safety-management-systems-plan-IGC-2.jpg';
import img5 from '../../constrain/Images/consultancy3.jpg';
import img6 from '../../constrain/Images/healthandsafety.jpg';
import img7 from '../../constrain/Images/slide_2.jpg'
import img8 from '../../constrain/Images/world-map-and-produce.jpg';
import image from "../../constrain/Images/VIDURA_images/VIDURA_images/Screenshot (244).png"
import headerImage from '../../constrain/Images/ESG_and_technology.jpg';
import ESGManagementHeader from '../../component/Services/Header/ESGManagementHeader';
import EsgManagemntHeroSection from '../../component/ESGcomponent/ESGManagementHeroSection';
import './esgStyle.css'
import KnowMore from '../../component/Services/KnowMore';
import MainPage from '../HseMenuPage/MainPage';
interface EsgAdvisoryDataItem {
  id: number;
  img: string;
  title: string;
  desc: string;
  link: string;
  btn: string;
  download: string;
}
interface EsgStripDataItem {
  id: number;
  img1: string;
  img2: string;
  title: string;
  desc: string;
  link: string;
  btn: string;
  download: string;
}
// const EsgAdvisoryData: EsgAdvisoryDataItem[] = [
//   {
//     id: 1,
//     img: img1,
//     title: "ESG Advisory",
//     desc: "Join our most awaited and self-paced course for NDA and prepare yourself for the academy",
//     link: "More Details",
//     btn: "Download Brochure",
//     download: "ndabrochure.pdf",
//   },
//   {
//     id: 2,
//     img: img2,
//     title: "Development advisory",
//     desc: "Join our most awaited and self-paced course for CDS and prepare yourself for the academy",
//     link: "More Details",
//     btn: "Download Brochure",
//     download: "cds.pdf",
//   },
//   {
//     id: 3,
//     img: img3,
//     title: "HSE advisory for changing the culture",
//     desc: "Join our most awaited and self-paced course for AFCAT and prepare yourself for the academy",
//     link: "More Details",
//     btn: "Download Brochure",
//     download: "afcat.pdf",
//   },
//   {
//     id: 4,
//     img: img4,
//     title: "Development of procedures/process",
//     desc: "Join our most awaited and self-paced course for AFCAT and prepare yourself for the academy",
//     link: "More Details",
//     btn: "Download Brochure",
//     download: "ota.pdf",
//   },

// ];

// const EsgStripData: EsgStripDataItem[] = [
//   {
//     id: 1,
//     img1: img5,
//     img2: img6,
//     title: "ESG Advisory",
//     desc: "Join our most awaited and self-paced course for NDA and prepare yourself for the academy",
//     link: "More Details",
//     btn: "Download Brochure",
//     download: "ndabrochure.pdf",
//   },
//   {
//     id: 2,
//     img1: img7,
//     img2: img8,
//     title: "Development advisory",
//     desc: "Join our most awaited and self-paced course for CDS and prepare yourself for the academy",
//     link: "More Details",
//     btn: "Download Brochure",
//     download: "cds.pdf",
//   },
// ];
const ESGManagementPlan: React.FC = () => {
  return (
    <MainLayout>
      {/* <ESGManagementHeader headerImage={headerImage} title="ESG Management Plan" /> */}
      <KnowMore heading={'ESG Management Plan'}/>
      {/* <div className='esg-section'>
        <h3>Designed To Achieve Your Safety Training Goals</h3>
      </div>
      <main className='main-esg-content'>
        {EsgStripData.map((item, ind) => (
          <EsgStripDataCard
            key={item.id}
            id={item.id}
            img1={item.img1}
            img2={item.img2}
            title={item.title}
            desc={item.desc}
            link={item.link}
            btn={item.btn}
          />
        ))}

      </main>
      <EsgManagemntHeroSection />
      <div className='esgAdvisory-section'>
        {EsgAdvisoryData.map((esdAdvisory) => (
          <EsgAdvisoryCard
            key={esdAdvisory.id}
            id={esdAdvisory.id}
            img={esdAdvisory.img}
            title={esdAdvisory.title}
            desc={esdAdvisory.desc}
            link={esdAdvisory.link}
            btn={esdAdvisory.btn}
          />
        ))}
      </div> */}
      <MainPage 
      heading={'ESG Management Plan'} 
      content={[
        {lione:"Embark on a transformative journey towards sustainable business practices with Vidura International's ESG Management Plan. In a world increasingly shaped by Environmental, Social, and Governance (ESG) considerations, our specialized service is crafted to guide organizations toward responsible and resilient practices.",litwo:"Our seasoned experts conduct a thorough evaluation of your organization's ESG performance, identifying strengths, weaknesses, opportunities, and threats. Together, we set ambitious yet achievable ESG goals and develop strategies to seamlessly integrate sustainable practices into your core business functions. You will receive a detailed roadmap for the phased and strategic integration of ESG initiatives, aligning with your organizational priorities." ,
        li3:"Stay informed about your ESG progress through our monitoring and reporting mechanisms, complete with established key performance indicators (KPIs) for regular assessment and reporting. Foster positive stakeholder relationships by transparently communicating your sustainability journey to investors, customers, and the community.",
        li4:"At Vidura International, our approach is not just tailored but crafted with precision to suit the specific needs and aspirations of your organization, ensuring relevance and impactful outcomes. Choose Vidura International for expert guidance, tailored solutions, and global standards compliance, and initiate a collaborative discussion to develop an ESG Management Plan that elevates your organization's sustainability profile.",
        li5: "",li6:""
        }
      ]} 
      image={image} />
      <Footer />
    </MainLayout>
  );
};

export default ESGManagementPlan;
