import React from 'react'
import "./Coreteam.css";
import styles from './Coreteam.module.scss';
import m1 from "../../constrain/Images/1.jpg";
import m2 from "../../constrain/Images/2.jpg";
import m3 from "../../constrain/Images/3.jpg";
import m4 from "../../constrain/Images/4.jpg";
import m5 from "../../constrain/Images/5.jpg";
import { Grid, Typography } from '@mui/material';
import TeamCard from './TeamCard/TeamCard';


const items = [
  // {
  //   id: 2,
  //   img: m2,
  //   names: "Miss Maya Rajan ",
  //   content: "NEBOSH, IEMA, Grad NEBOSH ",
  //   Experience: "25 Years",
  //   work: "Danfoss industries, Amway, Reliance, Essar, Standard Chartered Bank ",
  // },
  {
    id: 3,
    img: m3,
    names: "Mr Shyam M Dubey",
    content: "B.Tech (Electrical)-NPTI, Bangalore         ",
    Experience: "17 Years",
    work: "Saudi Electricity, STC Electrical, ABB Ltd, BSES",
  },
  {
    id: 4,
    img: m4,
    names: "Mr Christopher Mcdermott ",
    content: "ISO 9001 Lead Auditor, PIEMA,  CMIOSH, B.Sc (Honors)- Occupational Safety & Health",
    Experience: "20 Years",
    work: "British Safety Council, DLF"
  },
  {
    id: 5,
    img: m5,
    names: "Mr Rishi Chaturvedi",
    content: "NEBOSH, ESG, OHSAS 18001:2007, IAO 45001:2018",
    Experience: "13 Years",
    work: "Empower Energy Solution, Al Shirawi Group, ESSAR"
  }
]

function Coreteam() {
  return (
    <Grid className={styles.coreTeamContainer}>
      <Grid>
        <Typography textAlign={"center"} variant='h2' fontWeight={600} fontSize={45}>Core Team</Typography>
        <Typography textAlign={"center"}>"At VIDURA, our diverse team of experts shares a passion for excellence. We bring extensive experience and innovative thinking to deliver top-quality solutions.<br /> Our core values guide us in empowering organizations, fostering growth, and making a positive impact in every project."</Typography>
      </Grid>
      <Grid container spacing={2}>
        {items.map((item) => {
          return (
            <Grid item sm={4} key={item.id}>
              <TeamCard
                img={item.img}
                name={item.names}
                description={item.content}
                experience={item.Experience}
                content={item.work}
              />
            </Grid>
          )
        })}

      </Grid>

    </Grid>

  )
}

export default Coreteam

{/* <h2>Core Team</h2>
          <p>"At VIDURA, our diverse team of experts shares a passion for excellence. We bring extensive experience and innovative thinking to deliver top-quality solutions.<br /> Our core values guide us in empowering organizations, fostering growth, and making a positive impact in every project."</p>
        </div> */}