import React from 'react'
import Animation from '../component/Animationandvideo/Animation'
import MainLayout from '../component/Layout/MainLayout'
import Footer from '../component/Footer/Footer'
import Commondigital from '../component/Drone/Commondigital'
import MainDigital from '../component/Drone/MainDigital'
import imgg from ".././constrain/Images/animation_feature_img_v1.webp"

function AnimationsPage() {
  return (
    <MainLayout>
        <Commondigital heading={''} />
         <MainDigital
        heading={'Animation and video creation'}
        content={[
          {
            lione: "Vidura International brings creativity and innovation to life through our top-notch Animation and Video Creation services. Our team of skilled professionals specializes in transforming complex safety and environmental concepts into engaging visual narratives.",
            litwo: "With a focus on clarity and impact, our animations and videos convey critical safety messages, training modules, and environmental practices in a visually compelling manner. We leverage state-of-the-art animation techniques and video production tools to create content that resonates with your audience.",
            li3: "From safety induction videos to environmental awareness animations, we tailor our services to meet your specific needs. Our goal is to make learning and information retention an enjoyable experience, fostering a deeper understanding of safety and environmental practices among your workforce.",
            li4: "Step into the realm of dynamic visual storytelling with Vidura International's Animation and Video Creation services. Elevate your training programs and safety communication through captivating visuals that leave a lasting impression.",
            li5: "",
            li6: "",
          },
        ]}
        image={imgg} 
        text={'Explore the possibilities of engaging animation and video content by initiating a discussion with Vidura International.'}/>

        <Footer/>
    </MainLayout>
 
    
  )
}

export default AnimationsPage



// <Commondigital heading={''} />
// <MainDigital
// heading={''}
// content={[
//  {
//    lione: "",
//    litwo: "",
//    li3: "",
//    li4: "",
//    li5: "",
//    li6: "",
//  },
// ]}
// image={''} 
// text={''}/>