import React from 'react'

const ArticlesPage = () => {
  return (
    <div>
      Article
    </div>
  )
}

export default ArticlesPage
